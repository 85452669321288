import { InteractionRequiredAuthError } from '@azure/msal-common'
import { useMsal } from '@azure/msal-react'
import { useCallback } from 'react'
import { loginRequest } from '../authConfig'

export const useAccessToken = () => {
    const { instance } = useMsal()

    const getAccessToken = useCallback(async () => {
        try {
            await instance.initialize(loginRequest)
            const response = await instance.acquireTokenSilent(loginRequest)
            return response.accessToken
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                const response = await instance.acquireTokenPopup(loginRequest)
                return response.accessToken
            } else {
                throw error
            }
        }
    }, [instance])

    return getAccessToken
}
