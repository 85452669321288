import { toast } from 'react-toastify'
import { JSONFormPayload, JSONFormResponse, WarningResponse } from '../../apiTypes'
import { botFieldEndpoint } from '../../endpoints'
import { useGet } from '../../hooks/useGet'
import { usePost } from '../../hooks/usePost'
import { UUID } from '../../types'
import FormWrapper from '../forms/FormWrapper'

interface FieldDetailsProps {
    botId: UUID
    fieldId: UUID
    readonly: boolean
    onUpdate: () => void
    onCancel: () => void
}

const FieldDetails = ({ botId, fieldId, readonly, onUpdate, onCancel }: FieldDetailsProps) => {
    const endpoint = botFieldEndpoint(botId)(fieldId)
    const [field, fieldLoading] = useGet<JSONFormResponse>(endpoint)
    const [saveField, saveLoading] = usePost<JSONFormPayload, WarningResponse>(endpoint)

    const handleSave = async (updatedField: JSONFormPayload) => {
        const response = await saveField(updatedField)

        if (response) {
            if (response.warning) {
                toast.warning(response.warning)
            } else {
                toast.success('Field has been updated')
            }
            onUpdate()
        }
    }

    return <FormWrapper formData={field} disabled={readonly || fieldLoading || saveLoading} onSubmit={handleSave} onCancel={onCancel} />
}

export default FieldDetails
