import styled, { useTheme } from 'styled-components'
import PoweredBySpringbok from '../components/PoweredBySpringbok'
import { useDarkModeContext } from '../context/DarkModeContext'
import crsLogo from './companyLogos/crs-logo.png'
import crsUATLogo from './companyLogos/crs-uat-logo.png'
import dentonsLogoInv from './companyLogos/dentons-logo-inverted.png'
import dentonsLogo from './companyLogos/dentons-logo.png'
import springbokLogoInv from './companyLogos/springbok-logo-inverted.png'
import springbokLogo from './companyLogos/springbok-logo.png'

const Container = styled.div`
    position: relative;
`

const Marketing = styled(PoweredBySpringbok)`
    position: absolute;
    bottom: 0;
    right: 0;
`

const StyledImage = styled.img`
    height: 100%;
    object-fit: contain;
`

interface LogoIconProps {
    className?: string
}

const LogoIcon = ({ className }: LogoIconProps) => {
    const { company, logo } = useTheme()
    const { isDarkMode } = useDarkModeContext()

    const getLogo = () => {
        switch (company) {
            case 'Dentons':
                return <StyledImage src={isDarkMode ? dentonsLogoInv : dentonsLogo} width={logo.width} height={180} alt='fleetAI logo' />
            case 'CharlesRussellSpeechlys':
                return <StyledImage src={crsLogo} width={logo.width} height={180} alt='CRSidekick logo' />
            case 'CharlesRussellSpeechlysUAT':
                return <StyledImage src={crsUATLogo} width={logo.width} height={180} alt='CRSidekick UAT logo' />
            default:
                return <StyledImage src={isDarkMode ? springbokLogoInv : springbokLogo} width={logo.width} height={180} alt='SpringLaw logo' />
        }
    }

    return (
        <Container className={className}>
            {getLogo()}
            <Marketing />
        </Container>
    )
}

export default LogoIcon
