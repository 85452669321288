import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled, { useTheme } from 'styled-components'
import AddIcon from '../../icons/AddIcon'
import RetryIcon from '../../icons/RetryIcon'
import TickIcon from '../../icons/TickIcon'
import { UUID } from '../../types'
import { areEqual } from '../../utils/objectUtils'
import { requestSaveMessage } from '../../utils/userMessages'
import SortableItemWrapper, { Item, SortableItem } from './SortableItemWrapper'
import TransparentButton from './TransparentButton'
import { scrollBarStyle } from './styles'

const Container = styled.ol`
    height: 100%;
    margin: 10px 0;
    padding: 0;
    overflow-y: auto;
    ${scrollBarStyle}
`

const Controls = styled.div`
    align-self: end;
    display: flex;
`

const Button = styled(TransparentButton)`
    margin-left: 10px;
`

interface SortableListProps {
    items: Item[]
    disabled?: boolean
    onSave: (orderedItemIds: UUID[], deletedItemIds: UUID[]) => void
    onAdd: () => void
    ItemComponent: SortableItem
}

const SortableList = ({ items: initialItems, disabled = false, onSave, onAdd, ItemComponent }: SortableListProps) => {
    const { colors } = useTheme()

    const [items, setItems] = useState<Item[]>(initialItems)

    useEffect(() => {
        setItems(initialItems)
    }, [initialItems])

    const isDirty = !disabled && !areEqual(initialItems, items)

    const handleMoveItem = (dragIndex: number, hoverIndex: number) =>
        setItems(prev => {
            const newCards = [...prev]
            const draggedCard = newCards[dragIndex]

            newCards.splice(dragIndex, 1)
            newCards.splice(hoverIndex, 0, draggedCard)
            return newCards
        })

    const updateItemDeleteFlag = (toDelete: boolean) => (id: UUID) => setItems(prev => prev.map(item => (item.id === id ? { ...item, toDelete } : item)))
    const handleDeleteItem = updateItemDeleteFlag(true)
    const handleRestoreItem = updateItemDeleteFlag(false)

    const handleAdd = () => {
        if (isDirty) {
            toast.warn(requestSaveMessage)
        } else {
            onAdd()
        }
    }

    const handleSave = () => {
        const orderedItemIds: UUID[] = []
        const deletedItemIds: UUID[] = []

        items.forEach(({ id, toDelete }) => (toDelete ? deletedItemIds.push(id) : orderedItemIds.push(id)))

        onSave(orderedItemIds, deletedItemIds)
    }

    return (
        <>
            <Container>
                {items.map((item, i) => (
                    <SortableItemWrapper
                        key={item.id}
                        item={item}
                        index={i}
                        disabled={disabled}
                        listIsDirty={isDirty}
                        onMoveItem={handleMoveItem}
                        onDeleteItem={() => handleDeleteItem(item.id)}
                        onRestoreItem={() => handleRestoreItem(item.id)}
                        ItemComponent={ItemComponent}
                    />
                ))}
            </Container>
            <Controls>
                <Button type='button' aria-label='add new' disabled={disabled} onClick={handleAdd}>
                    <AddIcon width={24} height={18} color={colors.text} strokeWidth={2} />
                    Add
                </Button>
                <Button type='submit' aria-label='restore ordering' disabled={disabled} onClick={() => setItems(initialItems)}>
                    <RetryIcon width={20} height={14} />
                    Restore
                </Button>
                <Button type='submit' aria-label='save ordering changes' disabled={disabled} onClick={handleSave}>
                    <TickIcon width={24} height={18} strokeWidth={2} />
                    Save
                </Button>
            </Controls>
        </>
    )
}

export default SortableList
