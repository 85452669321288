import { SVGProps } from 'react'

const DocumentsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={280} height={280} fill='none' {...props}>
        <circle cx={140} cy={140} r={140} fill='#2968D2' />
        <path fill='#fff' fillOpacity={0.4} d='M70 54.092h111.364V190.91H70z' />
        <path fill='#fff' fillOpacity={0.4} d='M98.636 85.908H210v136.818H98.636z' />
    </svg>
)

export default DocumentsIcon
