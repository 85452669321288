import ReactMarkdown, { Options } from 'react-markdown'
import { styled } from '@mui/system'
import remarkGfm from 'remark-gfm'
import { TypographyOptions } from '@mui/material/styles/createTypography'

const StyledMarkdownMessage = styled(ReactMarkdown)(({ theme }) => ({
    [`& > :first-child`]: {
        marginTop: 0,
    },
    [`& > :last-child`]: {
        marginBottom: 0,
    },
    [`& pre`]: {
        overflowX: 'auto',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        borderRadius: '12px',
        padding: '12px',
        border: 'none',
    },
    [`& p`]: {
        whiteSpace: 'pre-wrap',
    },
    [`& a`]: {
        color: 'inherit',
    },
    [`& th`]: {
        overflow: 'hidden',
    },
    [`& td`]: {
        verticalAlign: 'baseline',
    },
    [`& table, & th, & td`]: {
        border: '1px solid',
        borderCollapse: 'collapse',
    },
    [`& th, & td`]: {
        padding: '2px 5px',
        textAlign: 'left',
    },
}))

type TypographySubset = Extract<'chatMessage' | 'body1', keyof TypographyOptions>

interface MarkDownMessageProps extends Options {
    variant?: TypographySubset
    className?: string
}

const MarkdownMessage = ({ variant = 'chatMessage', className, children, ...rest }: MarkDownMessageProps) => {
    return (
        <StyledMarkdownMessage className={`${variant}-markdown ${className}`} remarkPlugins={[remarkGfm]} {...rest}>
            {children}
        </StyledMarkdownMessage>
    )
}

export default MarkdownMessage
