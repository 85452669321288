import { useCallback, useEffect, useState } from 'react'

// textarea has default padding of 2px on top & bottom
const defaultVerticalPadding = 4

export const useAutosizeTextArea = (inputLineHeight: number): [(textArea: HTMLTextAreaElement | null) => void, string, (value: string) => void] => {
    const [textAreaRef, setTextAreaRef] = useState<HTMLTextAreaElement | null>(null)
    const [initialHeight, setInitialHeight] = useState<number | undefined>()
    const [value, setValue] = useState('')

    const refCallback = useCallback((node: HTMLTextAreaElement | null) => {
        if (node) {
            setTextAreaRef(node)
            setInitialHeight(node.clientHeight)
        }
    }, [])

    useEffect(() => {
        if (textAreaRef && initialHeight) {
            // We need to reset the height momentarily to get the correct scrollHeight for the textarea - for shrinking after content is deleted, as scrollHeight only seems to increase
            textAreaRef.style.height = '0px'
            const { scrollHeight } = textAreaRef

            if (scrollHeight > initialHeight) {
                const lines = Math.ceil(scrollHeight / inputLineHeight)

                textAreaRef.style.height = `${inputLineHeight * lines - defaultVerticalPadding}px`
            } else {
                textAreaRef.style.height = `${initialHeight - defaultVerticalPadding}px`
            }
        }
    }, [textAreaRef, initialHeight, value, inputLineHeight])

    return [refCallback, value, setValue]
}
