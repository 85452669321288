import { useEffect, useRef } from 'react'

const useOutside = <T extends HTMLElement>(onClickOutside: () => void) => {
    const ref = useRef<T>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && event.target instanceof Node && !ref.current.contains(event.target)) {
                onClickOutside()
            }
        }

        document.addEventListener('mouseup', handleClickOutside)

        return () => document.removeEventListener('mouseup', handleClickOutside)
    }, [ref, onClickOutside])

    return ref
}

export default useOutside
