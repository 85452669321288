import MiniIconButton from '../../common/MiniIconButton'
import ChevronIcon from '../../../icons/ChevronIcon'
import { UUID } from '../../../types'
import { styled, useTheme } from '@mui/material'
import { forwardPropsWithTransient } from '../../../utils/muiUtils'

const OrderingColCell = styled('td')(() => ({
    width: '24px',
}))

const ReorderingWrapper = styled('div')(() => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}))

const ReorderingButton = styled(MiniIconButton, { shouldForwardProp: forwardPropsWithTransient })<{ $isHighlighted: boolean }>(({ $isHighlighted, theme }) => ({
    width: '20px',
    height: '20px',
    margin: '4px 2px 4px 0px',
    border: `1px solid ${$isHighlighted ? theme.palette.primary.main : 'inherit'}`,

    svg: {
        height: '14px',
        width: '14px',
        cursor: 'pointer',
    },
}))

interface OrderingButtonsProps {
    promptResultId: UUID
    onOrderChange: (id: UUID, direction: 'up' | 'down', target: HTMLElement) => void
    isLastRow: boolean
    isFirstRow: boolean
    isHighlighted: boolean
}

const OrderingButtons = ({ promptResultId, onOrderChange, isFirstRow, isHighlighted, isLastRow }: OrderingButtonsProps) => {
    const { palette } = useTheme()

    return (
        <OrderingColCell>
            <ReorderingWrapper>
                <ReorderingButton
                    onClick={(e: React.MouseEvent<HTMLElement>) => onOrderChange(promptResultId, 'up', e.currentTarget)}
                    title={isFirstRow ? 'Already in the first row' : 'Move up'}
                    disabled={isFirstRow}
                    $isHighlighted={isHighlighted}
                >
                    <ChevronIcon direction='up' color={isFirstRow ? palette.text.disabled : palette.text.primary} />
                </ReorderingButton>
                <ReorderingButton
                    onClick={(e: React.MouseEvent<HTMLElement>) => onOrderChange(promptResultId, 'down', e.currentTarget)}
                    title={isLastRow ? 'Already in the last row' : 'Move down'}
                    disabled={isLastRow}
                    $isHighlighted={isHighlighted}
                >
                    <ChevronIcon direction='down' color={isLastRow ? palette.text.disabled : palette.text.primary} />
                </ReorderingButton>
            </ReorderingWrapper>
        </OrderingColCell>
    )
}

export default OrderingButtons
