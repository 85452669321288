import { SVGProps } from 'react'
import { useTheme } from 'styled-components'

const DeleteIcon = ({ color, ...rest }: SVGProps<SVGSVGElement>) => {
    const { colors } = useTheme()
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
            <circle cx='10' cy='10' r='10' fill='#676767' fillOpacity='0.15' />
            <path d='M13 7.00041L7.00032 13M12.9997 12.9996L7 7' stroke={colors.text} strokeWidth='1.5' strokeLinecap='square' />
        </svg>
    )
}

export default DeleteIcon
