import { styled } from '@mui/material'

const StyledTabPanel = styled('div')(() => ({
    position: 'relative',
    flex: 1,
    minHeight: 0,
}))

interface TabPanelProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode
    index: number
    value: number
}

export const TabPanel = ({ children, value, index, ...rest }: TabPanelProps) =>
    value === index && (
        <StyledTabPanel role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...rest}>
            {value === index && children}
        </StyledTabPanel>
    )
