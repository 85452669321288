import styled from 'styled-components'
import CenteredContainer from './CenteredContainer'

interface ColorProps {
    secondaryColor?: boolean
}

const Spinner = styled.div<ColorProps>`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid ${props => (props.secondaryColor ? props.theme.colors.secondary : props.theme.colors.primary)};
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

const Bar = styled.span<ColorProps>`
    width: 100%;
    height: 4.8px;
    display: inline-block;
    position: relative;
    background: rgba(255, 255, 255, 0.15);
    overflow: hidden;

    &::after {
        content: '';
        width: 192px;
        height: 4.8px;
        background: ${props => (props.secondaryColor ? props.theme.colors.secondary : props.theme.colors.primary)};
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        animation: animloader 2s linear infinite;

        @keyframes animloader {
            0% {
                left: 0;
                transform: translateX(-100%);
            }
            100% {
                left: 100%;
                transform: translateX(0%);
            }
        }
    }
`

interface LoadingProps extends ColorProps {
    className?: string
    fullSize?: boolean
    bar?: boolean
}

const Loading = ({ className, fullSize, secondaryColor, bar }: LoadingProps) => {
    const Loader = bar ? Bar : Spinner

    return fullSize ? (
        <CenteredContainer className={className}>
            <Loader secondaryColor={secondaryColor} role='progressbar' aria-label='loading' />
        </CenteredContainer>
    ) : (
        <Loader className={className} secondaryColor={secondaryColor} role='progressbar' aria-label='loading' />
    )
}

export default Loading
