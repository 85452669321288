import { SVGProps } from 'react'
import { useTheme } from 'styled-components'

interface FilterIconProps extends SVGProps<SVGSVGElement> {
    filtered?: boolean
}

const FilterIcon = ({ filtered = false, color, ...rest }: FilterIconProps) => {
    const { colors } = useTheme()

    return filtered ? (
        <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 -2 16 16' fill='none' {...rest}>
            <path
                stroke={color ?? colors.text}
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={1.3}
                d='M1 1h11.123M2.589 4.708h7.945M4.972 8.415H8.15'
            />
            <path stroke={color ?? colors.text} strokeLinecap='round' strokeWidth={1.3} d='m11 11 2.857-2.857M11 8.143 13.857 11' />
        </svg>
    ) : (
        <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 -2 12 12' fill='none' {...rest}>
            <path
                stroke={color ?? colors.text}
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={1.3}
                d='M1 1h8.313M2.186 3.375h5.938M3.969 5.75h2.375'
            />
        </svg>
    )
}

export default FilterIcon
