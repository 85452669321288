import styled from 'styled-components'
import UnstyledButton from './UnstyledButton'
import { zIndexes } from './styles'

const MiniIconButton = styled(UnstyledButton)`
    z-index: ${zIndexes.button};
    border: none;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    transition: all 0.3s;

    :hover:not([disabled]) {
        background-color: ${props => props.theme.colors.disabled};
    }

    :focus {
        outline: none;
    }

    :disabled {
        cursor: default;
    }
`

export default MiniIconButton
