import { useState } from 'react'
import BookIcon from '../../../icons/BookIcon'
import BookmarkIcon from '../../../icons/BookmarkIcon'
import ChevronIcon from '../../../icons/ChevronIcon'
import CrossIcon from '../../../icons/CrossIcon'
import FilterIcon from '../../../icons/FilterIcon'
import Checkbox from '../../common/Checkbox'
import Dropdown from '../../common/Dropdown'
import Editable from '../../common/Editable'
import { zIndexes } from '../../common/styles'
import TransparentButton from '../../common/TransparentButton'
import UnstyledButton from '../../common/UnstyledButton'
import DeleteTooltip from '../../common/tooltips/DeleteTooltip'
import HeadingText from './HeadingText'
import { ContentSectionProps, FilterSectionProps, cellWithFilterSectionStyles, dynamicHeightTableCell } from '../utils/styles'
import { PromptResult, ResponseType } from '../utils/types'
import { AUTO_RESIZE_TRANSITION_TIME, placeholderPrompt } from '../utils/utils'
import ExpandedInfoTooltip from '../../common/tooltips/ExpandedInfoTooltip'
import { styled, useTheme } from '@mui/material'
import { forwardPropsWithTransient } from '../../../utils/muiUtils'

const Container = styled('td')({
    ...dynamicHeightTableCell,
})

const InnerContainer = styled('div', { shouldForwardProp: forwardPropsWithTransient })<ContentSectionProps>(({ theme, $fullSize }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 30px) 1fr 200px',
    gridTemplateRows: '1fr 30px',
    gridRowGap: '10px',
    ...cellWithFilterSectionStyles.contentSectionStyles({ theme, $fullSize }),

    transition: `width ${AUTO_RESIZE_TRANSITION_TIME}ms ease-out`,
}))

const filterPadding = 12

const FilterContainer = styled('div', { shouldForwardProp: forwardPropsWithTransient })<FilterSectionProps>(({ theme, $disabled }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '35px',
    ...cellWithFilterSectionStyles.filterSectionStyles({ theme, $disabled }),
    padding: `5px ${filterPadding}px`,
    width: `calc(100% - ${filterPadding * 2}px)`,
}))

const DeleteButton = styled(TransparentButton)(({ theme }) => ({
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    padding: '5px',
    border: 'none',
    backgroundColor: theme.palette.secondary.dark,
    position: 'absolute',
    top: '5px',
    right: '5px',
    zIndex: zIndexes.tooltips,
}))

const CollapseButtonTooltipWrapper = styled(ExpandedInfoTooltip)(() => ({
    gridArea: '1 / 1 / 2 / 2',
    alignSelf: 'start',
}))

const CollapseButton = styled(UnstyledButton)(() => ({
    height: '20px',
}))

const StyledCheckboxTooltipWrapper = styled(ExpandedInfoTooltip)(() => ({
    marginTop: '2px',
    gridArea: '1 / 2 / 2 / 3',
}))

const StyledEditable = styled(Editable)(() => ({
    minHeight: '30px',
    height: 'fit-content',
    gridArea: '1 / 3 / 2 / 7',
}))

const ReplaceButton = styled(UnstyledButton)(({ theme }) => ({
    gridArea: '2 / 1 / 3 / 2',

    '&:hover:enabled svg': {
        color: theme.palette.text.primary,
    },
}))

const SavePromptButton = styled(UnstyledButton)(() => ({
    height: '30px',
}))

const BookmarkButtonTooltipWrapper = styled(ExpandedInfoTooltip)(() => ({
    gridArea: '2 / 2 / 3 / 3',
}))

const ToggleFilterContainer = styled('div')(() => ({
    gridArea: '2 / 3 / 3 / 4',
    alignSelf: 'center',
    width: '150px',
}))

const ToggleFilterButton = styled(TransparentButton)(() => ({
    border: 'none',
}))

const DropdownContainer = styled('div')(() => ({
    gridArea: '2 / 4 / 3 / 5',
    display: 'flex',
    justifyContent: 'flex-end',
}))

interface PromptCellProps {
    className?: string
    promptResult: PromptResult
    selected: boolean
    locked: boolean
    onToggle: () => void
    onEdit: (prompt: string) => void
    onReplace: () => void
    onDelete: () => void
    onResponseTypeChange: (responseType: ResponseType) => void
    onFilterChange: (filter: null | boolean | string) => void
}

type Label = 'Yes / No Response' | 'Free Text Response' | 'Short Text Response' | 'Bullet Points' | 'Entity'

const responseTypeLabelMap: Record<ResponseType, Label> = {
    boolean: 'Yes / No Response',
    text: 'Free Text Response',
    short_text: 'Short Text Response',
    bullet_points: 'Bullet Points',
    entity: 'Entity',
}

const PromptCell = ({
    className,
    promptResult: { id, prompt, responseType, filterValue },
    selected,
    locked,
    onToggle,
    onEdit,
    onReplace,
    onDelete,
    onResponseTypeChange,
    onFilterChange,
}: PromptCellProps) => {
    const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false)
    const { palette } = useTheme()

    const showFilterSection = typeof filterValue === 'boolean'

    return (
        <Container className={className}>
            <DeleteTooltip
                id={id}
                targetName='prompt'
                description='Any calculated results for this prompt will be lost.'
                onDelete={onDelete}
                open={deleteTooltipOpen}
                handleClose={() => setDeleteTooltipOpen(false)}
                placement='right'
            >
                <InnerContainer $fullSize={!showFilterSection} className='visible-on-hover-parent'>
                    <DeleteButton
                        className='visible-on-hover'
                        type='button'
                        aria-label='remove prompt'
                        title='Remove prompt'
                        disabled={locked}
                        onClick={() => setDeleteTooltipOpen(true)}
                    >
                        <CrossIcon width={10} height={10} />
                    </DeleteButton>

                    <CollapseButtonTooltipWrapper heading='Coming Soon:' description='Expand / Collapse prompt'>
                        <CollapseButton type='button' aria-label='expand / collapse prompt' disabled>
                            <ChevronIcon width={20} height={18} direction='down' color={palette.secondary.dark} />
                        </CollapseButton>
                    </CollapseButtonTooltipWrapper>
                    <StyledCheckboxTooltipWrapper heading='Coming Soon:' description='Activate / Deactivate prompt'>
                        <Checkbox checked={selected} onClick={onToggle} disabled />
                    </StyledCheckboxTooltipWrapper>
                    <StyledEditable
                        label={prompt}
                        onLabelSubmit={onEdit}
                        typographyVariant='body1'
                        disabled={locked}
                        multiline
                        initiallyEditable={prompt === placeholderPrompt}
                        placeholder='Add a prompt...'
                    />
                    <ReplaceButton type='button' aria-label='open prompt library' title='Prompt library' disabled={locked} onClick={onReplace}>
                        <BookIcon width={20} height={18} color={palette.divider} />
                    </ReplaceButton>
                    <BookmarkButtonTooltipWrapper heading='Coming Soon:' description='Save to Prompt Library'>
                        <SavePromptButton type='button' aria-label='save to prompt library' disabled={locked}>
                            <BookmarkIcon width={20} height={18} color={palette.divider} />
                        </SavePromptButton>
                    </BookmarkButtonTooltipWrapper>
                    {responseType === 'boolean' && (
                        <ToggleFilterContainer>
                            <ToggleFilterButton
                                type='button'
                                aria-label='add / remove filter'
                                onClick={() => onFilterChange(filterValue === null ? true : null)}
                                disabled={locked}
                            >
                                <FilterIcon color={palette.text.secondary} />
                                <HeadingText>{`${filterValue === null ? 'Add' : 'Remove'} Filter`}</HeadingText>
                            </ToggleFilterButton>
                        </ToggleFilterContainer>
                    )}
                    <DropdownContainer>
                        <Dropdown
                            placeholder='Response Style'
                            options={new Set(Object.values(responseTypeLabelMap))}
                            selected={responseTypeLabelMap[responseType]}
                            onSelectionChange={selection => {
                                const key = Object.keys(responseTypeLabelMap).find(key => responseTypeLabelMap[key as ResponseType] === selection) as
                                    | ResponseType
                                    | undefined
                                key && onResponseTypeChange(key)
                            }}
                            compact
                            disabled={locked}
                            typography='subtitle2'
                        />
                    </DropdownContainer>
                </InnerContainer>
            </DeleteTooltip>

            {showFilterSection && (
                <FilterContainer data-testid='filter section'>
                    <FilterIcon width={30} color={palette.text.secondary} />
                    <Dropdown
                        options={new Set(['Yes', 'No'])}
                        selected={filterValue ? 'Yes' : 'No'}
                        onSelectionChange={selection => onFilterChange(selection === 'Yes')}
                        compact
                        disabled={locked}
                        typography='subtitle2'
                    />
                </FilterContainer>
            )}
        </Container>
    )
}

export default PromptCell
