import { MetricType } from 'web-vitals'

const reportWebVitals = (onPerfEntry?: MetricType) => {
    if (onPerfEntry && typeof onPerfEntry === 'function') {
        import('web-vitals').then(({ onCLS, onFCP, onLCP, onTTFB, onINP }) => {
            onCLS(onPerfEntry)
            onFCP(onPerfEntry)
            onLCP(onPerfEntry)
            onTTFB(onPerfEntry)
            onINP(onPerfEntry)
        })
    }
}

export default reportWebVitals
