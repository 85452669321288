import styled from 'styled-components'
import { inputStyles } from './Input'
import { scrollBarStyle } from './styles'

const TextArea = styled.textarea`
    ${inputStyles}
    ${scrollBarStyle}
    outline: none;
    overflow-wrap: anywhere;

    &:focus-visible {
        outline: none;
        border: 1px solid ${props => props.theme.colors.secondary};
    }
`

export default TextArea
