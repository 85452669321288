import { COLLAPSING_TRANSITION_TIME, DOCUMENT_COLUMN_MAX_MANUAL_WIDTH, DOCUMENT_COLUMN_MIN_WIDTH } from './utils'
import { CSSObject, Theme } from '@mui/material'

const filterSection = {
    height: 30,
    verticalPadding: 5,
}
const contentSection = {
    topPadding: 15,
    bottomPadding: 5,
}
const contentSectionPadding = contentSection.topPadding + contentSection.bottomPadding
const usedHeight = contentSectionPadding + filterSection.height + filterSection.verticalPadding * 2

export interface FilterSectionProps {
    $disabled?: boolean
}

export interface ContentSectionProps {
    $fullSize: boolean
}

export const cellWithFilterSectionStyles = {
    filterSectionHeight: `${filterSection.height}px`,

    filterSectionStyles: (props: FilterSectionProps & { theme: Theme }): CSSObject => ({
        height: `${filterSection.height}px`,
        backgroundColor: props.$disabled ? props.theme.palette.action.disabledBackground : props.theme.palette.secondary.light,
        width: '100%',
        padding: `${filterSection.verticalPadding}px 0px`,
    }),

    contentSectionStyles: (props: ContentSectionProps & { theme: Theme }): CSSObject => ({
        padding: `${contentSection.topPadding}px 15px ${contentSection.bottomPadding}px`,
        height: `calc(100% - ${props.$fullSize ? contentSectionPadding : usedHeight}px)`,
    }),
}

export const dynamicHeightTableCell: CSSObject = {
    height: '1px', // Fix height so child can use percentage

    // Undo fixed height in Firefox as it doesn't work
    // https://developer.mozilla.org/en-US/docs/Web/CSS/@document#browser_compatibility:~:text=Only%20supports%20an%20empty%20url%2Dprefix()%20value%2C%20which%20is%20supported%20due%20to%20its%20use%20in%20Firefox%20browser%20detection.%20Still%20supported%20in%20user%20stylesheets.
    // CSS trick for Firefox specific bugs. styles here only apply in Firefox browser.
    '@supports (-moz-appearance: none)': {
        height: 'auto',
        verticalAlign: 'top',
    },
}

export const dynamicDocumentCellStyles = (collapsed: boolean, collapsedWidth: number, width: number): CSSObject => ({
    maxWidth: collapsed ? `${collapsedWidth}px` : `${DOCUMENT_COLUMN_MAX_MANUAL_WIDTH}px`,
    minWidth: collapsed ? 'unset' : `${DOCUMENT_COLUMN_MIN_WIDTH}px`,
    width: `${width}px`,
    transition: `max-width ${COLLAPSING_TRANSITION_TIME}ms ease-out`,
})

export const tableTransitions: CSSObject = {
    transition: 'opacity 0.3s ease, border-color 0.3s ease',
}
