import styled from 'styled-components'
import Button from './Button'
import { highlightOnHover } from './styles'

const TransparentButton = styled(Button)`
    background-color: transparent;
    color: ${props => props.theme.colors.text};
    border-radius: 8px;
    padding: 7px 10px;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 6px;
    ${props => highlightOnHover(props.theme.colors.paper)};

    :disabled {
        background-color: transparent;
        color: ${props => props.theme.colors.disabled};
    }
`

export default TransparentButton
