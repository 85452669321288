import { MouseEvent } from 'react'
import UnstyledButton from '../common/UnstyledButton'
import { PromptSummary } from './types'
import PromptMetaButtons from './PromptMetaButtons'
import { emphasize, styled, Typography, useTheme } from '@mui/material'
import { usePromptContext } from '../../context/PromptContext'
import { PromptLibraryRole, UUID } from '../../types'
import MiniIconButton from '../common/MiniIconButton'
import EditIcon from '../../icons/EditIcon'
import InfoTooltip from '../common/tooltips/InfoTooltip'
import { zIndexes } from '../common/styles'

const StyledPromptCard = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    padding: '14px 20px 14px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 300,
    height: 120,
    transition: 'background-color 0.3s',

    '&:hover': {
        backgroundColor: emphasize(theme.palette.background.paper, 0.03),
    },
}))

const CardActionArea = styled(UnstyledButton)({
    position: 'absolute',
    backgroundColor: 'transparent',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    borderRadius: '10px',
})

const CardHeader = styled('div')({
    display: 'flex',
    alignItems: 'center',
})

const StyledTooltip = styled(InfoTooltip)({
    zIndex: `${zIndexes.button}`,
})

const PromptCardName = styled(Typography)(({ theme }) => ({
    margin: 0,
}))

const PromptCardDescription = styled(Typography)(({ theme }) => ({
    margin: '5px 0 0 0',
    flexGrow: 1,
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}))

const StyledPromptMetaButtons = styled(PromptMetaButtons)({
    marginTop: '12px',
    zIndex: `${zIndexes.button}`,
})

const StyledMiniIconButton = styled(MiniIconButton)(({ theme }) => ({
    padding: '4px',
    margin: '0px 4px 2px 4px',
    '&:hover:not(:disabled)': {
        backgroundColor: theme.palette.secondary.dark,
    },
}))

interface PromptCardProps {
    prompt: PromptSummary
    onClick: () => void
}

const PromptCard = ({ prompt, onClick }: PromptCardProps) => {
    const { palette } = useTheme()
    const { onSelectPrompt, onEditPrompt, selectedLibrary } = usePromptContext()

    const handleEditPrompt = (event: MouseEvent<HTMLButtonElement>, selectedPromptId: UUID) => {
        event.stopPropagation()
        onSelectPrompt(selectedPromptId)
        onEditPrompt(true)
    }

    const handleCardClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        onClick()
    }

    return (
        <StyledPromptCard>
            <CardActionArea
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                    handleCardClick(event)
                }}
                aria-label='prompt card'
            />
            <div>
                <CardHeader>
                    <PromptCardName variant='h6'>{prompt.name}</PromptCardName>
                    {selectedLibrary && selectedLibrary.userRole !== PromptLibraryRole.Viewer && (
                        <StyledTooltip title='Edit Prompt'>
                            <StyledMiniIconButton
                                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                                    handleEditPrompt(event, prompt.id)
                                }}
                                type='button'
                                aria-label='Edit Prompt'
                            >
                                <EditIcon color={palette.text.primary} />
                            </StyledMiniIconButton>
                        </StyledTooltip>
                    )}
                </CardHeader>
                <PromptCardDescription>{prompt.description}</PromptCardDescription>
            </div>
            <StyledPromptMetaButtons prompt={prompt} />
        </StyledPromptCard>
    )
}

export default PromptCard
