import { SVGProps } from 'react'
import { useTheme } from 'styled-components'

const FileIcon = ({ width = 48, height = 48, ...rest }: SVGProps<SVGSVGElement>) => {
    const { colors } = useTheme()

    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width={width} height={height} {...rest}>
            <path
                fill={colors.text}
                d='m25.7 9.3-7-7c-.2-.2-.4-.3-.7-.3H8c-1.1 0-2 .9-2 2v24c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V10c0-.3-.1-.5-.3-.7ZM18 4.4l5.6 5.6H18V4.4ZM24 28H8V4h8v6c0 1.1.9 2 2 2h6v16Z'
            />
            <path d='M0 0h32v32H0z' fill='none' />
        </svg>
    )
}

export default FileIcon
