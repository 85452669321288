import { createContext, useContext, PropsWithChildren, useState } from 'react'
import {
    PromptSummary,
    PromptMetadataResponse,
    Vote,
    PromptDetail,
    PromptSummaryResponse,
    PromptLibraryResponse,
    PromptLibrary,
} from '../components/prompt-library/types'
import {
    promptsEndpoint,
    promptDetailEndpoint,
    promptFavouritingEndpoint,
    librariesEndpoint,
    promptsMetadataEndpoint,
    promptVotingEndpoint,
} from '../endpoints'
import { useDelete } from '../hooks/useDelete'
import { usePost } from '../hooks/usePost'
import { UUID, doNothing, doNothingEventually } from '../types'
import { useChatContext } from './ChatContext'
import { useGet } from '../hooks/useGet'

type View = 'list' | 'new prompt' | 'edit prompt' | 'prompt details' | 'new library' | 'edit library'

interface PromptContextState {
    view: View
    prompts: PromptSummary[] | null
    prompt: PromptDetail | null | undefined
    promptLibraries: PromptLibrary[] | null | undefined
    loading: boolean
    loadingPrompt: boolean
    selectedLibrary: PromptLibrary | null
    onLibraryChange: (library: UUID | 'new' | null) => void
    refreshLibraries: () => void
    onSelectPrompt: (id: UUID | 'new' | null) => void
    refreshPromptList: () => void
    toggleFavourite: (promptId: UUID, isFavourite: boolean) => Promise<void>
    vote: (vote: Vote, promptId: UUID, currentVote: Vote) => Promise<void>
    onEditLibrary: (editing: boolean) => void
    onEditPrompt: (editing: boolean) => void
}

export const defaultState: PromptContextState = {
    view: 'list',
    prompts: null,
    prompt: null,
    promptLibraries: null,
    loading: false,
    loadingPrompt: false,
    selectedLibrary: null,
    onLibraryChange: doNothing,
    refreshLibraries: doNothing,
    onSelectPrompt: doNothing,
    refreshPromptList: doNothing,
    toggleFavourite: doNothingEventually,
    vote: doNothingEventually,
    onEditLibrary: doNothing,
    onEditPrompt: doNothing,
}

export const PromptContext = createContext<PromptContextState>(defaultState)

const WithPromptContextPage = ({ children }: PropsWithChildren) => {
    const [selectedPromptId, setSelectedPromptId] = useState<UUID | 'new' | null>(null)
    const [selectedLibraryId, setSelectedLibraryId] = useState<UUID | 'new' | null>(null)
    const [isEditingLibrary, setIsEditingLibrary] = useState(false)
    const [isEditingPrompt, setIsEditingPrompt] = useState(false)

    const hasSelectedLibrary = selectedLibraryId && selectedLibraryId !== 'new'

    const [promptLibraries, loadingPromptLibraries, refreshLibraries] = useGet<PromptLibraryResponse[]>(librariesEndpoint)
    const [metadataList, , refreshMetadataList] = useGet<PromptMetadataResponse[]>(promptsMetadataEndpoint)
    const [promptList, loadingPromptList, refreshPromptList] = useGet<PromptSummaryResponse[]>(promptsEndpoint)
    const [prompt, loadingPrompt, refreshPrompt] = useGet<PromptDetail>(
        hasSelectedLibrary && selectedPromptId && selectedPromptId !== 'new' ? promptDetailEndpoint(selectedLibraryId, selectedPromptId) : null
    )

    const [favouritePrompt, favouritingLoading] = usePost<null, null, UUID>(hasSelectedLibrary ? promptFavouritingEndpoint(selectedLibraryId) : null)
    const [unFavouritePrompt, unFavouritingLoading] = useDelete(hasSelectedLibrary ? promptFavouritingEndpoint(selectedLibraryId) : null)
    const [votePrompt, voteLoading] = usePost<{ vote: Vote }, null, UUID>(hasSelectedLibrary ? promptVotingEndpoint(selectedLibraryId) : null)

    const loading = loadingPromptList || loadingPromptLibraries

    if (!loading && promptLibraries && promptLibraries.length > 0 && !selectedLibraryId) {
        setSelectedLibraryId(promptLibraries[0].id)
    }

    const refreshMetadata = () => {
        refreshMetadataList()
        prompt && refreshPrompt()
    }

    const toggleFavourite = async (promptId: UUID, isFavourite: boolean) => {
        if (favouritingLoading || unFavouritingLoading) return
        if (isFavourite) {
            await unFavouritePrompt(promptId)
        } else {
            await favouritePrompt(null, promptId)
        }
        refreshMetadata()
    }

    const vote = async (vote: Vote, promptId: UUID, currentVote: Vote) => {
        if (!voteLoading) {
            await votePrompt({ vote: currentVote === vote ? 0 : vote }, promptId)
            refreshMetadata()
        }
    }

    const prompts: PromptSummary[] | null =
        promptList && metadataList
            ? promptList.map(promptSummary => {
                  const metadata = metadataList.find(meta => meta.id === promptSummary.id)
                  return metadata ? { ...promptSummary, ...metadata } : promptSummary
              })
            : null

    const determineView = (): View => {
        if (selectedPromptId === 'new') {
            return 'new prompt'
        }
        if (selectedLibraryId === 'new') {
            return 'new library'
        }
        if (isEditingLibrary) {
            return selectedLibraryId ? 'edit library' : 'list'
        }
        if (selectedPromptId) {
            return isEditingPrompt ? 'edit prompt' : 'prompt details'
        }
        return 'list'
    }

    return (
        <PromptContext.Provider
            value={{
                view: determineView(),
                prompts,
                prompt,
                promptLibraries,
                loading,
                loadingPrompt,
                selectedLibrary: promptLibraries?.find(l => l.id === selectedLibraryId) ?? null,
                onLibraryChange: setSelectedLibraryId,
                refreshLibraries,
                onSelectPrompt: setSelectedPromptId,
                refreshPromptList,
                toggleFavourite,
                vote,
                onEditLibrary: setIsEditingLibrary,
                onEditPrompt: setIsEditingPrompt,
            }}
        >
            {children}
        </PromptContext.Provider>
    )
}

const usePromptContext = () => useContext(PromptContext)

const WithPromptContext = (props: PropsWithChildren) => {
    const { currentChat } = useChatContext()

    return <WithPromptContextPage key={currentChat?.bot.id} {...props} />
}

export { WithPromptContext, usePromptContext }
