import styled from 'styled-components'

const UnstyledButton = styled.button`
    all: unset;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;
`

export default UnstyledButton
