import { SVGProps } from 'react'
import { useTheme } from 'styled-components'

const HandleIcon = (props: SVGProps<SVGSVGElement>) => {
    const { colors } = useTheme()

    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={40} height={25} viewBox='0 0 24 24' {...props}>
            <path
                fill={colors.text}
                fillRule='nonzero'
                d='M3 13h18a1 1 0 0 1 .117 1.993L21 15H3a1 1 0 0 1-.117-1.993L3 13h18H3Zm0-4h18a1 1 0 0 1 .117 1.993L21 11H3a1 1 0 0 1-.117-1.993L3 9h18H3Z'
            />
        </svg>
    )
}

export default HandleIcon
