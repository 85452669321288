import { useCallback } from 'react'
import { useAccessToken } from './useAccessToken'

export const useAuthHeaders = () => {
    const getAccessToken = useAccessToken()

    const generateHeaders = useCallback(async () => {
        const headers = new Headers()
        const bearer = `Bearer ${await getAccessToken()}`
        headers.append('Authorization', bearer)

        return headers
    }, [getAccessToken])

    return generateHeaders
}
