import { SVGProps } from 'react'
import { useTheme } from 'styled-components'

interface AddIconProps extends SVGProps<SVGSVGElement> {
    withCircle?: boolean
}

const AddIcon = ({ color, width = 14, height = 14, strokeWidth = 1.54, withCircle = false, ...rest }: AddIconProps) => {
    const { colors } = useTheme()

    return withCircle ? (
        <svg xmlns='http://www.w3.org/2000/svg' width={19} height={19} fill='none' {...rest}>
            <circle cx={9.5} cy={9.5} r={8.686} fill='transparent' stroke={color || colors.text} strokeWidth={1.627} />
            <path stroke={color || colors.text} strokeLinecap='round' strokeWidth={strokeWidth} d='M9.471 13.884V5.116M5.115 9.5h8.71' />
        </svg>
    ) : (
        <svg width={width} height={height} viewBox={`0 0 14 14`} fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
            <path d='M7.00183 1.66699L7.00183 12.3338' stroke={color || colors.text} strokeWidth={strokeWidth} strokeLinecap='round' />
            <path d='M12.3346 7L1.66783 7' stroke={color || colors.text} strokeWidth={strokeWidth} strokeLinecap='round' />
        </svg>
    )
}

export default AddIcon
