import { useEffect, useState } from 'react'
import { useGet } from './useGet'

const createUrl = (url: string | null, limit: number, offset: number) =>
    url ? `${url}?${new URLSearchParams({ limit: limit.toString(), offset: offset.toString() })}` : null

type UsePagedGetReturn<T> = [latestResults: T[], loading: boolean, getNext: (offsetOffset?: number) => void]

export const usePagedGet = <T>(url: string | null, limit: number = 10): UsePagedGetReturn<T> => {
    const [offset, setOffset] = useState(0)
    const [urlWithPagination, setUrlWithPagination] = useState<string | null>(createUrl(url, limit, offset))

    const [result, loading] = useGet<T[]>(urlWithPagination)

    useEffect(() => {
        setOffset(0)
        setUrlWithPagination(createUrl(url, limit, 0))
    }, [limit, url])

    const getNext = (offsetOffset: number = 0) => {
        if (result?.length) {
            const newOffset = offset + limit + offsetOffset
            setOffset(newOffset)
            setUrlWithPagination(createUrl(url, limit, newOffset))
        }
    }

    return [result ?? [], loading, getNext]
}
