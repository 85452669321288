import UnstyledButton from './UnstyledButton'
import CheckIcon from '../../icons/CheckIcon'
import { ButtonHTMLAttributes } from 'react'
import { styled } from '@mui/material'

const Container = styled(UnstyledButton)(({ theme }) => ({
    width: '16px',
    height: '16px',
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
}))

interface CheckboxProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string
    checked: boolean
}

const Checkbox = ({ checked, ...rest }: CheckboxProps) => (
    <Container type='button' aria-label='checkbox' {...rest}>
        {checked && <CheckIcon />}
    </Container>
)

export default Checkbox
