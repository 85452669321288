import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { LeaseReport } from '../components/lease-report/types'
import { FileWithMetadata, UUID, doNothing } from '../types'
import { useFileContext } from './FileContext'
import { usePost } from '../hooks/usePost'
import { leaseReportsEndpoint } from '../endpoints'
import { useChatContext } from './ChatContext'
import { CreateOrUpdateLeaseReportPayload } from '../apiTypes'
import { toast } from 'react-toastify'
import { identicalFileLists, isInFolder } from '../utils/documents'
import { getLeaseReportPayload, tryFindLatestLeaseContract } from '../utils/leaseReports'
import { ReportState, WithReportContext, defaultState as defaultReportContextState, useReportContext } from './ReportContext'

interface LeaseReportState extends ReportState<LeaseReport> {
    contractFiles: FileWithMetadata[]
    onContractFileChange: (fileId: UUID) => void
    contractFile: FileWithMetadata | null
}

export const defaultState: LeaseReportState = {
    ...defaultReportContextState,
    reports: null,
    selectedReport: null,
    contractFiles: [],
    onContractFileChange: doNothing,
    contractFile: null,
}

export const LeaseReportContext = createContext<LeaseReportState>(defaultState)

const WithLeaseReportContext = ({ children }: PropsWithChildren) => {
    const { currentChat, updateChat, blockChatSwapping, unblockChatSwapping } = useChatContext()
    const { files, upload } = useFileContext()
    const reportContext = useReportContext<LeaseReport>()
    const { folders, onLoadingChange, onUploadFailure, reports, selectedFolder } = reportContext
    const [createOrUpdateLeaseReport] = usePost<CreateOrUpdateLeaseReportPayload, true>(currentChat ? leaseReportsEndpoint(currentChat.id) : null)

    const [contractFiles, setContractFiles] = useState(defaultState.contractFiles)

    const isInSelectedFolder = isInFolder(selectedFolder)
    const contractFile = contractFiles.find(isInSelectedFolder) ?? null

    useEffect(() => {
        setContractFiles(defaultState.contractFiles)
    }, [currentChat?.id])

    useEffect(() => {
        /*
        auto select contract file(s):
        - upon initial upload 
        - when user removes a file which is currently selected as "a" contract file
        - upon an existing lease folder getting a single file uploaded (ONLY if its previous contract file was invalid)
        */

        let newContracts: FileWithMetadata[] = []
        const oldContractIds = contractFiles.map(cf => cf.id) //if user has removed file, contractFiles will still have it
        const currentContracts = files?.filter(f => oldContractIds.includes(f.id)) ?? []
        const getNewLeaseContracts = (newContracts: FileWithMetadata[], folderName: string) => {
            const isInCurrentFolder = isInFolder(folderName)
            const currentContractFile = currentContracts.find(isInCurrentFolder)

            if (currentContractFile) {
                newContracts.push(currentContractFile) //already have a contract file for this lease folder
            } else if (files) {
                // select latest contract file for each lease folder
                const leaseFiles = files.filter(isInCurrentFolder)
                const contract = tryFindLatestLeaseContract(leaseFiles)
                contract && newContracts.push(contract)
            }
            return newContracts
        }

        //uploading to existing lease report
        if (reports?.length) {
            newContracts = reports
                .filter(lr => !lr.leaseContract || lr.leaseContract.state === 'error')
                .map(lr => lr.name)
                .reduce(getNewLeaseContracts, [])
        }
        //no lease reports yet, user has just freshly attached lease documents/folders
        else if (folders.length) {
            newContracts = folders.reduce(getNewLeaseContracts, [])
        }

        if (!identicalFileLists(contractFiles, newContracts)) {
            setContractFiles(newContracts)
        }
    }, [files, reports, folders, contractFiles])

    const handleUploadAndGenerate = async () => {
        const numberOfContracts = (reports?.filter(lr => lr.leaseContract.state !== 'error')?.length ?? 0) + contractFiles.length //already uploaded docs & newly attached files

        if (!files?.length) {
            toast.warn('Please attach at least one file')
            return
        }
        if (folders?.length !== numberOfContracts) {
            toast.warn('Please ensure you have selected a lease contract for each lease folder')
            return
        }
        onLoadingChange('uploading')
        blockChatSwapping()

        const { uploadedIds, rejectedIds } = await upload()

        if (uploadedIds.length) {
            const payload = getLeaseReportPayload(folders, reports, files, contractFiles, rejectedIds)
            const generating = await createOrUpdateLeaseReport(payload)
            if (generating) {
                onLoadingChange('generating')
            } else {
                onLoadingChange(null)
            }

            if (currentChat) {
                const title = folders.length > 1 ? `Bulk lease: ${folders.join(', ')}` : `${contractFile?.file.name ?? 'Lease'} report`
                updateChat(currentChat.id, { title })
            }
        }

        if (rejectedIds.length) {
            onUploadFailure(rejectedIds)
        }

        unblockChatSwapping()
    }

    return (
        <LeaseReportContext.Provider
            value={{
                ...reportContext,
                generateReport: handleUploadAndGenerate,
                contractFiles,
                onContractFileChange: (fileId: UUID) => {
                    setContractFiles(f => {
                        const targetFile = files?.find(f => f.id === fileId)
                        const otherContracts = f.filter(f => !isInFolder(targetFile?.folderName ?? '')(f))
                        if (targetFile) otherContracts.push(targetFile)
                        return otherContracts
                    })
                },
                contractFile,
            }}
        >
            {children}
        </LeaseReportContext.Provider>
    )
}

const WithLeaseEnhancedReportContext = (props: PropsWithChildren) => (
    <WithReportContext getEndpoint={leaseReportsEndpoint}>
        <WithLeaseReportContext {...props} />
    </WithReportContext>
)

const useLeaseReportContext = () => useContext(LeaseReportContext)

export { WithLeaseEnhancedReportContext, useLeaseReportContext }
