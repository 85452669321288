import { Id, toast } from 'react-toastify'
import { DocumentName, FileWithMetadata, UUID } from '../types'
import UploadClient, { chunkSize } from '../utils/UploadClient'
import { captureMessage } from '@sentry/react'
import { supportMessage } from '../utils/userMessages'
import { usePost } from './usePost'
import { DocumentFinalisePayload, DocumentUploadUrlsPayload, DocumentUploadUrlsResponse } from '../apiTypes'
import { useCallback } from 'react'

interface UploadResult {
    documentId: UUID
    uploadSuccessful: boolean
}

const updateToastToError = (toastId: Id) =>
    toast.update(toastId, { type: 'error', render: `Failed to upload document. ${supportMessage}`, isLoading: false, autoClose: 5000 })

export const useUpload = (
    uploadUrlsEndpoint: string | null,
    getFinaliseEndpoint: ((id: UUID) => string) | null
): [(fileWithId: FileWithMetadata) => Promise<UploadResult>, string | null] => {
    const [getUploadUrls] = usePost<DocumentUploadUrlsPayload, DocumentUploadUrlsResponse>(uploadUrlsEndpoint, { suppressDefaultErrorBehaviour: true })
    const [finaliseUpload, , error] = usePost<DocumentFinalisePayload, true, UUID>(getFinaliseEndpoint, { suppressDefaultErrorBehaviour: true })

    const makeRequest = useCallback(
        async (fileWithId: FileWithMetadata): Promise<UploadResult> => {
            const { id: documentId, file } = fileWithId
            const { name, size } = fileWithId.file

            const toastId = toast.loading(`Preparing to upload ${name}`)
            const uploadData = await getUploadUrls({ id: documentId, parts: Math.ceil(size / chunkSize), name })

            if (!uploadData) {
                captureMessage('Failed to get upload URLs from server')
                updateToastToError(toastId)

                return { documentId, uploadSuccessful: false }
            }

            toast.update(toastId, { render: `Uploading ${name}` })
            const uploadClient = new UploadClient(file, uploadData.uploadId, uploadData.key, uploadData.parts, uploadData.cloudProvider)
            const uploadedParts = await uploadClient.uploadInParts()

            if (!uploadedParts) {
                updateToastToError(toastId)

                return { documentId, uploadSuccessful: false }
            }

            const finaliseResponse = await finaliseUpload(
                {
                    key: uploadData.key,
                    uploadId: uploadData.uploadId,
                    // Cast to document name as file should've already been validated
                    name: name as DocumentName,
                    parts: uploadedParts,
                },
                documentId
            )

            if (!finaliseResponse) {
                updateToastToError(toastId)
            }
            toast.dismiss(toastId)

            return { documentId, uploadSuccessful: !!finaliseResponse }
        },
        [getUploadUrls, finaliseUpload]
    )

    return [makeRequest, error]
}
