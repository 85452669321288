import { toast } from 'react-toastify'
import { JSONFormPayload, JSONFormResponse, WarningResponse } from '../../apiTypes'
import { botFieldCreateEndpoint, botFieldSchemaEndpoint } from '../../endpoints'
import { useGet } from '../../hooks/useGet'
import { usePost } from '../../hooks/usePost'
import { UUID } from '../../types'
import FormWrapper from '../forms/FormWrapper'

interface NewFieldProps {
    botId: UUID
    onUpdate: () => void
    onCancel: () => void
}

const NewField = ({ botId, onUpdate, onCancel }: NewFieldProps) => {
    const [schema, schemaLoading] = useGet<JSONFormResponse>(botFieldSchemaEndpoint(botId))
    const [saveField, saveLoading] = usePost<JSONFormPayload, WarningResponse>(botFieldCreateEndpoint(botId))

    const handleSave = async (updatedField: JSONFormPayload) => {
        const response = await saveField(updatedField)

        if (response) {
            if (response.warning) {
                toast.warning(response.warning)
            } else {
                toast.success('Field has been created')
            }
            onUpdate()
        }
    }

    return <FormWrapper formData={schema} disabled={schemaLoading || saveLoading} onSubmit={handleSave} onCancel={onCancel} />
}

export default NewField
