import styled from 'styled-components'
import Button from '../common/Button'
import { useFileContext } from '../../context/FileContext'
import HiddenAttachInput from '../common/HiddenAttachInput'
import { useRef } from 'react'
import CenteredContainer from '../common/CenteredContainer'
import TextButton from '../common/TextButton'
import { useChatContext } from '../../context/ChatContext'
import { useUserContext } from '../../context/UserContext'
import { exceededQuotaMessage } from '../../utils/userMessages'
import { environmentVariables } from '../../env'
import { useReportContext } from '../../context/ReportContext'
import { defaultState, useLeaseReportContext } from '../../context/LeaseReportContext'

export const archivedWarning = `This report has been archived and will be deleted within ${environmentVariables.chatRetentionDays} days. You may no longer edit reports here. Please create a new report.`

const Container = styled(CenteredContainer)`
    justify-content: space-between;
    padding: 0 36px;
    width: unset;
`

const AddButton = styled(TextButton)`
    text-decoration: underline;
`

const WarningText = styled.div`
    color: ${props => props.theme.colors.text};
    margin: auto;
`

interface ReportControlsProps {
    className?: string
}

const ReportControls = ({ className }: ReportControlsProps) => {
    const { hasRemainingQuota } = useUserContext()
    const { currentChat, createChat } = useChatContext()
    const { files } = useFileContext()
    const { loading, selectedFolder, generateReport, selectedReport, reports } = useReportContext()
    const { generateReport: generateLeaseReport } = useLeaseReportContext()

    const uploadRef = useRef<HTMLInputElement>(null)

    if (loading) {
        return null
    }

    if (currentChat?.status === 'archived') {
        return (
            <Container className={className}>
                <WarningText>{archivedWarning}</WarningText>
            </Container>
        )
    }

    if (selectedReport?.state === 'completed') {
        return (
            <Container className={className}>
                <TextButton onClick={() => createChat()} aria-label='new report'>
                    Start new report
                </TextButton>
            </Container>
        )
    }

    if (!hasRemainingQuota) {
        return (
            <Container className={className}>
                <WarningText>{exceededQuotaMessage}</WarningText>
            </Container>
        )
    }

    const genText = `Generate${reports && reports.length > 1 ? ' All' : ''}`

    if (!files && !selectedReport) {
        return (
            <CenteredContainer className={className}>
                <Button disabled aria-label='generate report'>
                    {genText}
                </Button>
            </CenteredContainer>
        )
    }

    return (
        <Container className={className}>
            <AddButton onClick={() => uploadRef.current?.click()} aria-label='add files'>
                Add additional files
                <HiddenAttachInput ref={uploadRef} folderOverride={selectedFolder} />
            </AddButton>
            <Button onClick={generateLeaseReport === defaultState.generateReport ? generateReport : generateLeaseReport} aria-label='generate report'>
                {genText}
            </Button>
        </Container>
    )
}

export default ReportControls
