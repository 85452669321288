import { PropsWithChildren, useEffect } from 'react'
import { createPortal } from 'react-dom'
import CrossIcon from '../../icons/CrossIcon'
import { zIndexes } from './styles'
import TransparentButton from './TransparentButton'
import { styled, Typography } from '@mui/material'
import { forwardPropsWithTransient } from '../../utils/muiUtils'

const Overlay = styled('div')(({ theme }) => ({
    position: 'absolute',
    zIndex: zIndexes.modal,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: `${theme.palette.background.default}99`,
}))

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    flexDirection: 'column',
    padding: '40px 60px',
    borderRadius: '10px',
    maxHeight: '90vh',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    ...(theme.palette.mode === 'dark' && {
        border: '1px solid',
        borderColor: theme.palette.primary.main,
    }),
    [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
        maxWidth: '80%',
    },
}))

const Heading = styled(Typography, {
    shouldForwardProp: forwardPropsWithTransient,
})<{ $alignTitleCenter: boolean }>(({ $alignTitleCenter }) => ({
    marginTop: 0,
    textAlign: $alignTitleCenter ? 'center' : 'left',
}))

const StyledCloseModalButton = styled(TransparentButton)({
    position: 'absolute',
    top: '20px',
    right: '20px',
    svg: {
        width: '25px',
    },
})

export interface ModalProps extends PropsWithChildren {
    className?: string
    heading?: string
    show: boolean
    alignTitleCenter?: boolean
    onClose: () => void
}

const Modal = ({ children, className, heading, show, alignTitleCenter = true, onClose }: ModalProps) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => event.key === 'Escape' && onClose()

        show && window.addEventListener('keydown', handleKeyDown)
        return () => window.removeEventListener('keydown', handleKeyDown)
    }, [show, onClose])

    return show
        ? createPortal(
              <Overlay>
                  <Container className={className}>
                      <StyledCloseModalButton onClick={onClose} aria-label='close modal'>
                          <CrossIcon />
                      </StyledCloseModalButton>
                      {heading && (
                          <Heading variant='h1' $alignTitleCenter={alignTitleCenter}>
                              {heading}
                          </Heading>
                      )}
                      {children}
                  </Container>
              </Overlay>,
              document.getElementById('root') ?? document.body
          )
        : null
}

export default Modal
