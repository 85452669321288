import { DatePicker } from '@mui/x-date-pickers'
import { useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import Button from '../../common/Button'
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, styled } from '@mui/material'
import { adminAnalyticsEndpoint } from '../../../endpoints'
import { useGet } from '../../../hooks/useGet'

type AnalyticsOption = 'user_by_day' | 'user_usage' | 'bot_by_day'

const optionLabels: Record<AnalyticsOption, string> = {
    user_usage: 'User Usage',
    bot_by_day: 'Bot by day',
    user_by_day: 'User by day',
}

const FlexContainer = styled('div')(({ theme }) => ({
    margin: '12px 0 24px 0',
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '12px',
    },
}))

const StyledButton = styled(Button)(() => ({
    width: 120,
    height: 42,
    display: 'flex',
    justifyContent: 'center',
}))

const StyledProgress = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
}))

const AnalyticsContainer = () => {
    const [startDate, setStartDate] = useState<Dayjs>(dayjs())
    const [endDate, setEndDate] = useState<Dayjs>(dayjs())
    const [selectedData, setSelectedData] = useState<AnalyticsOption>('user_by_day')
    const [, loading, getAnalytics] = useGet<Blob>(adminAnalyticsEndpoint(selectedData, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')), {
        requestOnRender: false,
        responseType: 'blob',
    })

    return (
        <>
            <FormControl>
                <InputLabel id='analytics-select'>Report Type</InputLabel>
                <Select
                    labelId='analytics-select'
                    id='analytics_report_type_select'
                    value={selectedData}
                    onChange={({ target: { value } }) => setSelectedData(value as AnalyticsOption)} // Type cast due to MUI bug, value is already typed but gets merged into string
                >
                    {Object.entries(optionLabels).map(([key, value]) => (
                        <MenuItem value={key} key={key}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FlexContainer>
                <FormControl>
                    <InputLabel id='start-date-select'>Start Date</InputLabel>
                    <DatePicker aria-labelledby='Start date' value={startDate} onChange={newValue => newValue && setStartDate(newValue)} />
                </FormControl>
                <FormControl>
                    <InputLabel id='end-date-select'>End Date</InputLabel>
                    <DatePicker aria-labelledby='End date' value={endDate} onChange={newValue => newValue && setEndDate(newValue)} />
                </FormControl>
            </FlexContainer>
            <StyledButton onClick={getAnalytics}>{loading ? <StyledProgress size={24} /> : 'Download'}</StyledButton>
        </>
    )
}

export default AnalyticsContainer
