import { Company, UUID, isCompany, isUUID } from './types'

interface AzureVariables {
    allowedTenantIds: UUID[]
    clientId: UUID
    redirectUris: string[]
    scopes: string[]
}

interface SentryVariables {
    sentryDsn: string
    sentryTracesSampleRate: number
    sentryEnvironment: string
    sentryRelease: string
}

declare global {
    interface Window {
        env: {
            version: string
            apiUris: string
            company?: string
            userMessageCharacterLimit: string
            documentSizeByteLimit: string
            chatRetentionDays: string
            clientFeedbackFormUrl: string
            azure: Record<keyof AzureVariables, string>
            sentry: Record<keyof SentryVariables, string>
        }
    }
    namespace NodeJS {
        interface ProcessEnv {
            REACT_APP_VERSION: string
            REACT_APP_API_URIS: string
            REACT_APP_COMPANY: string
            REACT_APP_USER_MESSAGE_CHARACTER_LIMIT: string
            REACT_APP_DOCUMENT_SIZE_BYTE_LIMIT: string
            REACT_APP_CHAT_RETENTION_DAYS: string
            REACT_APP_ALLOWED_TENANT_IDS: string
            REACT_APP_CLIENT_ID: string
            REACT_APP_REDIRECT_URIS: string
            REACT_APP_SCOPES: string
            REACT_APP_CLIENT_FEEDBACK_FORM_URL: string
            REACT_APP_SENTRY_DSN: string
            REACT_APP_SENTRY_TRACES_SAMPLE_RATE: string
            REACT_APP_SENTRY_ENV: string
            REACT_APP_SENTRY_RELEASE: string
        }
    }
}

interface EnvironmentVariables extends Omit<AzureVariables, 'redirectUris'>, SentryVariables {
    version: string
    apiUri: string
    company: Company
    userMessageCharacterLimit: number
    documentSizeByteLimit: number
    chatRetentionDays: number
    redirectUri: string
    clientFeedbackFormUrl: string | null
}

const getDomainRelevantValue = (jsonArray: string) => {
    // We can cast here because the app is un-usable anyway if this configuration is invalid
    const parsedValues = JSON.parse(jsonArray) as string[]
    // Temporary code for allowing automatic domains provided by Azure until we have custom domain names setup
    const domainValue = parsedValues.find(
        value => value.includes(window.location.hostname) || value.includes('azurecontainerapps') || value.includes('azurestaticapps')
    )

    if (!domainValue) {
        throw new Error(`No valid value found in ${jsonArray} for domain ${window.location.hostname}`)
    }

    return domainValue
}

const companyOrDefault = (value: unknown): Company => (isCompany(value) ? value : 'Springbok')

const uuidOrError = (value: unknown): UUID => {
    if (isUUID(value)) {
        return value
    }

    throw new SyntaxError(`${value} is not a valid UUID`)
}

const validateClientFeedbackFormUrl = (url: string) => {
    try {
        new URL(url)
        return url
    } catch {
        return null
    }
}

export const environmentVariables: EnvironmentVariables =
    process.env.NODE_ENV === 'development'
        ? {
              version: process.env.REACT_APP_VERSION,
              apiUri: getDomainRelevantValue(process.env.REACT_APP_API_URIS),
              company: companyOrDefault(process.env.REACT_APP_COMPANY),
              userMessageCharacterLimit: Number(process.env.REACT_APP_USER_MESSAGE_CHARACTER_LIMIT),
              documentSizeByteLimit: Number(process.env.REACT_APP_DOCUMENT_SIZE_BYTE_LIMIT),
              chatRetentionDays: Number(process.env.REACT_APP_CHAT_RETENTION_DAYS),
              allowedTenantIds: JSON.parse(process.env.REACT_APP_ALLOWED_TENANT_IDS),
              clientId: uuidOrError(process.env.REACT_APP_CLIENT_ID),
              redirectUri: getDomainRelevantValue(process.env.REACT_APP_REDIRECT_URIS),
              scopes: JSON.parse(process.env.REACT_APP_SCOPES),
              clientFeedbackFormUrl: validateClientFeedbackFormUrl(process.env.REACT_APP_CLIENT_FEEDBACK_FORM_URL),
              sentryDsn: process.env.REACT_APP_SENTRY_DSN,
              sentryTracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
              sentryEnvironment: process.env.REACT_APP_SENTRY_ENV,
              sentryRelease: process.env.REACT_APP_SENTRY_RELEASE,
          }
        : {
              ...window.env,
              apiUri: getDomainRelevantValue(window.env.apiUris),
              company: companyOrDefault(window.env.company),
              userMessageCharacterLimit: Number(window.env.userMessageCharacterLimit),
              documentSizeByteLimit: Number(window.env.documentSizeByteLimit),
              chatRetentionDays: Number(window.env.chatRetentionDays),
              clientFeedbackFormUrl: validateClientFeedbackFormUrl(window.env.clientFeedbackFormUrl),
              ...window.env.azure,
              allowedTenantIds: JSON.parse(window.env.azure.allowedTenantIds),
              clientId: uuidOrError(window.env.azure.clientId),
              redirectUri: getDomainRelevantValue(window.env.azure.redirectUris),
              scopes: JSON.parse(window.env.azure.scopes),
              ...window.env.sentry,
              sentryTracesSampleRate: Number(window.env.sentry.sentryTracesSampleRate),
          }
