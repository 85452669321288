import styled from 'styled-components'
import DeleteIcon from '../../icons/DeleteIcon'
import { highlightOnHover } from '../common/styles'
import { useReportContext } from '../../context/ReportContext'
import ReportContent from './ReportContent'
import { Tab, Tabs } from '@mui/material'
import { TabPanel } from '../common/TabPanel'

const DeleteButton = styled.button`
    margin-left: 12px;
    vertical-align: middle;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    ${props => highlightOnHover(props.theme.colors.tertiary)}
`

interface ReportTabsProps {
    className?: string
}

const ReportTabs = ({ className }: ReportTabsProps) => {
    const { folders, onSelectedFolderChange, selectedFolder, onFolderDelete, reports } = useReportContext()

    const tabs = folders.filter(f => f) // remove the empty string representing single file/folder upload
    const localOnly = !reports?.length //no files have been uploaded to backend yet
    const selectedTabIndex = tabs.findIndex(tab => tab === selectedFolder)

    return tabs.length > 0 ? (
        <>
            <Tabs value={selectedTabIndex}>
                {tabs.map(folder => (
                    <Tab
                        onClick={() => onSelectedFolderChange(folder)}
                        key={folder}
                        label={
                            <>
                                {folder}
                                {localOnly && (
                                    <DeleteButton onClick={() => onFolderDelete(folder)}>
                                        <DeleteIcon />
                                    </DeleteButton>
                                )}
                            </>
                        }
                    />
                ))}
            </Tabs>
            {tabs.map((folder, index) => (
                <TabPanel key={folder} index={index} value={selectedTabIndex}>
                    <ReportContent />
                </TabPanel>
            ))}
        </>
    ) : (
        <ReportContent className={className} />
    )
}

export default ReportTabs
