import { ChangeEvent, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Template } from '../../apiTypes'
import { templateFinaliseEndpoint, templateUploadUrlsEndpoint, templateUrlEndpoint, templatesEndpoint } from '../../endpoints'
import { useGet } from '../../hooks/useGet'
import { useUpload } from '../../hooks/useUpload'
import AttachIcon from '../../icons/AttachIcon'
import { UUID } from '../../types'
import { validateTemplateFile } from '../../utils/documents'
import CenteredContainer from '../common/CenteredContainer'
import Chip from '../common/Chip'
import ErrorCard from '../common/ErrorCard'
import Loading from '../common/Loading'
import Instructions from './Instructions'
import { configTabErrors } from '../../utils/userMessages'

const Container = styled.div`
    margin: 10px;
`

const StyledChip = styled(Chip)`
    width: fit-content;
    margin-bottom: 10px;
`

const HiddenInput = styled.input`
    display: none;
`

interface TemplateChipProps {
    botId: UUID
    template: Template
    onUpload: (event: ChangeEvent<HTMLInputElement>) => Promise<void>
}

const TemplateChip = ({ botId, template, onUpload }: TemplateChipProps) => {
    const [templateUrl, loading, getTemplateUrl] = useGet<string>(templateUrlEndpoint(botId, template.id), { requestOnRender: false })
    const hiddenInputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        templateUrl && window.open(templateUrl, '_blank')
    }, [templateUrl])

    return (
        <>
            <StyledChip
                label={template.name}
                title={`download ${template.name}`}
                loading={loading}
                onClick={getTemplateUrl}
                iconTitle={`replace ${template.name}`}
                onIconClick={() => hiddenInputRef.current?.click()}
                Icon={AttachIcon}
            />
            <HiddenInput data-testid='template input' ref={hiddenInputRef} type='file' onChange={onUpload} />
        </>
    )
}

interface TemplateDisplayProps {
    botId: UUID
    readonly: boolean
}

const TemplateDisplay = ({ botId, readonly }: TemplateDisplayProps) => {
    const [template, loading, refresh] = useGet<Template>(templatesEndpoint(botId))
    const [uploadFile, templateError] = useUpload(templateUploadUrlsEndpoint(botId), templateFinaliseEndpoint(botId))

    const handleUpload = (templateId: UUID) => async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        if (readonly) {
            toast.warn('This tool is live and can not be edited')
            return
        }

        const file = validateTemplateFile(event.target.files)

        if (file) {
            const result = await uploadFile({ id: templateId, file })

            if (result.uploadSuccessful) {
                toast.success('Template contents have been updated')
                refresh()
            }
        }
    }

    return (
        <>
            {readonly && <ErrorCard error={configTabErrors} />}
            {loading ? (
                <CenteredContainer>
                    <Loading secondaryColor fullSize />
                </CenteredContainer>
            ) : (
                <Container>
                    <Instructions>
                        You can configure a template used to produce the final report for the user.
                        <br />
                        The template should be a Word document with Jinja2 placeholders for field values and metadata. To get started, download the default
                        template and modify it using Microsoft Word see the Help tab for details of available syntax.
                    </Instructions>
                    {template ? <TemplateChip botId={botId} template={template} onUpload={handleUpload(template.id)} /> : <p>No template found</p>}
                    {templateError && <ErrorCard error={templateError} />}
                </Container>
            )}
        </>
    )
}

export default TemplateDisplay
