import FileIcon from '../../icons/FileIcon'
import UnstyledButton from './UnstyledButton'
import { overflowText } from './styles'
import CrossIcon from '../../icons/CrossIcon'
import { useFileContext } from '../../context/FileContext'
import { Document, UUID, isDownloadableState } from '../../types'
import Button from './Button'
import { PropsWithChildren } from 'react'
import { useChatContext } from '../../context/ChatContext'
import { useDocumentDownload } from '../../hooks/useDocumentDownload'
import Loading from './Loading'
import ErrorCard from './ErrorCard'
import { styled, Typography } from '@mui/material'

const Container = styled('div')(({ theme }) => ({
    padding: '16px',
    display: 'grid',
    gridTemplateColumns: '48px 1fr auto',
    gridTemplateRows: '52% 48%',
    gridRowGap: '5px',
    backgroundColor: theme.palette.background.default,
    border: '1px solid #d8d8d8',
    borderRadius: '8px',
}))

const StyledFileIcon = styled(FileIcon)({
    gridArea: '1 / 1 / 3 / 2',
    justifySelf: 'center',
    alignSelf: 'center',
    width: '48px',
    height: '48px',
})

const Name = styled(Typography)({
    gridArea: '1 / 2 / 2 / 3',
    margin: 0,
    alignSelf: 'end',
    ...overflowText,
})

const Size = styled(Typography)(({ theme }) => ({
    gridArea: '2 / 2 / 3 / 3',
    margin: 0,
    color: theme.palette.text.disabled,
    ...overflowText,
}))

const ChildContainer = styled('div')({
    gridArea: '1 / 3 / 3 / 4',
    justifySelf: 'end',
    alignSelf: 'center',
    margin: '0 0 0 30px',
})

interface BaseCardProps extends PropsWithChildren {
    className?: string
    name: string
    size?: number
    error?: string
}

const BaseCard = ({ className, children, name, size, error }: BaseCardProps) => (
    <div className={className}>
        <Container>
            <StyledFileIcon />
            <Name>{name}</Name>
            {size && <Size variant='body2'>{`${size / 1000}KB`}</Size>}
            <ChildContainer>{children}</ChildContainer>
        </Container>
        {error && <ErrorCard error={error} />}
    </div>
)

const defaultErrorText = (name: string) =>
    `We are unable to proceed with ${name}. It will be ignored in report generation. You may try uploading a new document.`

interface DocumentCardProps {
    className?: string
    document: Document
}

export const DocumentCard = ({ className, document: { id, name, state, error } }: DocumentCardProps) => {
    const { currentChat } = useChatContext()
    const [download, loading] = useDocumentDownload(currentChat?.id ?? null, id, state)

    return (
        <BaseCard className={className} name={name} error={state === 'error' ? error ?? defaultErrorText(name) : undefined}>
            {isDownloadableState(state) &&
                (loading ? (
                    <Loading secondaryColor fullSize />
                ) : (
                    <Button onClick={download} aria-label='download document'>
                        Download
                    </Button>
                ))}
        </BaseCard>
    )
}

interface FileCardProps {
    className?: string
    id: UUID
    name: string
    size: number
    error?: string
}

export const FileCard = ({ id, ...rest }: FileCardProps) => {
    const { onRemove } = useFileContext()

    return (
        <BaseCard {...rest}>
            <UnstyledButton onClick={() => onRemove(id)} aria-label='remove file'>
                <CrossIcon />
            </UnstyledButton>
        </BaseCard>
    )
}
