import { useEffect } from 'react'
import { documentDownloadUrlEndpoint } from '../endpoints'
import { DocumentState, UUID, doNothing, isDownloadableState } from '../types'
import { useGet } from './useGet'
import useLoadingDebounce from './useLoadingDebounce'

export const useDocumentDownload = (chatId: UUID | null, documentId: UUID, documentState: DocumentState): [() => void, boolean] => {
    const [documentUrl, loading, getDocumentUrl] = useGet<string>(chatId ? documentDownloadUrlEndpoint(chatId, documentId) : null, { requestOnRender: false })
    const debouncedLoading = useLoadingDebounce(loading, 500)

    useEffect(() => {
        documentUrl && window.open(documentUrl, '_blank')
    }, [documentUrl])

    return [isDownloadableState(documentState) ? getDocumentUrl : doNothing, debouncedLoading]
}
