import styled from 'styled-components'
import { zIndexes } from './styles'

const StyledButton = styled.button<{ $isOpen: boolean }>`
    position: absolute;
    top: 36px;
    right: 36px;
    z-index: ${zIndexes.mobileNavigationButton};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    width: 2em;
    height: 1.6em;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    span {
        height: 0.3em;
        background-color: ${({ $isOpen }) => ($isOpen ? props => props.theme.colors.text : props => props.theme.colors.text)};
        border-radius: 20px;
        transition: all 0.3s ease;
        position: relative;
        transform-origin: 6px;

        &:first-child {
            transform: ${({ $isOpen }) => ($isOpen ? 'rotate(45deg)' : 'rotate(0)')};
            width: ${({ $isOpen }) => ($isOpen ? '2.1em' : '2.5em')};
        }

        &:nth-child(2) {
            transform: ${({ $isOpen }) => ($isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
            width: ${({ $isOpen }) => ($isOpen ? '2.1em' : '1.6em')};
        }
    }
`

interface BurgerButtonProps {
    isOpen: boolean
    onClick: () => void
}

const BurgerButton = ({ isOpen, onClick }: BurgerButtonProps) => (
    <StyledButton $isOpen={isOpen} onClick={onClick} aria-label='toggle menu'>
        <span />
        <span />
    </StyledButton>
)

export default BurgerButton
