import HeadingText from './HeadingText'
import TransparentButton from '../../common/TransparentButton'
import CrossIcon from '../../../icons/CrossIcon'
import { UUID } from '../../../types'
import DeleteTooltip from '../../common/tooltips/DeleteTooltip'
import UnstyledButton from '../../common/UnstyledButton'
import { styled } from '@mui/material'
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded'
import { dynamicDocumentCellStyles } from '../utils/styles'
import { zIndexes } from '../../common/styles'
import useDebounce from '../../../hooks/useDebounce'
import {
    AUTO_RESIZE_TRANSITION_TIME,
    COLLAPSING_TRANSITION_TIME,
    DOCUMENT_COLUMN_COLLAPSED_WIDTH,
    DOCUMENT_COLUMN_MAX_MANUAL_WIDTH,
    DOCUMENT_COLUMN_MIN_WIDTH,
} from '../utils/utils'
import { rotate180degAnimation } from '../../common/Theme'
import { clamp } from '../../../utils/formatting'
import { useState } from 'react'
import ExpandedInfoTooltip from '../../common/tooltips/ExpandedInfoTooltip'
import { forwardPropsWithTransient } from '../../../utils/muiUtils'

// Changes to the width of this component may require updates to the autosize calculations

const CollapsibleCell = styled('div', { shouldForwardProp: forwardPropsWithTransient })<{ $collapsed: boolean; $width: number; $animateWidth: boolean }>(
    ({ $collapsed, $width, $animateWidth }) => ({
        ...dynamicDocumentCellStyles($collapsed, DOCUMENT_COLUMN_COLLAPSED_WIDTH, $width),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...($animateWidth && { transition: `width ${AUTO_RESIZE_TRANSITION_TIME}ms ease-out` }),
    })
)

const TitleSpan = styled('span')(() => ({
    display: 'inline-flex',
    justifyContent: 'flex-start',
    width: 'calc(100% - 26px)',
}))

const InnerContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    width: '100%',
}))

const DeleteButton = styled(TransparentButton)(() => ({
    marginLeft: '8px',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    padding: '5px',
}))

const CollapseButton = styled(UnstyledButton)(({ theme }) => ({
    height: '20px',

    '& .icon': {
        fontSize: 20,
        color: theme.palette.text.secondary,

        '&.collapsed-true': {
            animation: `${rotate180degAnimation} 0.2s forwards`,
        },
        '&.collapsed-false': {
            animation: `${rotate180degAnimation} 0.2s reverse forwards`,
        },
    },
}))

const ResizeHandle = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: -3,
    top: 3,
    bottom: 0,
    width: 4,
    height: '46px',
    backgroundColor: theme.palette.divider,
    borderRadius: 12,
    cursor: 'col-resize',
    userSelect: 'none',
    zIndex: zIndexes.elevated,
    transition: 'all 0.2s ease',

    '&:hover': {
        width: 8,
        right: -5,
    },

    '&:active': {
        backgroundColor: theme.palette.primary.main,
        width: 8,
        right: -5,
    },
}))

interface DocumentHeadingProps {
    id: UUID
    name: string
    locked: boolean
    onDelete: () => void
    collapsed: boolean
    onCollapse: () => void
    width: number
    onWidthChange: (newWidth: number | null) => void
}

const DocumentHeading = ({ id, name, locked, onDelete, collapsed, onCollapse, width, onWidthChange }: DocumentHeadingProps) => {
    const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false)
    const debouncedDisableTooltip = useDebounce(!collapsed, COLLAPSING_TRANSITION_TIME) // Tooltip needs to wait for collapsing animation to end before showing
    const [animateWidth, setAnimateWidth] = useState(false)

    const handleMouseMove = ({ clientX }: MouseEvent, startX: number, initialWidth: number) => {
        const newWidth = initialWidth + (clientX - startX)
        onWidthChange(clamp(newWidth, DOCUMENT_COLUMN_MIN_WIDTH, DOCUMENT_COLUMN_MAX_MANUAL_WIDTH))
    }

    const handleMouseDown = ({ clientX }: React.MouseEvent<HTMLDivElement>) => {
        const startX = clientX

        const onMouseMove = (moveEvent: MouseEvent) => {
            handleMouseMove(moveEvent, startX, width)
        }

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('mouseup', onMouseUp)
        }

        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseup', onMouseUp)
    }

    const handleAutoSize = () => {
        setAnimateWidth(true)
        setTimeout(() => {
            setAnimateWidth(false)
        }, AUTO_RESIZE_TRANSITION_TIME)

        onWidthChange(null)
    }

    return (
        <th>
            <CollapsibleCell $collapsed={collapsed} $width={width} $animateWidth={animateWidth}>
                <ExpandedInfoTooltip
                    heading={name}
                    description=''
                    disabled={collapsed ? debouncedDisableTooltip : true}
                    disableInteractive
                    key={`tooltip-${collapsed}`}
                >
                    <InnerContainer>
                        <CollapseButton type='button' aria-label='expand / collapse this column' onClick={onCollapse} title={collapsed ? '' : 'Collapse'}>
                            <KeyboardDoubleArrowRightRoundedIcon
                                className={`icon collapsed-${collapsed}`}
                                data-testid={collapsed ? 'collapsed-icon' : 'expanded-icon'}
                            />
                        </CollapseButton>
                        {!collapsed && (
                            <>
                                <DeleteTooltip
                                    id={id}
                                    targetName={name}
                                    description='Any calculated results for this document will be lost.'
                                    onDelete={onDelete}
                                    open={deleteTooltipOpen}
                                    handleClose={() => setDeleteTooltipOpen(false)}
                                >
                                    <TitleSpan className='visible-on-hover-parent'>
                                        <HeadingText>{name}</HeadingText>
                                        <DeleteButton
                                            className='visible-on-hover'
                                            type='button'
                                            aria-label='remove document'
                                            title='Remove document'
                                            disabled={locked}
                                            onClick={() => setDeleteTooltipOpen(true)}
                                        >
                                            <CrossIcon width={10} height={10} />
                                        </DeleteButton>
                                    </TitleSpan>
                                </DeleteTooltip>
                            </>
                        )}
                    </InnerContainer>
                </ExpandedInfoTooltip>
                {!collapsed && <ResizeHandle onMouseDown={handleMouseDown} onDoubleClick={handleAutoSize} aria-label='resize column' />}
            </CollapsibleCell>
        </th>
    )
}

export default DocumentHeading
