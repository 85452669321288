import styled, { css } from 'styled-components'

export const inputStyles = css`
    padding: 10px;
    border-radius: 6px;
    border: none;
    background-color: ${props => props.theme.colors.background};
    border: 1px solid ${props => props.theme.colors.divider};
    color: ${props => props.theme.colors.text};

    &:disabled {
        background-color: ${props => props.theme.colors.divider};
        color: ${props => props.theme.colors.disabled};
        cursor: not-allowed;
    }

    &:focus-visible {
        outline: none;
        border: 1px solid ${props => props.theme.colors.secondary};
    }
`

const Input = styled.input`
    ${inputStyles}
`

export default Input
