import { UUID } from 'crypto'
import { userQuotaEndpoint } from '../../../endpoints'
import { styled } from '@mui/material'
import { usePost } from '../../../hooks/usePost'
import Editable from '../../common/Editable'
import { formatToUSD } from '../../../utils/formatting'

const StyledEditable = styled(Editable)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
}))

interface UserQuotaInterface {
    quota: number
    userId: UUID
    onUserStateChange: () => void
}

const UserQuotaEditable = ({ quota, userId, onUserStateChange }: UserQuotaInterface) => {
    const [requestUserQuotaChange] = usePost(userQuotaEndpoint(userId))

    const handleUserRoleChange = async (value: string) => {
        const success = await requestUserQuotaChange({ quota: value })
        success && onUserStateChange()
    }

    return <StyledEditable label={quota.toString()} onLabelSubmit={handleUserRoleChange} type='numeric' textFormatter={formatToUSD} controlledLabel />
}

export default UserQuotaEditable
