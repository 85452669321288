import { SVGProps } from 'react'
import { useTheme } from 'styled-components'

const ErrorIcon = ({ color, ...rest }: SVGProps<SVGSVGElement>) => {
    const { colors } = useTheme()

    return (
        <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
            <path
                d='M18.375 10.5C18.375 6.15234 14.8477 2.625 10.5 2.625C6.15234 2.625 2.625 6.15234 2.625 10.5C2.625 14.8477 6.15234 18.375 10.5 18.375C14.8477 18.375 18.375 14.8477 18.375 10.5Z'
                stroke={color ? color : colors.error}
                strokeWidth='1.3125'
                strokeMiterlimit='10'
            />
            <path
                d='M10.2646 6.81056L10.5001 11.8124L10.7351 6.81056C10.7365 6.77858 10.7315 6.74665 10.7202 6.7167C10.7088 6.68676 10.6915 6.65943 10.6693 6.6364C10.6471 6.61337 10.6204 6.59511 10.5909 6.58274C10.5614 6.57037 10.5296 6.56415 10.4976 6.56446C10.466 6.56476 10.4347 6.57143 10.4058 6.58407C10.3768 6.59671 10.3506 6.61506 10.3289 6.63803C10.3071 6.661 10.2902 6.68812 10.2792 6.71776C10.2682 6.7474 10.2632 6.77896 10.2646 6.81056Z'
                stroke={color ? color : colors.error}
                strokeWidth='1.3125'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.5 15.0898C10.3378 15.0898 10.1792 15.0417 10.0443 14.9516C9.90936 14.8615 9.80422 14.7333 9.74213 14.5835C9.68004 14.4336 9.6638 14.2686 9.69545 14.1095C9.7271 13.9504 9.80523 13.8042 9.91995 13.6895C10.0347 13.5748 10.1808 13.4966 10.34 13.465C10.4991 13.4333 10.664 13.4496 10.8139 13.5117C10.9638 13.5737 11.0919 13.6789 11.1821 13.8138C11.2722 13.9487 11.3203 14.1073 11.3203 14.2695C11.3203 14.4871 11.2339 14.6957 11.08 14.8496C10.9262 15.0034 10.7176 15.0898 10.5 15.0898Z'
                fill={color ? color : colors.error}
            />
        </svg>
    )
}

export default ErrorIcon
