import { Card } from './Card'
import { Container, baseCardStyles } from './ChatList'
import { useChatContext } from '../context/ChatContext'
import { Bot } from '../types'
import { botListEndpoint } from '../endpoints'
import Loading from './common/Loading'
import { useGet } from '../hooks/useGet'
import CenteredContainer from './common/CenteredContainer'
import useIsMobile from '../hooks/useIsMobile'
import { styled } from '@mui/material'

const StyledBotCard = styled(Card)(({ theme }) => ({
    ...baseCardStyles(theme),
    border: `1px solid ${theme.palette.text.primary}`,
    '&:hover': {
        backgroundColor: `${theme.palette.secondary.light}90`,
    },
}))

const TopScrollMask = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '235px',
    width: 'calc(100% - 5px)',
    height: '40px',
    pointerEvents: 'none',
    backgroundImage: `linear-gradient(${theme.palette.secondary.dark}, transparent)`,
}))

const BottomScrollMask = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: '76px',
    width: 'calc(100% - 5px)',
    height: '40px',
    pointerEvents: 'none',
    backgroundImage: `linear-gradient(transparent, ${theme.palette.secondary.dark})`,
}))
interface BotListProps {
    className?: string
}

const BotList = ({ className }: BotListProps) => {
    const { createChat } = useChatContext()
    const isMobile = useIsMobile()
    const [bots, loading] = useGet<Bot[]>(botListEndpoint)

    if (loading || !bots) {
        return (
            <CenteredContainer aria-label='bot list'>
                <Loading secondaryColor />
            </CenteredContainer>
        )
    }

    return (
        <>
            {!isMobile && <TopScrollMask />}
            <Container className={className} aria-label='bot list'>
                {bots.map(bot => (
                    <StyledBotCard
                        key={bot.id}
                        heading={bot.name}
                        subheading={bot.description}
                        onClick={() => createChat(bot)}
                        size='small'
                        bot={bot}
                        compact={false}
                        showConfig
                    ></StyledBotCard>
                ))}
            </Container>
            {!isMobile && <BottomScrollMask />}
        </>
    )
}

export default BotList
