import styled from 'styled-components'

const Header = styled.header`
    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    border-bottom: 1px solid ${props => props.theme.colors.divider};
    border-radius: 16px 0 0 0;
`

export default Header
