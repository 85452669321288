import { ChangeEvent, ForwardedRef, HTMLProps, RefObject, forwardRef, useEffect } from 'react'
import { useFileContext } from '../../context/FileContext'
import { allowedFileExtensions, convertFileListToFilesWithMetadata } from '../../utils/documents'

const isRefObject = (ref: ForwardedRef<HTMLInputElement>): ref is RefObject<HTMLInputElement> => ref !== null && 'current' in ref

interface HiddenAttachInputProps extends HTMLProps<HTMLInputElement> {
    folderMode?: boolean
    folderOverride?: string
}

const HiddenAttachInput = forwardRef<HTMLInputElement, HiddenAttachInputProps>(({ folderMode, folderOverride }, ref) => {
    const { files, onAttach } = useFileContext()

    useEffect(() => {
        if (!files && isRefObject(ref)) {
            if (ref.current) {
                // Input is uncontrolled, sync with our state. Allows user to upload same file if it has been removed by them or context
                ref.current.value = ''
            }
        }
    }, [ref, files])

    const handleAttach = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        if (folderMode || typeof folderOverride === 'string') {
            // when uploading via chrome using this input method, webkitRelativePath IS filled in
            const filesWithMetadata = event.target.files ? convertFileListToFilesWithMetadata(event.target.files, folderOverride) : null
            onAttach(filesWithMetadata)
        } else {
            onAttach(event.target.files)
        }
    }

    return (
        <input
            data-testid='attach input'
            ref={ref}
            type='file'
            accept={allowedFileExtensions.map(e => `.${e}`).join(',') + ',.json'}
            multiple
            onChange={handleAttach}
            // We can't use styled components here as we are using properties it does not know, hence it will filter them and not pass to the HTML element
            style={{ display: 'none' }}
            // directory & webkitdirectory are not known properties
            // @ts-ignore
            directory={folderMode ? '' : undefined}
            webkitdirectory={folderMode ? '' : undefined}
        />
    )
})

export default HiddenAttachInput
