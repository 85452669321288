import { SVGProps } from 'react'
import { useTheme } from 'styled-components'

const TickIcon = (props: SVGProps<SVGSVGElement>) => {
    const { colors } = useTheme()

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={30}
            height={30}
            fill='none'
            stroke={colors.text}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={1.5}
            viewBox='0 0 16 16'
            {...props}
        >
            <path d='m2.75 8.75 3.5 3.5 7-7.5' />
        </svg>
    )
}

export default TickIcon
