import { SkimmerPayload } from '../../../apiTypes'
import { Dollar } from '../../../types'
import { DocumentResult, PromptResult, ResponseType, SkimmerReport } from './types'

export const placeholderPrompt = ''
const removeEmptyPrompts = (results: PromptResult[]) => results.filter(r => r.prompt !== placeholderPrompt)

export const documentResultIsFiltered = ({ filterValue }: PromptResult, { value }: DocumentResult) =>
    filterValue !== null && value !== null && value !== filterValue.toString()

export const generatePayload = (results: PromptResult[], costCap: Dollar): SkimmerPayload => ({
    prompts: removeEmptyPrompts(results),
    costCap,
})

export const isPromptDataDirty = (serverData: PromptResult[], localData: PromptResult[]) => {
    const filteredLocalData = removeEmptyPrompts(localData)

    return (
        filteredLocalData.length !== serverData.length ||
        filteredLocalData.some(localResult => {
            const serverIds = serverData.map(({ id }) => id)
            const localIds = filteredLocalData.map(({ id }) => id)
            const promptOrderChanged = serverIds.join() !== localIds.join()
            const serverResult = serverData.find(serverResult => serverResult.id === localResult.id)
            return !serverResult || serverResult.digest !== localResult.digest || serverResult.filterValue !== localResult.filterValue || promptOrderChanged
        })
    )
}

export const isDirty = (serverData: SkimmerReport, localPrompts: PromptResult[], costCapOverride: Dollar | null) =>
    (costCapOverride && serverData.costCap !== costCapOverride) || isPromptDataDirty(serverData.results, localPrompts)

export const generatePayloadIfDirty = (serverData: SkimmerReport | null | undefined, localPrompts: PromptResult[] | null, costCapOverride: Dollar | null) =>
    serverData && localPrompts?.length && isDirty(serverData, localPrompts, costCapOverride)
        ? generatePayload(localPrompts, costCapOverride ?? serverData.costCap)
        : null

// Must be in sync with BE calculation
interface DigestData {
    prompt: string
    response_type: ResponseType
}

export const calculateDigest = async (prompt: string, responseType: ResponseType) => {
    const digestData: DigestData = { prompt, response_type: responseType }
    const encoded = new TextEncoder().encode(JSON.stringify(digestData))
    const hashBuffer = await window.crypto.subtle.digest('SHA-1', encoded)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')

    return hashHex.slice(0, 16)
}

export const DOCUMENT_COLUMN_MAX_MANUAL_WIDTH = 2000
export const DOCUMENT_COLUMN_MAX_AUTO_WIDTH = 500
export const DOCUMENT_COLUMN_MIN_WIDTH = 150
export const DOCUMENT_COLUMN_COLLAPSED_WIDTH = 20
export const DOCUMENT_HEADER_PADDING = 16
export const COLLAPSING_TRANSITION_TIME = 300
export const AUTO_RESIZE_TRANSITION_TIME = 300
