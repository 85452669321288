export const exponentialIncrement = (minimum: number, maximum: number) => (i: number) => Math.min(Math.pow(2, i) * minimum, maximum)

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export const cancellableSleep = (abortSignal: AbortSignal) => (ms: number) =>
    new Promise((resolve, reject) => {
        const timeout = setTimeout(resolve, ms)

        try {
            abortSignal.throwIfAborted()
        } catch (error) {
            if (abortSignal.aborted) {
                clearTimeout(timeout)
                reject(error)
            }

            throw error
        }
    })
