import { useCallback, useEffect, useState } from 'react'
import { useAuthHeaders } from './useAuthHeaders'
import { captureException, captureMessage } from '@sentry/react'
import { genericErrorMsg } from '../utils/userMessages'
import { toast } from 'react-toastify'
import { downloadBlob } from '../utils/download'

type UseGetReturn<T> = [result: T | null | undefined, loading: boolean, refresh: () => void]

type ResponseType = 'json' | 'blob'

interface GetOptions {
    requestOnRender: boolean
    responseType: ResponseType
}

const defaultOptions: GetOptions = {
    requestOnRender: true,
    responseType: 'json',
}

export const useGet = <T>(url: string | null, options?: Partial<GetOptions>): UseGetReturn<T> => {
    const { requestOnRender, responseType } = { ...defaultOptions, ...options }
    const [result, setResult] = useState<T | null>()
    const [loading, setLoading] = useState(requestOnRender)
    const generateHeaders = useAuthHeaders()

    const makeRequest = useCallback(async () => {
        if (url) {
            setLoading(true)

            try {
                const response = await fetch(url, { method: 'GET', headers: await generateHeaders() })

                if (response.ok) {
                    if (response.status === 204) {
                        setResult(null)
                    } else {
                        if (responseType === 'blob') {
                            await downloadBlob(response)
                        } else {
                            const json = await response.json()
                            setResult(json)
                        }
                    }
                } else {
                    captureMessage(`Failed GET request: ${response.status} - ${response.statusText}`)
                    toast.error(genericErrorMsg)
                }
            } catch (error) {
                captureException(error)
                toast.error(genericErrorMsg)
            }

            setLoading(false)
        }
    }, [url, generateHeaders, responseType])

    useEffect(() => {
        setResult(undefined)
    }, [url])

    useEffect(() => {
        requestOnRender && makeRequest()
    }, [makeRequest, requestOnRender])

    return [result, loading, makeRequest]
}
