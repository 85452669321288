import styled from 'styled-components'
import { mediaQueries } from './styles'

const Container = styled.div`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    display: flex;
    border-top: 1px solid ${props => props.theme.colors.divider};
    width: 100%;
    height: 90px;
    margin: 0;

    @media ${mediaQueries.mobile} {
        margin: 12px;
    }
`

const WarningText = styled.div`
    color: ${props => props.theme.colors.text};
    margin: auto;
`

interface WarningAreaProps {
    message: string
}

const WarningArea = ({ message }: WarningAreaProps) => (
    <Container>
        <WarningText>{message}</WarningText>
    </Container>
)

export default WarningArea
