import styled from 'styled-components'

const Link = styled.a`
    font-size: small;
    font-style: italic;
    margin: 0;

    &:visited {
        color: ${({ theme }) => theme.colors.text};
    }
`

interface PoweredBySpringbokProps {
    className?: string
}

const PoweredBySpringbok = ({ className }: PoweredBySpringbokProps) => (
    <Link className={className} href='https://springbok.ai'>
        Powered by Springbok AI
    </Link>
)

export default PoweredBySpringbok
