import { SVGProps } from 'react'

const BinIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <g clipPath='url(#clip0_203_3076)'>
            <path
                d='M5.32812 1.6125L4.73438 2.5H9.26562L8.67188 1.6125C8.625 1.54375 8.54688 1.5 8.4625 1.5H5.53438C5.45 1.5 5.37187 1.54062 5.325 1.6125H5.32812ZM9.92188 0.78125L11.0688 2.5H11.5H13H13.25C13.6656 2.5 14 2.83437 14 3.25C14 3.66563 13.6656 4 13.25 4H13V13.5C13 14.8813 11.8813 16 10.5 16H3.5C2.11875 16 1 14.8813 1 13.5V4H0.75C0.334375 4 0 3.66563 0 3.25C0 2.83437 0.334375 2.5 0.75 2.5H1H2.5H2.93125L4.07812 0.778125C4.40312 0.29375 4.95 0 5.53438 0H8.4625C9.04688 0 9.59375 0.29375 9.91875 0.778125L9.92188 0.78125ZM2.5 4V13.5C2.5 14.0531 2.94688 14.5 3.5 14.5H10.5C11.0531 14.5 11.5 14.0531 11.5 13.5V4H2.5ZM5 6V12.5C5 12.775 4.775 13 4.5 13C4.225 13 4 12.775 4 12.5V6C4 5.725 4.225 5.5 4.5 5.5C4.775 5.5 5 5.725 5 6ZM7.5 6V12.5C7.5 12.775 7.275 13 7 13C6.725 13 6.5 12.775 6.5 12.5V6C6.5 5.725 6.725 5.5 7 5.5C7.275 5.5 7.5 5.725 7.5 6ZM10 6V12.5C10 12.775 9.775 13 9.5 13C9.225 13 9 12.775 9 12.5V6C9 5.725 9.225 5.5 9.5 5.5C9.775 5.5 10 5.725 10 6Z'
                fill={props.fill}
            />
        </g>
        <defs>
            <clipPath id='clip0_203_3076'>
                <rect width='14' height='16' fill={props.fill} />
            </clipPath>
        </defs>
    </svg>
)

export default BinIcon
