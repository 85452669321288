import styled, { useTheme } from 'styled-components'
import MiniIconButton from '../common/MiniIconButton'
import ChevronIcon from '../../icons/ChevronIcon'
import StarIcon from '../../icons/StarIcon'
import { PromptDetail, PromptSummary } from '../prompt-library/types'
import { usePromptContext } from '../../context/PromptContext'
import { Typography } from '@mui/material'
import InfoTooltip from '../common/tooltips/InfoTooltip'

const IconContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
`

const StyledMiniStarIconButton = styled(MiniIconButton)<{ isActive: boolean }>`
    background-color: ${props => (props.isActive ? props.theme.colors.secondary : props.theme.colors.tertiary)};
`

const StyledMiniChevronButton = styled(MiniIconButton)<{ isActive: boolean }>`
    background-color: ${props => (props.isActive ? props.theme.colors.secondary : props.theme.colors.tertiary)};
    svg {
        height: 16px;
        width: 16px;
    }
`

const VoteContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const VoteCount = styled(Typography)`
    font-weight: 900;
    margin: 0px 5px;
    min-width: 20px;
    text-align: center;
`

interface PromptMetaButtonsProps {
    className?: string
    prompt: PromptDetail | PromptSummary
}

const PromptMetaButtons = ({ className, prompt }: PromptMetaButtonsProps) => {
    const { toggleFavourite, vote } = usePromptContext()

    const { colors } = useTheme()

    return (
        <IconContainer className={className}>
            <InfoTooltip title='Favourite prompt'>
                <StyledMiniStarIconButton
                    onClick={event => {
                        event.stopPropagation()
                        toggleFavourite(prompt.id, prompt.isFavourite)
                    }}
                    isActive={prompt.isFavourite}
                >
                    <StarIcon color={colors.textOnSecondary} fill={prompt.isFavourite ? `${colors.textOnSecondary}` : 'none'} />
                </StyledMiniStarIconButton>
            </InfoTooltip>
            <VoteContainer>
                <InfoTooltip title='Downvote prompt'>
                    <StyledMiniChevronButton
                        onClick={event => {
                            event.stopPropagation()
                            vote(-1, prompt.id, prompt.myVote)
                        }}
                        isActive={prompt.myVote === -1}
                    >
                        <ChevronIcon direction='down' color={colors.textOnSecondary} />
                    </StyledMiniChevronButton>
                </InfoTooltip>
                <VoteCount variant='body2'>{prompt.totalVotes}</VoteCount>
                <InfoTooltip title='Upvote prompt'>
                    <StyledMiniChevronButton
                        onClick={event => {
                            event.stopPropagation()
                            vote(1, prompt.id, prompt.myVote)
                        }}
                        isActive={prompt.myVote === 1}
                    >
                        <ChevronIcon direction='up' color={colors.textOnSecondary} />
                    </StyledMiniChevronButton>
                </InfoTooltip>
            </VoteContainer>
        </IconContainer>
    )
}

export default PromptMetaButtons
