import { CircularProgress, Slider, styled, Typography, useTheme } from '@mui/material'
import { useEffect } from 'react'
import { useChatContext } from '../../../context/ChatContext'
import { WithSkimmerContext, useSkimmerContext } from '../../../context/SkimmerContext'
import { skimmerExportEndpoint } from '../../../endpoints'
import { useGet } from '../../../hooks/useGet'
import useIsMobile from '../../../hooks/useIsMobile'
import ExportIcon from '../../../icons/ExportIcon'
import { toUSD } from '../../../utils/formatting'
import ChatHeader from '../../ChatHeader'
import WithValidation from '../../WithValidation'
import Button from '../../common/Button'
import CenteredContainer from '../../common/CenteredContainer'
import Loading from '../../common/Loading'
import MissingChat from '../../common/MissingChat'
import TransparentButton from '../../common/TransparentButton'
import UnavailableInMobile from '../../common/UnavailableInMobile'
import { areaLayoutStyles } from '../../common/styles'
import ProgressUpdate from '../components/ProgressUpdate'
import SkimmerTable from '../components/SkimmerTable'
import Upload from '../components/Upload'

const StyledHeader = styled(ChatHeader)(({ theme }) => ({
    ...areaLayoutStyles(theme).header,
}))

const Content = styled('div')(({ theme }) => ({
    ...areaLayoutStyles(theme).content,
    height: '75%',
    marginTop: '24px',
    padding: '0 16px 0 40px',
    overflow: 'auto',
}))

const InnerContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
}))

const StyledFooter = styled('div')(({ theme }) => ({
    ...areaLayoutStyles(theme).footer,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

const FooterSideContainer = styled('div')(() => ({
    flex: 1,
}))

const CostCap = styled(FooterSideContainer)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}))

const WrappedSkimmerLayout = () => {
    const { palette } = useTheme()
    const { currentChat } = useChatContext()
    const isMobile = useIsMobile()
    const { loading, report, saveAndRefresh, updateCostCap, triggerRun } = useSkimmerContext()

    const [exportToExcelUrl, loadingExportUrl, refreshExportUrl] = useGet<string>(currentChat?.id ? skimmerExportEndpoint(currentChat.id) : null, {
        requestOnRender: false,
    })

    useEffect(() => {
        exportToExcelUrl && window.open(exportToExcelUrl, '_blank')
    }, [exportToExcelUrl])

    if (!currentChat) {
        // Users shouldn't be able to get here as the main area checks the current chat to determine whether or not to show these components
        return <MissingChat />
    }

    if (isMobile) {
        return <UnavailableInMobile featureName='Tables' />
    }

    if (!loading && !report) {
        return <Upload onChange={saveAndRefresh} updateMode={false} />
    }

    const handleExport = () => {
        // This wrapper is just so we can reuse the method from the context as is.
        // We want to save before triggering the export but not trigger a refresh.
        // It requires knowledge of how the context works which is a bit gross.
        const wrapper = async (): Promise<false> => {
            refreshExportUrl()
            return false
        }
        saveAndRefresh(wrapper)
    }

    const running = currentChat?.status === 'locked' || loading === 'running'
    const processing = running || !!loading
    const locked = processing || currentChat?.status !== 'active'
    // BE does not send state updates for processing, therefore we presume until updated otherwise
    const processingCount = report?.documents?.filter(doc => doc.state !== 'extracted').length ?? 0

    return (
        <>
            <StyledHeader>
                <TransparentButton type='button' aria-label='export to excel' disabled={processing} onClick={handleExport}>
                    {loadingExportUrl ? <CircularProgress size={24} /> : <ExportIcon color={processing ? palette.text.disabled : palette.text.primary} />}{' '}
                    Export to Excel
                </TransparentButton>
            </StyledHeader>
            <Content>
                {report ? (
                    <InnerContainer>
                        <SkimmerTable report={report} running={running} locked={locked} />
                    </InnerContainer>
                ) : loading ? (
                    <CenteredContainer>
                        <Loading fullSize secondaryColor />
                    </CenteredContainer>
                ) : (
                    <MissingChat /> // Users shouldn't be able to get here
                )}
            </Content>
            <WithValidation>
                <StyledFooter>
                    <CostCap>
                        {report && (
                            <>
                                <Typography>Each run is limited to {toUSD(report.costCap)}</Typography>
                                <Slider
                                    aria-label='cost cap'
                                    value={report.costCap}
                                    onChange={(_, value) => typeof value === 'number' && value > 0 && updateCostCap(value)}
                                    disabled={locked}
                                    getAriaValueText={toUSD}
                                    valueLabelFormat={toUSD}
                                    valueLabelDisplay='auto'
                                    step={1}
                                    marks={[
                                        { value: 0, label: '$0' },
                                        { value: 25, label: '$25' },
                                        { value: 50, label: '$50' },
                                    ]}
                                    min={0}
                                    max={50}
                                    sx={{ maxWidth: '80%' }}
                                    size='small'
                                />
                            </>
                        )}
                    </CostCap>
                    <Button type='button' aria-label='run skimmer' disabled={locked} onClick={triggerRun}>
                        Run Prompts
                    </Button>
                    <FooterSideContainer aria-hidden /> {/* Empty element for flex alignment */}
                    {(locked || processingCount > 0) && <ProgressUpdate running={running} saving={loading === 'saving'} processingCount={processingCount} />}
                </StyledFooter>
            </WithValidation>
        </>
    )
}

const SkimmerLayout = () => (
    <WithSkimmerContext>
        <WrappedSkimmerLayout />
    </WithSkimmerContext>
)

export default SkimmerLayout
