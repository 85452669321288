import { FileWithMetadata, UUID } from '../types'
import { isInFolder } from './documents'
import { CreateOrUpdateLeaseReportPayload } from '../apiTypes'
import { LeaseReport } from '../components/lease-report/types'

export const fileIsLeaseContract = (fileName: string) => {
    const regex = /lease|signedlease/gi
    const stringWithoutPunc = fileName.replace(/[.,/#!$%^&*;:{}=\-_`~()\\ ]/g, '')
    return regex.test(stringWithoutPunc)
}

export const tryFindLatestLeaseContract = (files: FileWithMetadata[] | null) => {
    if (!files || !files.length) {
        return null
    }

    const leaseContractMatches = files?.filter(f => fileIsLeaseContract(f.file.name))
    if (leaseContractMatches.length) {
        return leaseContractMatches.sort((a, b) => b.file.lastModified - a.file.lastModified)[0]
    } else {
        return files[0]
    }
}

export const getLeaseReportPayload = (
    folders: string[],
    leaseReports: LeaseReport[] | null | undefined,
    files: FileWithMetadata[],
    contractFiles: FileWithMetadata[],
    rejectedIds: UUID[]
) =>
    folders.reduce((payload: CreateOrUpdateLeaseReportPayload, folder) => {
        const isInCurrentFolder = isInFolder(folder)

        const leaseReport = leaseReports?.find(lr => lr.name === folder)
        const leaseFileIds = files.filter(isInCurrentFolder).map(f => f.id)

        if (!leaseFileIds.length) {
            // No files were uploaded for this report
            return payload
        }

        const contractFile = contractFiles.find(contract => leaseFileIds.includes(contract.id))

        // Either previously uploaded document or new file id
        const contractId = contractFile?.id ?? leaseReport?.leaseContract.id
        if (contractId) {
            const hasRejectedFiles = rejectedIds.some(rejectedId => leaseFileIds.includes(rejectedId))

            if (!hasRejectedFiles) {
                // Fallback route: Lease report name -> folder -> contract file name -> empty string
                const name = leaseReport?.name ?? (folder === '' && contractFile ? contractFile.file.name : folder)

                payload.push({
                    leaseReportId: leaseReport?.id,
                    leaseContractId: contractId,
                    config: {},
                    auxiliaryDocumentIds: leaseFileIds.filter(id => id !== contractId),
                    name,
                })
            }
        }

        return payload
    }, [])
