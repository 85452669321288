import { ModalProps } from '../common/Modal'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { usePost } from '../../hooks/usePost'
import { botListEndpoint, newBotOptionsEndpoint } from '../../endpoints'
import { useGet } from '../../hooks/useGet'
import { CreateBotPayload, CreateBotResponse, NewBotOption } from '../../apiTypes'
import { FixedSizeModal } from './BotConfigModal'
import { useBotContext } from '../../context/BotContext'
import Loading from '../common/Loading'
import NewBotDetails from './NewBotDetails'

const NewBotModal = ({ show, onClose }: ModalProps) => {
    const [addNewBot] = usePost<CreateBotPayload, CreateBotResponse>(botListEndpoint)
    const [botOptions] = useGet<NewBotOption[]>(newBotOptionsEndpoint)

    const { editBotConfig } = useBotContext()
    const [chosenBotId, setChosenBotId] = useState<string | null>(null)

    //chosenBotId might not be set on first load, so hand in botId here
    const handleCreate = async (botId: string, name: string, description: string) => {
        if (botId && name && description) {
            const newBot = await addNewBot({ id: botId, name, description })
            if (newBot) {
                toast.success(`Successfully created, now editing ${name}`)
                editBotConfig(newBot)
            }
        } else {
            toast.warn('Please enter a name & description for the new bot')
        }
    }

    return (
        <FixedSizeModal show={show} onClose={onClose} heading='Create new tool'>
            {botOptions?.length ? (
                <NewBotDetails
                    key={chosenBotId}
                    botId={chosenBotId ?? botOptions.find(bo => bo.id === 'document_over_limit')?.id ?? botOptions[0].id}
                    botOptions={botOptions}
                    onOptionChange={setChosenBotId}
                    onCreate={handleCreate}
                />
            ) : (
                <Loading />
            )}
        </FixedSizeModal>
    )
}

export default NewBotModal
