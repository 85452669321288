import { useState } from 'react'
import styled from 'styled-components'
import ContractSelectModal from './ContractSelectModal'
import TextButton from '../common/TextButton'
import { useLeaseReportContext } from '../../context/LeaseReportContext'
import FileName from '../common/FileName'
import ReportDisplay from '../report/ReportDisplay'
import { useFileContext } from '../../context/FileContext'
import { Typography } from '@mui/material'

const Container = styled('div')({
    overflowY: 'auto',
})

const SubHeading = styled(Typography)({
    margin: '32px 0 16px',
})

const ChangeButton = styled(TextButton)({
    marginLeft: '18px',
    fontWeight: 'bold',
})

const AdditionalInformation = () => {
    const { selectedReport, contractFile } = useLeaseReportContext()
    const [showContractSelect, setShowContractSelect] = useState(false)
    const { files } = useFileContext()

    const readonly = selectedReport?.state === 'completed'
    const isContractChangeEnabled =
        !readonly &&
        ((!selectedReport?.leaseContract && !!files) || // contract not uploaded yet, blank slate upload
            selectedReport?.leaseContract.state === 'error') // contract uploaded but errored & needs replacing

    return (
        <Container>
            <Typography variant='h2'>Additional information</Typography>
            {(selectedReport || contractFile) && (
                <>
                    <SubHeading variant='h3'>Lease contract</SubHeading>
                    <FileName name={contractFile?.file.name ?? selectedReport?.leaseContract.name ?? ''} />
                    {isContractChangeEnabled && (
                        <ChangeButton onClick={() => setShowContractSelect(true)} aria-label='change lease contract'>
                            Change
                        </ChangeButton>
                    )}
                </>
            )}
            <ContractSelectModal show={showContractSelect} onClose={() => setShowContractSelect(false)} />
            <br></br>
            <ReportDisplay />
        </Container>
    )
}

export default AdditionalInformation
