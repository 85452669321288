import { DocumentCard } from '../common/DocumentCards'
import ErrorCard from '../common/ErrorCard'
import { useReportContext } from '../../context/ReportContext'
import { styled, Typography } from '@mui/material'

const Heading = styled(Typography)({
    margin: '0 0 36px 0',
    textAlign: 'center',
})

const StyledDocumentCard = styled(DocumentCard)({
    minWidth: '350px',
})

const ReportDisplay = () => {
    const { selectedReport } = useReportContext()

    if (selectedReport?.state === 'completed') {
        return (
            <div>
                <Heading variant='h2'>Your files are ready</Heading>
                <StyledDocumentCard document={selectedReport.report} />
            </div>
        )
    }

    if (selectedReport?.state === 'error') {
        return (
            <div>
                <Heading variant='h2'>Something went wrong</Heading>
                <ErrorCard>
                    <Typography>Unfortunately we were unable to generate your report.</Typography>
                    <Typography>Please try again or report an issue.</Typography>
                </ErrorCard>
            </div>
        )
    }

    return null
}

export default ReportDisplay
