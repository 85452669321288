import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { isResolvedAuthError, msalConfig } from './authConfig'
import * as Sentry from '@sentry/react'
import { environmentVariables } from './env'
import { WithDarkModeContext } from './context/DarkModeContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const msalInstance = new PublicClientApplication(msalConfig)
const redirectUrl = new URL(environmentVariables.redirectUri)

// Remove errors thrown by the MSAL hook that we have already handled
const filterHandledUnhandledExceptions = (event: Sentry.ErrorEvent, hint: Sentry.EventHint) => (isResolvedAuthError(hint.originalException) ? null : event)

Sentry.init({
    dsn: environmentVariables.sentryDsn,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: environmentVariables.sentryTracesSampleRate,
    environment: environmentVariables.sentryEnvironment,
    release: environmentVariables.sentryRelease,
    beforeSend: filterHandledUnhandledExceptions,
    tracePropagationTargets: [new RegExp(environmentVariables.apiUri)],
})

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <WithDarkModeContext>{window.location.pathname === redirectUrl.pathname ? null : <App />}</WithDarkModeContext>
        </MsalProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
