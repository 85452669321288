import { SVGProps } from 'react'

const BookmarkIcon = ({ color, ...rest }: SVGProps<SVGSVGElement>) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={12} height={15} viewBox='0 0 20 15' fill='none' {...rest}>
        <path
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={1.2}
            d='M9.328 1.567H2.642c-.443 0-.869.153-1.182.424-.314.271-.49.64-.49 1.023v11.09l5.015-3.857L11 14.104V3.014c0-.384-.176-.752-.49-1.023a1.812 1.812 0 0 0-1.182-.424Z'
        />
    </svg>
)

export default BookmarkIcon
