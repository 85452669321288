import { styled, Typography } from '@mui/material'
import CenteredContainer from './CenteredContainer'

const UnavailableWrapper = styled('div')({
    padding: '0 10%',
    textAlign: 'center',
    fontWeight: 'bold',
    opacity: 0.7,
})

interface UnavailableInMobileProps {
    featureName: string
}

const UnavailableInMobile = ({ featureName }: UnavailableInMobileProps) => {
    return (
        <CenteredContainer>
            <UnavailableWrapper>
                <Typography>You cannot access {featureName} on mobile devices.</Typography>
                <Typography>Please use a desktop device to enable this feature.</Typography>
            </UnavailableWrapper>
        </CenteredContainer>
    )
}

export default UnavailableInMobile
