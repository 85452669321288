import DocumentsIcon from '../../icons/DocumentsIcon'
import Loading from '../common/Loading'
import { useReportContext } from '../../context/ReportContext'
import styled from 'styled-components'
import { Typography } from '@mui/material'

const Container = styled('div')({
    textAlign: 'center',
})

const Text = styled(Typography)({
    margin: '42px 0 5px',
})

const ReportLoading = () => {
    const { loading } = useReportContext()

    if (loading === 'sync') {
        return <Loading fullSize secondaryColor />
    }

    return (
        <Container>
            <DocumentsIcon />
            <Text>
                {loading === 'uploading'
                    ? 'Uploading your documents, please do not leave the page'
                    : 'The report is being generated. This should take about 5 minutes per report. Refresh the page to check the results.'}
            </Text>
            <Loading secondaryColor bar />
        </Container>
    )
}

export default ReportLoading
