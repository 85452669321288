import Sidebar from './Sidebar'
import useIsMobile from '../hooks/useIsMobile'
import styled, { useTheme } from 'styled-components'
import BurgerButton from './common/BurgerButton'
import { mediaQueries, sidebarTransitions, sidebarWidth, zIndexes } from './common/styles'
import { useState } from 'react'
import { useChatContext } from '../context/ChatContext'
import ChevronIcon from '../icons/ChevronIcon'
import IconButton from './common/IconButton'
import AddIcon from '../icons/AddIcon'

const StyledSidebar = styled(Sidebar)<{ isSidebarOpen: boolean }>`
    flex: 20;
    position: relative;
    min-width: ${props => (props.isSidebarOpen ? `${sidebarWidth}px` : '0')};
    max-width: ${props => (props.isSidebarOpen ? `${sidebarWidth}px` : '0')};
    ${sidebarTransitions}

    @media ${mediaQueries.mobile} {
        position: fixed;
        min-width: ${props => (props.isSidebarOpen ? '100%' : '0')};
        height: 100vh;
        margin: 0;
        top: 0;
        right: 0;
    }
`

const StyledSidebarToggleButton = styled.button<{ isSidebarOpen: boolean }>`
    position: fixed;
    top: 50%;
    margin-left: ${props => (props.isSidebarOpen ? `${sidebarWidth + 12}px` : '12px')};
    ${sidebarTransitions}
    background-color: transparent;
    width: 40px;
    height: 50px;
    padding: 0;
    border: none;
    border-radius: ${props => (props.isSidebarOpen ? `25% 0 0 25%` : '0 25% 25% 0')};
    cursor: pointer;
    z-index: ${zIndexes.navigation + 1};
`

const StyledIconButton = styled(IconButton)`
    position: fixed;
    top: 53%;
    right: calc(50% - 69px);
    z-index: ${zIndexes.button};
    background-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.textOnSecondary};
`

const SidebarWrapper = () => {
    const { currentChat, loading } = useChatContext()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const {
        colors: { textOnSecondary },
    } = useTheme()

    const [isSidebarOpen, setIsSidebarOpen] = useState(true)
    const [showBotList, setShowBotList] = useState(false)
    const [prevCurrentChat, setPrevCurrentChat] = useState(currentChat)
    const shouldShowBotList = !currentChat && !loading
    if (prevCurrentChat !== currentChat) {
        setPrevCurrentChat(currentChat)
        setShowBotList(shouldShowBotList)
        isMobile && setIsSidebarOpen(false)
    }

    const [prevLoading, setPrevLoading] = useState(loading)
    if (prevLoading !== loading) {
        setPrevLoading(loading)
        setShowBotList(shouldShowBotList)
    }

    const handleToggleSidebar = () => {
        setIsSidebarOpen(prevState => !prevState)
    }

    return (
        <>
            {isMobile ? (
                <>
                    <BurgerButton isOpen={isSidebarOpen} onClick={handleToggleSidebar} />
                    {!currentChat && (
                        <StyledIconButton label='Create chat' onClick={() => setIsSidebarOpen(true)}>
                            <AddIcon color={textOnSecondary} />
                        </StyledIconButton>
                    )}
                </>
            ) : (
                <StyledSidebarToggleButton onClick={handleToggleSidebar} isSidebarOpen={isSidebarOpen} aria-label='toggle sidebar'>
                    <ChevronIcon direction={isSidebarOpen ? 'left' : 'right'} color={theme.colors.tertiary} flat />
                </StyledSidebarToggleButton>
            )}
            {<StyledSidebar aria-label='sidebar' isSidebarOpen={isSidebarOpen} showBotList={showBotList} onBotListToggle={setShowBotList} />}
        </>
    )
}

export default SidebarWrapper
