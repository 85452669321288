import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { CreateChatPayload, CreateChatResponse } from '../apiTypes'
import { chatEndpoint } from '../endpoints'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { Bot, Chat, ChatStatus, doNothing, doNothingEventually, placeholderChatTitle, UUID } from '../types'
import { clearLocalChatData } from '../utils/localStorage'

interface ChatState {
    currentChat: Chat | null
    selectChat: (chatId: UUID) => void
    chats: Chat[]
    loading: boolean
    refreshChats: () => void
    updateChat: (chatId: UUID, update: Partial<Chat>) => void
    blockChatSwapping: () => void
    unblockChatSwapping: () => void
    lockCurrentChat: () => void
    unlockCurrentChat: () => void
    createChat: (bot?: Bot) => Promise<void>
    removeChat: (chatId: UUID) => void
}

export const defaultState: ChatState = {
    currentChat: null,
    selectChat: doNothing,
    chats: [],
    loading: true,
    refreshChats: doNothing,
    updateChat: doNothing,
    blockChatSwapping: doNothing,
    unblockChatSwapping: doNothing,
    lockCurrentChat: doNothing,
    unlockCurrentChat: doNothing,
    createChat: doNothingEventually,
    removeChat: doNothing,
}

export const ChatContext = createContext<ChatState>(defaultState)

const WithChatContext = ({ children }: PropsWithChildren) => {
    const [currentChat, setCurrentChat] = useState<Chat | null>(null)
    const [chats, setChats] = useState<Chat[]>([])
    const [refreshChatId, setRefreshChatId] = useState<UUID | null>(null)
    const [chatSwappingBlocked, setChatSwappingBlocked] = useState<boolean>(false)
    const [serverChats, loading, refreshChats] = useGet<Chat[]>(chatEndpoint)
    const [createChat, createChatLoading] = usePost<CreateChatPayload, CreateChatResponse>(chatEndpoint)

    useEffect(() => {
        serverChats && setChats(serverChats)
    }, [serverChats])

    useEffect(() => {
        if (refreshChatId) {
            const refreshTarget = chats.find(c => c.id === refreshChatId)
            if (refreshTarget) {
                setCurrentChat(refreshTarget)
                setRefreshChatId(null)
            }
        } else {
            // Keep current chat synced with chat list changes
            setCurrentChat(currentChat => chats.find(c => c.id === currentChat?.id) ?? chats[0])
        }
    }, [chats, refreshChatId])

    const handleSetChat = (chatId: UUID) => {
        if (chatSwappingBlocked) {
            toast.warn('Please wait for your documents to finish uploading before switching chats')
        } else {
            chatId !== currentChat?.id && setCurrentChat(chats.find(c => c.id === chatId) ?? null)
        }
    }

    const updateChat = useCallback((chatId: UUID, update: Partial<Chat>) => setChats(chats.map(c => (c.id === chatId ? { ...c, ...update } : c))), [chats])

    const updateCurrentChatStatus = (status: ChatStatus, progressUpdate: string | null) =>
        currentChat && setCurrentChat({ ...currentChat, status: status, progressUpdate: progressUpdate })
    const lockCurrentChat = () => updateCurrentChatStatus('locked', currentChat?.progressUpdate || null)
    /**
     * This should only be used if the frontend is locking the chat without the backend knowing.
     * E.g. Uploading a document.
     * Most of the time unlocking should only be requested by the backend
     */
    const unlockCurrentChat = () => updateCurrentChatStatus('active', null)

    const handleCreateChat = async (bot = currentChat?.bot) => {
        if (bot) {
            const result = await createChat({ botId: bot.id })

            if (result) {
                const placeholderChat: Chat = {
                    id: result,
                    title: placeholderChatTitle(bot.toolType),
                    bot,
                    latestTimestamp: new Date().toISOString(),
                    documentCount: 0,
                    status: 'locked',
                    progressUpdate: null,
                }

                setChats([placeholderChat, ...chats])
                setCurrentChat(placeholderChat)
            }
        }
    }

    const removeChat = (chatId: UUID) => {
        setChats(chats => chats.filter(c => c.id !== chatId))
        clearLocalChatData(chatId)
    }

    return (
        <ChatContext.Provider
            value={{
                currentChat: currentChat,
                selectChat: handleSetChat,
                chats: chats?.sort((a, b) => Date.parse(b.latestTimestamp) - Date.parse(a.latestTimestamp)) ?? [],
                loading: loading || createChatLoading,
                refreshChats,
                updateChat,
                blockChatSwapping: () => setChatSwappingBlocked(true),
                unblockChatSwapping: () => setChatSwappingBlocked(false),
                lockCurrentChat,
                unlockCurrentChat,
                createChat: handleCreateChat,
                removeChat,
            }}
        >
            {children}
        </ChatContext.Provider>
    )
}

const useChatContext = () => useContext(ChatContext)

export { useChatContext, WithChatContext }
