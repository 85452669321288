import { PromptLibraryPayload } from './types'
import { usePost } from '../../hooks/usePost'
import { usePromptContext } from '../../context/PromptContext'
import { useChatContext } from '../../context/ChatContext'
import { librariesEndpoint } from '../../endpoints'
import { UUID } from '../../types'
import LibraryForm from './LibraryForm'

const CreatePromptLibrary = () => {
    const { currentChat } = useChatContext()
    const { onLibraryChange, refreshLibraries } = usePromptContext()

    const [createLibrary, loading] = usePost<PromptLibraryPayload, UUID>(currentChat ? librariesEndpoint : null)

    const handleSubmit = async (payload: PromptLibraryPayload) => {
        const newLibraryId = await createLibrary(payload)

        if (newLibraryId) {
            refreshLibraries()
            onLibraryChange(newLibraryId)
        }
    }

    return <LibraryForm loading={loading} onBack={() => onLibraryChange(null)} onSubmit={handleSubmit} />
}

export default CreatePromptLibrary
