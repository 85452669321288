import styled from 'styled-components'
import { mainAreaLayoutStyles } from '../common/styles'
import ReportControls from './ReportControls'
import { WithLeaseEnhancedReportContext } from '../../context/LeaseReportContext'
import { useChatContext } from '../../context/ChatContext'
import useIsMobile from '../../hooks/useIsMobile'
import { WithReportContext } from '../../context/ReportContext'
import ReportTabs from './ReportTabs'
import MissingChat from '../common/MissingChat'
import UnavailableInMobile from '../common/UnavailableInMobile'
import ChatHeader from '../ChatHeader'
import WithValidation from '../WithValidation'

const StyledHeader = styled(ChatHeader)`
    ${mainAreaLayoutStyles.header}
`

const Content = styled(ReportTabs)`
    height: 80%;
    margin-top: 26px;
    ${mainAreaLayoutStyles.content}
`

const Footer = styled(ReportControls)`
    ${mainAreaLayoutStyles.footer}
`

const ReportLayout = () => {
    const { currentChat } = useChatContext()
    const isMobile = useIsMobile()

    if (!currentChat) {
        // Users shouldn't be able to get here as the main area checks the current chat to determine whether or not to show these components
        return <MissingChat />
    }

    if (isMobile) {
        return <UnavailableInMobile featureName='Report Generators' />
    }

    const isLeaseReport = currentChat.bot.toolType === 'lease_report_generator'
    const Context = isLeaseReport ? WithLeaseEnhancedReportContext : WithReportContext

    return (
        <Context>
            <StyledHeader />
            <Content />
            <WithValidation>
                <Footer />
            </WithValidation>
        </Context>
    )
}

export default ReportLayout
