import { JSONFormPayload } from '../../apiTypes'
import { usePromptContext } from '../../context/PromptContext'
import { libraryPromptsEndpoint } from '../../endpoints'
import { usePost } from '../../hooks/usePost'
import PromptForm from './PromptForm'
import { PromptLibrary } from './types'

interface CreatePromptProps {
    library: PromptLibrary
}

const CreatePrompt = ({ library }: CreatePromptProps) => {
    const { refreshLibraries, refreshPromptList, onSelectPrompt } = usePromptContext()

    const [createNewPrompt] = usePost<JSONFormPayload, boolean>(libraryPromptsEndpoint(library.id))

    const handleSubmit = async (updatedPrompt: JSONFormPayload) => {
        const newPrompt = {
            prompt: updatedPrompt,
            variables: [],
        }

        const success = await createNewPrompt(newPrompt)

        if (success) {
            refreshLibraries()
            refreshPromptList()
            onSelectPrompt(null)
        }
    }

    return <PromptForm onSubmit={handleSubmit} initialFormValue={null} library={library} onBack={() => onSelectPrompt(null)} />
}

export default CreatePrompt
