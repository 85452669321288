import { useState } from 'react'
import styled from 'styled-components'
import { UUID } from '../../types'
import Input from '../common/Input'
import TextArea from '../common/TextArea'
import TransparentButton from '../common/TransparentButton'
import WithLabels from '../common/WithLabels'
import { highlightOnHover, scrollBarStyle } from '../common/styles'
import BotConfigButton from './BotConfigButton'
import Divider from './Divider'
import Instructions from './Instructions'
import ErrorCard from '../common/ErrorCard'
import { configErrors } from '../../utils/userMessages'

const Container = styled.section`
    overflow-y: auto;
    ${scrollBarStyle}
    padding-right: 15px;
`

const DeleteControls = styled.div`
    display: flex;
`

const DeleteButton = styled(BotConfigButton)`
    background-color: ${({ theme }) => theme.colors.errorBackground};
    color: ${({ theme }) => theme.colors.error};
    align-self: end;
    ${({ theme }) => highlightOnHover(theme.colors.errorBackground)}
`

const CancelButton = styled(TransparentButton)`
    margin-left: 10px;
`

const StyledTextArea = styled(TextArea)`
    resize: none;
    width: fill-available;
`

interface DetailsProps {
    id: UUID
    name: string
    description: string
    loading: boolean
    readonly: boolean
    onClone: (cloneName: string) => Promise<void>
    onDelete: () => Promise<void>
    onUpdate: (name: string, description: string) => Promise<void>
}

const DetailsPage = ({ name, description, loading, readonly, onClone, onDelete, onUpdate }: DetailsProps) => {
    const [cloneName, setCloneName] = useState('')
    const [newName, setNewName] = useState(name)
    const [newDescription, setNewDescription] = useState(description)
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

    return (
        <Container>
            {readonly && <ErrorCard error={configErrors} />}
            <WithLabels label='Name' description="This is the name that will be displayed to the end users. It doesn't affect how the tool works.">
                <Input aria-label='update tool name' value={newName} onChange={({ target: { value } }) => setNewName(value)} disabled={readonly || loading} />
            </WithLabels>
            <WithLabels
                label='Description'
                description="This is a short description of the tool that is visible to the end users in the side panel. It doesn't affect how the tool works."
            >
                <StyledTextArea
                    rows={3}
                    aria-label='update tool description'
                    value={newDescription}
                    onChange={({ target: { value } }) => setNewDescription(value)}
                    disabled={readonly || loading}
                />
            </WithLabels>
            <BotConfigButton type='button' aria-label='update tool info' onClick={() => onUpdate(newName, newDescription)} disabled={readonly || loading}>
                Update
            </BotConfigButton>
            <Divider />
            <WithLabels
                label='Clone this tool'
                description='Create an editable copy of this tool that is only visible to you. This is useful for experimentation.'
            >
                <Input
                    aria-label='clone name'
                    placeholder='Name of the new tool'
                    value={cloneName}
                    onChange={({ target: { value } }) => setCloneName(value)}
                    disabled={loading}
                />
            </WithLabels>
            <BotConfigButton type='submit' aria-label='clone tool' onClick={() => onClone(cloneName)} disabled={loading}>
                Clone
            </BotConfigButton>
            {!readonly && (
                <>
                    <Divider />
                    <Instructions>WARNING: deleting this tool will also remove all conversations you have with it.</Instructions>
                    {showDeleteConfirm ? (
                        <DeleteControls>
                            <DeleteButton type='submit' aria-label='delete confirm' onClick={onDelete} disabled={loading}>
                                Confirm Delete
                            </DeleteButton>
                            <CancelButton type='button' aria-label='delete cancel' onClick={() => setShowDeleteConfirm(false)}>
                                Cancel
                            </CancelButton>
                        </DeleteControls>
                    ) : (
                        <DeleteButton type='button' aria-label='delete tool' onClick={() => setShowDeleteConfirm(true)} disabled={loading}>
                            Delete tool
                        </DeleteButton>
                    )}
                </>
            )}
        </Container>
    )
}
const Details = (props: DetailsProps) => <DetailsPage key={props.id} {...props} />

export default Details
