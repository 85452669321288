import { Form } from '@rjsf/mui'
import styled from 'styled-components'
import Loading from '../common/Loading'
import { scrollBarStyle } from '../common/styles'
import { default as FormType } from '@rjsf/core'
import { forwardRef, useRef } from 'react'
import TransparentButton from '../common/TransparentButton'
import BotConfigButton from '../bot-config/BotConfigButton'

const StyledForm = styled(Form)`
    flex: 1;
    overflow-y: auto;
    ${scrollBarStyle}
    margin-bottom: 15px;
    padding-right: 15px;
`

const StyledTransparentButton = styled(TransparentButton)`
    margin-left: 10px;
    text-align: center;
`

const Controls = styled.div`
    display: flex;
`

interface FormWrapperProps {
    className?: string
    formData?: { schema: Record<string, unknown>; uiSchema?: Record<string, unknown>; values: Record<string, unknown> } | null
    disabled: boolean
    onSubmit: (update: Record<string, unknown>) => void
    onCancel?: () => void
    onChange?: (update: Record<string, unknown>) => void
}

const FormWrapper = forwardRef<FormType, FormWrapperProps>(({ className, formData, disabled, onSubmit, onCancel, onChange }, passedRef) => {
    const defaultRef = useRef<FormType>(null)

    if (!formData) {
        return <Loading secondaryColor fullSize />
    }

    return (
        <>
            <StyledForm
                className={className}
                ref={passedRef ?? defaultRef}
                schema={formData.schema}
                uiSchema={{
                    ...formData.uiSchema,
                    'ui:submitButtonOptions': {
                        norender: true,
                    },
                }}
                formData={formData.values}
                onChange={({ formData }) => onChange && onChange(formData)}
                // Disable validator to avoid CSP issue as they generate JS at runtime.
                // If we need more complex validation than HTML5 we will need to setup precompiled validators
                validator={{
                    validateFormData: () => ({ errors: [], errorSchema: {} }),
                    toErrorList: () => [],
                    isValid: () => true,
                    rawValidation: () => ({}),
                }}
                onSubmit={({ formData }) => onSubmit(formData)}
                disabled={disabled}
                showErrorList={false}
            />
            <Controls>
                {onCancel && (
                    <StyledTransparentButton onClick={onCancel} aria-label='cancel form' type='reset'>
                        Go Back
                    </StyledTransparentButton>
                )}
                {!passedRef && (
                    <BotConfigButton
                        onClick={() => defaultRef.current?.submit()}
                        disabled={disabled}
                        type='submit'
                        title={disabled ? 'Form Disabled' : 'Submit'}
                        aria-label='submit form'
                    >
                        Save
                    </BotConfigButton>
                )}
            </Controls>
        </>
    )
})

export default FormWrapper
