import styled from 'styled-components'
import { scrollBarStyle } from './styles'

const CardList = styled.section`
    overflow-y: auto;
    padding: 8px;
    background-color: ${props => props.theme.colors.background};
    ${scrollBarStyle}

    & > * {
        & ~ * {
            margin-top: 8px;
        }
    }
`

export default CardList
