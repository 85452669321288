import styled from 'styled-components'
import Button from '../common/Button'
import Modal, { ModalProps } from '../common/Modal'
import VerticalRadio from '../common/VerticalRadio'
import { scrollBarStyle } from '../common/styles'
import { useLeaseReportContext } from '../../context/LeaseReportContext'
import { Typography } from '@mui/material'

const Container = styled.div`
    min-width: 350px;
    max-width: 50vw;
    display: flex;
    flex-direction: column;
`

const StyledVerticalRadio = styled(VerticalRadio)`
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 12px;
    ${scrollBarStyle}
` as typeof VerticalRadio

const CloseButton = styled(Button)`
    max-width: 200px;
    width: 100%;
    text-align: center;
    margin-top: 15px;
    align-self: center;
`

const ContractSelectModal = ({ show, onClose }: ModalProps) => {
    const { onContractFileChange, contractFile, folderFiles } = useLeaseReportContext()

    return (
        <Modal show={show} onClose={onClose} heading='Lease contract'>
            <Container>
                {contractFile && folderFiles ? (
                    <StyledVerticalRadio
                        options={folderFiles.map(f => ({ label: f.file.name, value: f.id }))}
                        value={contractFile?.id}
                        onChange={onContractFileChange}
                    />
                ) : (
                    <Typography>No files found, please attach some.</Typography>
                )}
                <CloseButton onClick={onClose}>Close</CloseButton>
            </Container>
        </Modal>
    )
}

export default ContractSelectModal
