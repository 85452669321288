import styled from 'styled-components'
import LogoIcon from '../icons/LogoIcon'

const Container = styled.section`
    min-height: 170px;
    max-height: 170px;
    margin: 0 12px 12px 12px;
`

const StyledLogoIcon = styled(LogoIcon)`
    height: 100%;
`

interface SidebarHeaderProps {
    className?: string
}

const SidebarHeader = ({ className }: SidebarHeaderProps) => {
    return (
        <Container className={className}>
            <StyledLogoIcon />
        </Container>
    )
}

export default SidebarHeader
