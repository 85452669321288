import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'

const FloatingCard = styled(({ className, ...props }: TooltipProps) => <Tooltip arrow={false} {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.divider}`,
        color: theme.palette.text.primary,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0px 4px 12px rgba(0, 0, 0, 0.3), 0px 2px 4px rgba(0, 0, 0, 0.15)'
                : '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.06)',
        maxWidth: 400,
    },
}))

export default FloatingCard
