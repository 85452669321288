import { useEffect, useState } from 'react'
import { breakpoints } from '../components/common/styles'

const getIsMobile = () => window.innerWidth <= breakpoints.mobile

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(getIsMobile)

    useEffect(() => {
        const handleResize = () => setIsMobile(getIsMobile)

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return isMobile
}

export default useIsMobile
