import { useTheme } from '@mui/material'
import { SVGProps } from 'react'

const InfoIcon = (props: SVGProps<SVGSVGElement>) => {
    const { palette } = useTheme()

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={18}
            height={18}
            viewBox='0 0 32 32'
            stroke={palette.text.secondary}
            fill={palette.text.secondary}
            {...props}
        >
            <path d='M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13 13-5.832 13-13S23.168 3 16 3Zm0 2c6.086 0 11 4.914 11 11s-4.914 11-11 11S5 22.086 5 16 9.914 5 16 5Zm-1 5v2h2v-2Zm0 4v8h2v-8Z' />
        </svg>
    )
}

export default InfoIcon
