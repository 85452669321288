import styled, { css } from 'styled-components'
import { Message, ChatProgressMessage } from '../types'
import MessageLoading from './common/MessageLoading'
import UserMessageContent from './UserMessageContent'
import BotMessageContent from './BotMessageContent'
import { mainElementMaxWidth, mediaQueries, scrollBarStyle } from './common/styles'
import MessageControls from './MessageControls'

const userMessageStyles = css`
    justify-content: flex-end;
`

const DisplayContainer = styled.div`
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    ${mainElementMaxWidth}
`

const MessageContainer = styled.div<{ userMessage: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: ${props => (props.userMessage ? '0 25px 0 48px' : '0 48px 0 25px')};
    width: fill-available;
    ${props => props.userMessage && userMessageStyles}

    :first-of-type {
        margin-top: 24px;
    }
`

export const MessageBlob = styled.div<{ userMessage: boolean }>`
    padding: 16px 24px;
    width: fit-content;
    max-width: 100%;
    overflow-x: auto;
    text-wrap: wrap;
    font-family: Nunito Sans, -apple-system, system-ui, Segoe UI, Helvetica Neue, sans-serif;
    font-size: 16px;
    border-radius: ${props => (props.userMessage ? '18px 18px 6px 18px' : '6px 18px 18px 18px')};
    background-color: ${props => (props.userMessage ? props.theme.colors.secondary : props.theme.colors.tertiary)};
    color: ${props => (props.userMessage ? props.theme.colors.textOnSecondary : props.theme.colors.text)};
    align-self: ${props => (props.userMessage ? 'flex-end' : 'flex-start')};
    ${scrollBarStyle}

    @media ${mediaQueries.laptop} {
        font-size: 15px;
    }
    @media ${mediaQueries.half} {
        font-size: 14px;
    }
`

const LoadingBlob = styled(MessageBlob)<{ progress?: ChatProgressMessage }>`
    margin-bottom: 33px;

    ${props =>
        props.progress &&
        css`
            display: grid;
            grid-template-columns: auto auto;
            align-items: center;
        `}
`
const ProgressMessage = styled.p`
    padding: 0;
    margin: 0 25px 0 0;
    font-style: italic;
`
interface MessageDisplayProps {
    messages: Message[]
    loading: boolean
    progress?: ChatProgressMessage
}

const MessageDisplay = ({ messages, loading, progress }: MessageDisplayProps) => (
    <DisplayContainer>
        {messages.map((message, index) => (
            <MessageContainer key={message.id} userMessage={message.fromUser}>
                <MessageBlob userMessage={message.fromUser}>
                    {message.fromUser ? <UserMessageContent message={message} /> : <BotMessageContent message={message} />}
                </MessageBlob>
                <MessageControls message={message} isLatestMessage={index === messages.length - 1} />
            </MessageContainer>
        ))}
        {loading && (
            <MessageContainer userMessage={false}>
                <LoadingBlob userMessage={false} progress={progress}>
                    {progress && <ProgressMessage aria-label='progress message'>{progress}</ProgressMessage>}
                    <MessageLoading size='small' role='progressbar' aria-label='response loading' />
                </LoadingBlob>
            </MessageContainer>
        )}
    </DisplayContainer>
)

export default MessageDisplay
