import styled from 'styled-components'
import { MessageBlob } from '../MessageDisplay'
import Button from '../common/Button'
import Loading from '../common/Loading'
import Breadcrumb from '../common/Breadcrumb'
import PromptMetaButtons from './PromptMetaButtons'
import { usePromptContext } from '../../context/PromptContext'
import MarkdownMessage from '../common/MarkdownMessage'
import { scrollBarStyle } from '../common/styles'
import { createDisplayedPrompt } from './promptUtils'
import { Typography } from '@mui/material'

export const PromptDetailWrapper = styled.div`
    height: 85%;
    overflow-y: auto;
    ${scrollBarStyle}
    padding: 0 20px;
    margin: 0 -15px;
`

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
`

export const MessageContainer = styled.div`
    width: 80%;
`

export const UsePromptButton = styled(Button)`
    margin-top: 50px;
`

export const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const SubmissionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 100px;
`

export const StyledMessageBlob = styled(MessageBlob)`
    margin-top: 20px;
    max-height: 45%;
    width: inherit;
    overflow-y: auto;
    border: 24px solid ${props => props.theme.colors.secondary};
    padding: 0px 12px 0px 0px;
    margin: 24px auto 0 auto;
`

interface SimplePromptDetailProps {
    onUsePrompt: (promptMessage: string) => void
}

const SimplePromptDetail = ({ onUsePrompt }: SimplePromptDetailProps) => {
    const { prompt, onSelectPrompt } = usePromptContext()

    if (!prompt) {
        return <Loading fullSize={true} secondaryColor={true} />
    }

    const displayedPrompt = createDisplayedPrompt(prompt.messageList)

    return (
        <>
            <Breadcrumb onClick={() => onSelectPrompt(null)} label='Back to Library' />
            <PromptDetailWrapper>
                <DetailsContainer>
                    <MessageContainer>
                        <Typography variant='h2'>{prompt.name}</Typography>
                        <HeaderRow>
                            <Typography>{prompt.description}</Typography>
                            <PromptMetaButtons prompt={prompt} />
                        </HeaderRow>
                        <StyledMessageBlob userMessage={true}>
                            <MarkdownMessage>{displayedPrompt}</MarkdownMessage>
                        </StyledMessageBlob>
                        <SubmissionContainer>
                            <UsePromptButton onClick={() => onUsePrompt(displayedPrompt)} title='Use Prompt' type='submit' aria-label='use prompt button'>
                                Use Prompt
                            </UsePromptButton>
                        </SubmissionContainer>
                    </MessageContainer>
                </DetailsContainer>
            </PromptDetailWrapper>
        </>
    )
}

export default SimplePromptDetail
