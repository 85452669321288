import { styled, Typography } from '@mui/material'

const Container = styled('div')({
    overflowY: 'scroll',
})

const DescriptionSection = styled('div')(({ theme }) => ({
    margin: '12px 0',
    ...theme.typography.subtitle1,
}))

const MarginSubtitle = styled(Typography)({
    margin: '12px 0',
})

const MarginBody = styled(Typography)({
    margin: '12px 0',
})

const MarginDescription = styled(Typography)({
    margin: '12px 0',
})

const ReportConfigGuidance = () => (
    <Container>
        <Typography variant='h2'>Report Generation Process</Typography>
        <Typography variant='h3'>What are reports?</Typography>
        <Typography>
            <strong>Reports</strong> are Word documents that compile key information from the user's input document into a more organized form. To generate
            reports, you can define:
        </Typography>
        <ul>
            <li>
                <Typography>
                    the <strong>fields</strong>, i.e. small pieces of information that should be extracted from the contract
                </Typography>
            </li>
            <li>
                <Typography>
                    the <strong>template</strong>, i.e. template of the resulting report word document
                </Typography>
            </li>
        </ul>
        <Typography variant='h3'>Field Extraction</Typography>
        <Typography>
            In the <strong>Fields Tab</strong> you can define each relevant field that should be extracted from the input document. Fields are key words that
            are used in the LLM pipeline to find relevant information in the document.
        </Typography>
        <MarginDescription variant='subtitle1'>
            <strong>Example:</strong> Field "Rent" might be used to extract "$10,000 per annum"
        </MarginDescription>
        <Typography>
            For each defined field, the pipeline will skim through the entire document and extract relevant pieces of text. The field value is a generated
            condensed summary, taking all extracted text snippets into account.
        </Typography>
        <Typography variant='h3'>Report Templates</Typography>
        <Typography>
            After fields have been extracted, the field values are put into a downloadable word document - the final report. This is done via a template. The
            simplest way to create a template is to download the default template from the <strong>Template Tab</strong>, adjust the content and upload it
            again.
        </Typography>
        <MarginBody>
            A template contains fixed text and placeholders for specific information written in Jinja2 templating syntax. The report generator will
            automatically replace the placeholder text with the corresponding values.
        </MarginBody>
        <MarginSubtitle variant='h4'>Jinja2 Syntax</MarginSubtitle>
        <Typography>For a single field called foo, the placeholder is &#123;&#123; fields['foo'] &#125;&#125;</Typography>
        <MarginDescription variant='subtitle1'>
            <strong>Example:</strong> "The rent is &#123;&#123; fields['rent'] &#125;&#125;" will in the final report read "The rent is $10,000 per annum"
        </MarginDescription>
        <Typography>You can also iterate over all fields by using &#123;% for %&#125;.</Typography>
        <DescriptionSection>
            <strong>Example:</strong>
            <pre>
                {' '}
                &#123;% for field in fields %&#125; <br /> Name: &#123;&#123; field.key &#125;&#125; <br /> &#123;% endfor %&#125; <br />
                <br /> will in the final report read:
            </pre>
            <ul style={{ listStyleType: 'none' }}>
                <li>Name: Rent</li>
                <li>Name: Term of Lease</li>
                <li>etc.</li>
            </ul>
        </DescriptionSection>
        <Typography>Conditions can also be used to check if a certain field has a value to display, using &#123;% if %&#125;.</Typography>
        <DescriptionSection>
            <strong>Example:</strong>
            <pre>
                {' '}
                &#123;% if fields['rent'] %&#125; <br /> The rent is: &#123;&#123; fields['rent'] &#125;&#125; <br /> &#123;% else %&#125; <br /> Rent is not
                specified. <br /> &#123;% endif %&#125;
            </pre>
        </DescriptionSection>
        <MarginSubtitle variant='h4'>Available Placeholders</MarginSubtitle>
        <Typography>In addition to the fields, the report generator has access to these individual variables: </Typography>
        <ul>
            <li>
                <Typography>
                    <strong>&#123;&#123; source_filename &#125;&#125;</strong>: name of the contract the user has uploaded
                </Typography>
            </li>
        </ul>
        <Typography>
            It has also access to the complete list of fields with all field properties. These can be accessed when you iterate over fields using &#123;% for
            field in fields %&#125;:
        </Typography>
        <ul>
            <li>
                <Typography>
                    <strong>&#123;&#123; field.key &#125;&#125;</strong>: name of the field, e.g. "Rent"
                </Typography>
            </li>
            <li>
                <Typography>
                    <strong>&#123;&#123; field.value &#125;&#125;</strong>: the extracted value of the field, e.g. "$10,000 per annum"
                </Typography>
            </li>
            <li>
                <Typography>
                    <strong>&#123;&#123; field.pages &#125;&#125;</strong>: the page ranges that were used to source the information, e.g. "1-3,5"
                </Typography>
            </li>
        </ul>
    </Container>
)

export default ReportConfigGuidance
