import { styled, useTheme } from '@mui/material'
import ChevronIcon from '../../../icons/ChevronIcon'
import Checkbox from '../../common/Checkbox'
import UnstyledButton from '../../common/UnstyledButton'
import HeadingText from './HeadingText'
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded'
import { useChatContext } from '../../../context/ChatContext'
import { rotate180degAnimation } from '../../common/Theme'
import { AUTO_RESIZE_TRANSITION_TIME } from '../utils/utils'
import ExpandedInfoTooltip from '../../common/tooltips/ExpandedInfoTooltip'
import { forwardPropsWithTransient } from '../../../utils/muiUtils'

interface ContainerProps {
    $collapsed: boolean
}

const Container = styled('div', { shouldForwardProp: forwardPropsWithTransient })<ContainerProps>(({ $collapsed }) => ({
    marginLeft: '8px',
    display: 'grid',
    gridTemplateColumns: $collapsed ? 'repeat(3, 30px)' : 'repeat(2, 30px) 24px auto',
    alignItems: 'center',
    width: $collapsed ? '90px' : '470px',
    transition: `width ${AUTO_RESIZE_TRANSITION_TIME}ms ease-out`,
    justifyItems: $collapsed ? 'center' : 'unset',
}))

const CollapseButtonTooltipWrapper = styled(ExpandedInfoTooltip)(() => ({
    gridColumn: '1 / 2',
    width: '30px',
}))

const ColumnCollapseButton = styled(UnstyledButton)(({ theme }) => ({
    height: '20px',

    '& .icon': {
        fontSize: 20,
        color: theme.palette.text.secondary,

        '&.collapsed-true': {
            animation: `${rotate180degAnimation} 0.2s forwards`,
        },
        '&.collapsed-false': {
            animation: `${rotate180degAnimation} 0.2s reverse forwards`,
        },
    },
}))

const CollapseButton = styled(UnstyledButton)(({ theme }) => ({
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .icon': { color: theme.palette.text.secondary },
}))

const StyledCheckboxTooltipWrapper = styled(ExpandedInfoTooltip)(() => ({
    gridColumn: '2 / 3',
    width: '30px',
}))

const Text = styled(HeadingText)(() => ({
    gridColumn: '4 / 4',
}))

interface PromptHeadingProps {
    allSelected: boolean
    onToggleAll: () => void
    collapsed: boolean
    onCollapse: () => void
}

const PromptHeading = ({ allSelected, onToggleAll, collapsed, onCollapse }: PromptHeadingProps) => {
    const { currentChat } = useChatContext()
    const { palette } = useTheme()

    if (!currentChat) return

    return (
        <Container $collapsed={collapsed}>
            <CollapseButtonTooltipWrapper heading='Coming Soon:' description='Expand / Collapse all Prompt'>
                <CollapseButton type='button' aria-label='expand / collapse all prompts' disabled>
                    <ChevronIcon width={20} height={18} direction='down' color={palette.divider} />
                </CollapseButton>
            </CollapseButtonTooltipWrapper>
            <StyledCheckboxTooltipWrapper heading='Coming Soon:' description='Activate / Deactivate all Prompts'>
                <Checkbox checked={allSelected} onClick={onToggleAll} disabled />
            </StyledCheckboxTooltipWrapper>
            <ColumnCollapseButton type='button' aria-label='expand / collapse this column' onClick={onCollapse} title={collapsed ? '' : 'Collapse'}>
                <KeyboardDoubleArrowRightRoundedIcon
                    className={`icon collapsed-${collapsed}`}
                    data-testid={collapsed ? 'collapsed-icon' : 'expanded-icon'}
                    key={`${collapsed}`}
                />
            </ColumnCollapseButton>
            {!collapsed && <Text>Prompt</Text>}
        </Container>
    )
}

export default PromptHeading
