import styled from 'styled-components'
import { useFileContext } from '../../context/FileContext'
import UploadArea from '../common/UploadArea'
import { useReportContext } from '../../context/ReportContext'
import CenteredContainer from '../common/CenteredContainer'
import ReportLoading from '../report/ReportLoading'
import UploadInstructions from '../report/UploadInstructions'
import LeaseReportContent from '../lease-report/LeaseReportContent'
import CardList from '../common/CardList'
import { DocumentCard, FileCard } from '../common/DocumentCards'
import ReportDisplay from './ReportDisplay'
import { useChatContext } from '../../context/ChatContext'

const Container = styled.section`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    column-gap: 15px;
    padding: 0 15px 0 49px;
    color: ${props => props.theme.colors.text};
    overflow: hidden;
`

interface ReportContentProps {
    className?: string
}

const ReportContent = ({ className }: ReportContentProps) => {
    const { currentChat } = useChatContext()
    const { files } = useFileContext()
    const { failedUploadFileIds, folderFiles, loading, selectedReport, selectedFolder } = useReportContext()

    if (loading) {
        return (
            <CenteredContainer className={className}>
                <ReportLoading />
            </CenteredContainer>
        )
    }

    if (!files && !selectedReport) {
        return (
            <UploadArea className={className} clickable folderOverride={selectedFolder} alwaysVisible>
                <UploadInstructions />
            </UploadArea>
        )
    }

    const isLeaseReport = currentChat?.bot.toolType === 'lease_report_generator'

    return (
        <Container className={className}>
            {isLeaseReport ? (
                <LeaseReportContent />
            ) : (
                <>
                    <CardList>
                        {selectedReport?.sourceDocuments.map(doc => (
                            <DocumentCard key={doc.id} document={doc} />
                        ))}
                        {folderFiles?.map(({ id, file }) => (
                            <FileCard
                                key={id}
                                id={id}
                                name={file.name}
                                size={file.size}
                                error={failedUploadFileIds?.includes(id) ? 'Document failed to upload, please try again' : undefined}
                            />
                        ))}
                    </CardList>
                    <ReportDisplay />
                </>
            )}
        </Container>
    )
}

export default ReportContent
