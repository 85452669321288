import { captureMessage } from '@sentry/react'
import { Chat, FileWithMetadata } from '../types'
import { supportMessage } from './userMessages'
import { toast } from 'react-toastify'

export const chatFileLimit = 10
export const chatbotFileCountOK = (chat: Chat, newFiles: FileWithMetadata[] | FileList) => {
    if (newFiles.length > chatFileLimit) {
        toast.warn('A maximum of 10 documents can be uploaded to a single chat')
        return false
    }
    if (typeof chat?.documentCount === 'undefined') {
        captureMessage(`Document count is not set for chat: ${chat?.id}`)
        toast.error(`Unable to validate number of documents. ${supportMessage}`)
        return false
    }

    if (chat.documentCount >= chatFileLimit) {
        toast.warn(`This chat has already reached the limit of ${chatFileLimit} documents attached.`)
        return false
    }

    if (chat.documentCount + newFiles.length > chatFileLimit) {
        toast.warn(
            `This chat already has ${chat.documentCount} documents attached, you can only attach up to ${chatFileLimit - chat.documentCount} more documents`
        )
        return false
    }
    return true
}
