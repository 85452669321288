import UploadIcon from '../../icons/UploadIcon'
import { allowedFileExtensions } from '../../utils/documents'
import HiddenAttachInput from './HiddenAttachInput'
import { PropsWithChildren, useRef } from 'react'
import TextButton from './TextButton'
import { useDragAndDrop } from '../../hooks/useDragAndDrop'
import { styled, Typography } from '@mui/material'

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: `2px dashed ${theme.palette.text.primary}`,
    borderRadius: '22px',
    margin: '64px',
    padding: '5px',
    backgroundColor: `${theme.palette.background.default}e0`,
}))

const MainText = styled(Typography)({
    marginBottom: 0,
    marginTop: '20px',
})

const BrowseText = styled(TextButton)({
    margin: '5px 0 0',
})

const FormatsText = styled(Typography)({
    margin: '10px 0',
})

interface UploadAreaProps extends PropsWithChildren {
    className?: string
    clickable?: boolean
    folderOverride?: string
    alwaysVisible?: boolean
}

const UploadArea = ({ className, clickable, folderOverride, alwaysVisible, children }: UploadAreaProps) => {
    const [dropRef, dragging] = useDragAndDrop(folderOverride)
    const uploadRef = useRef<HTMLInputElement>(null)
    const uploadFolderRef = useRef<HTMLInputElement>(null)

    if (!alwaysVisible && !dragging) {
        return null
    }

    return (
        <Container className={className} ref={dropRef}>
            <UploadIcon />
            <MainText variant='h3'>Drag and drop to upload</MainText>
            {clickable && (
                <>
                    <BrowseText onClick={() => uploadRef.current?.click()}>
                        or browse files
                        <HiddenAttachInput ref={uploadRef} folderOverride={folderOverride} />
                    </BrowseText>
                    <BrowseText onClick={() => uploadFolderRef.current?.click()}>
                        or upload a folder
                        <HiddenAttachInput ref={uploadFolderRef} folderMode />
                    </BrowseText>
                </>
            )}
            <FormatsText variant='subtitle1'>Supported formats: {allowedFileExtensions.join(', ')}</FormatsText>
            {children}
        </Container>
    )
}

export default UploadArea
