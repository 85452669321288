export const stringToColour = (str: string, darkMode?: boolean): string => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
        hash = hash * 50 + str.charCodeAt(i)
        hash = hash & hash
    }

    hash = Math.abs(hash)

    const hue = hash % 360
    const sat = 0.6
    const val = darkMode ? 0.8 : 0.9

    const c = val * sat
    const x = c * (1 - Math.abs(((hue / 60) % 2) - 1))
    const m = val - c
    let [r, g, b] = [0, 0, 0]

    if (hue >= 0 && hue < 60) {
        ;[r, g, b] = [c, x, 0]
    } else if (hue < 120) {
        ;[r, g, b] = [x, c, 0]
    } else if (hue < 180) {
        ;[r, g, b] = [0, c, x]
    } else if (hue < 240) {
        ;[r, g, b] = [0, x, c]
    } else if (hue < 300) {
        ;[r, g, b] = [x, 0, c]
    } else {
        ;[r, g, b] = [c, 0, x]
    }

    r = Math.floor((r + m) * 255)
    g = Math.floor((g + m) * 255)
    b = Math.floor((b + m) * 255)

    return `rgb(${r},${g},${b})`
}
