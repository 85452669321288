import { Typography } from '@mui/material'
import { FormEvent, KeyboardEvent, MouseEvent, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import AddIcon from '../../icons/AddIcon'
import CrossIcon from '../../icons/CrossIcon'
import { UUID } from '../../types'
import Breadcrumb from '../common/Breadcrumb'
import Button from '../common/Button'
import ErrorCard from '../common/ErrorCard'
import Input from '../common/Input'
import TextArea from '../common/TextArea'
import UnstyledButton from '../common/UnstyledButton'
import WithLabels from '../common/WithLabels'
import DeleteTooltip from '../common/tooltips/DeleteTooltip'
import { StyledDeleteButton } from './EditPrompt'
import { DeleteFilterButton } from './PromptLibraries'
import { maxNumberOfTags, maxTagCharacterLength } from './promptUtils'
import { PromptLibrary, PromptLibraryPayload, PromptTag } from './types'

const StyledForm = styled.form`
    margin: 0 auto;
    width: 60%;
    height: 80%;
`

const StyledTextArea = styled(TextArea)`
    min-height: 100px;
    max-height: 150px;
    resize: vertical;
`

const TagInputContainer = styled(WithLabels)`
    position: relative;
    display: flex;
    align-items: left;
`

const AddTagButton = styled(UnstyledButton)`
    position: absolute;
    right: 10px;
    top: 42px;
`

const TagContainer = styled.div`
    display: flex;
    min-height: 25px; // to hold an empty space for the tags. Otherwise it resizes.
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
`

const StyledChip = styled.div`
    background-color: ${props => props.theme.colors.divider};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 25px;
    padding: 2px 2px 2px 10px;

    button {
        margin-left: 2px;
    }
`

const SubmissionRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
`

interface LibraryFormError {
    titleError: string | null
    descriptionError: string | null
    tagError: string | null
}

interface LibraryFormProps {
    loading: boolean
    library?: PromptLibrary
    onBack: () => void
    onSubmit: (payload: PromptLibraryPayload) => void
    onDelete?: (libraryId: UUID) => void
}

const LibraryForm = ({ loading, library, onBack, onSubmit, onDelete }: LibraryFormProps) => {
    const { colors } = useTheme()

    const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false)
    const [tagList, setTagList] = useState<PromptTag[]>(library ? library.tags : [])
    const [currentTag, setCurrentTag] = useState<string>('')
    const [title, setTitle] = useState<string>(library ? library.title : '')
    const [description, setDescription] = useState<string>(library ? library.description : '')
    const [formErrors, setFormErrors] = useState<LibraryFormError>({ titleError: null, descriptionError: null, tagError: null })

    const handleSubmitTag = (event: KeyboardEvent | MouseEvent) => {
        if (tagList.length >= maxNumberOfTags) {
            setFormErrors({ ...formErrors, tagError: `You can only add ${maxNumberOfTags} tags. Please remove some to add more.` })
            event.preventDefault()
        }

        if (event.type === 'click' || ('key' in event && event.key === 'Enter')) {
            event.preventDefault()

            const tagName = currentTag.trim()
            if (tagName) {
                if (tagList.find(t => t.name === tagName)) {
                    setFormErrors({ ...formErrors, tagError: 'Tag already exists' })
                } else {
                    setFormErrors({ ...formErrors, tagError: null })
                    setCurrentTag('')
                    setTagList(tagList.concat({ name: tagName, description: `A tag named: ${tagName}` }))
                }
            }
        }
    }

    const removeTag = (tag: PromptTag) => {
        setTagList(tagList.filter(item => item !== tag))
        setFormErrors({ ...formErrors, tagError: null })
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (title === '') {
            setFormErrors({ tagError: null, descriptionError: null, titleError: 'Please give the library a name before submitting.' })
        } else if (description === '') {
            setFormErrors({ tagError: null, titleError: null, descriptionError: 'Please give the library a description before submitting.' })
        } else {
            // clear errors as the below api sometimes takes a second to finish loading
            setFormErrors({ tagError: null, titleError: null, descriptionError: null })
            onSubmit({
                title,
                description,
                tags: tagList,
            })
        }
    }

    return (
        <>
            <Breadcrumb onClick={onBack} label='Back to Library' />
            <StyledForm onSubmit={handleSubmit} noValidate>
                <WithLabels label='Name' required>
                    {formErrors.titleError && <ErrorCard error={formErrors.titleError} />}
                    <Input placeholder='Name' value={title} onChange={e => setTitle(e.target.value)} required />
                </WithLabels>
                <WithLabels label='Description' required>
                    {formErrors.descriptionError && <ErrorCard error={formErrors.descriptionError} />}
                    <StyledTextArea placeholder='Description' value={description} onChange={e => setDescription(e.target.value)} required />
                </WithLabels>
                <TagInputContainer label='Tags'>
                    <Input
                        placeholder="Tag Name (press 'Enter' to add)"
                        value={currentTag}
                        onChange={e => setCurrentTag(e.target.value)}
                        onKeyDown={handleSubmitTag}
                        maxLength={maxTagCharacterLength}
                    />
                    {formErrors.tagError && <ErrorCard error={formErrors.tagError} />}
                    {tagList.length < maxNumberOfTags && (
                        <AddTagButton onClick={handleSubmitTag} title='Add Tag' aria-label='Add Tag'>
                            <AddIcon />
                        </AddTagButton>
                    )}
                </TagInputContainer>
                <TagContainer>
                    {tagList.map(tag => (
                        <StyledChip key={tag.name}>
                            <Typography variant='body2'>{tag.name}</Typography>
                            <DeleteFilterButton onClick={() => removeTag(tag)} title='Remove Tag' aria-label='Remove Tag'>
                                <CrossIcon color={colors.text} strokeWidth='1px' />
                            </DeleteFilterButton>
                        </StyledChip>
                    ))}
                </TagContainer>
                <SubmissionRow>
                    <Button type='submit' disabled={loading} aria-label='Save Library'>
                        Save Library
                    </Button>
                    {library && onDelete && (
                        <div>
                            <DeleteTooltip
                                id={library.id}
                                targetName='prompt library'
                                description='This prompt library will be permanently deleted.'
                                onDelete={() => onDelete(library.id)}
                                open={deleteTooltipOpen}
                                handleClose={() => setDeleteTooltipOpen(false)}
                                placement='top'
                            >
                                <StyledDeleteButton onClick={() => setDeleteTooltipOpen(true)} type='button' title='Delete' aria-label='delete library'>
                                    Delete
                                </StyledDeleteButton>
                            </DeleteTooltip>
                        </div>
                    )}
                </SubmissionRow>
            </StyledForm>
        </>
    )
}

export default LibraryForm
