import { Card } from './Card'
import BinIcon from '../icons/BinIcon'
import { useChatContext } from '../context/ChatContext'
import { useDelete } from '../hooks/useDelete'
import { chatEndpoint } from '../endpoints'
import { UUID, placeholderChatTitle } from '../types'
import Loading from './common/Loading'
import CenteredContainer from './common/CenteredContainer'
import { toast } from 'react-toastify'
import { genericErrorMsg } from '../utils/userMessages'
import useIsMobile from '../hooks/useIsMobile'
import TransparentButton from './common/TransparentButton'
import { CSSObject, styled, Theme, useTheme } from '@mui/material'
import { forwardPropsWithTransient } from '../utils/muiUtils'
import React from 'react'

export const baseCardStyles = (theme: Theme): CSSObject => ({
    padding: '16px 0',
    cursor: 'pointer',
    borderRadius: '12px',
    margin: '12px 12px 24px 12px',
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.divider}`,
})

export const Container = styled('section')({
    marginTop: '16px',
})

const StyledChatCard = styled(Card, {
    shouldForwardProp: forwardPropsWithTransient,
})<{ $current: boolean }>(({ $current, theme }) => ({
    ...baseCardStyles(theme),
    ...($current
        ? {
              backgroundColor: theme.palette.secondary.light,
          }
        : {
              '&:hover': {
                  backgroundColor: `${theme.palette.secondary.light}90`,
              },
          }),
}))

const DeleteButton = styled(TransparentButton)({
    gridArea: '1 / 4 / 3 / 5',
    alignSelf: 'center',
    justifySelf: 'center',
})

const CenteredDiv = styled('div')({
    display: 'flex',
    justifyContent: 'center',
})

const TopScrollMask = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '235px',
    width: 'calc(100% - 5px)',
    height: '40px',
    pointerEvents: 'none',
    backgroundImage: `linear-gradient(${theme.palette.background.default}, transparent)`,
}))

const BottomScrollMask = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: '76px',
    width: 'calc(100% - 5px)',
    height: '40px',
    pointerEvents: 'none',
    backgroundImage: `linear-gradient(transparent, ${theme.palette.background.default})`,
}))

interface ChatListProps {
    className?: string
}

const ChatList = ({ className }: ChatListProps) => {
    const { chats, selectChat, currentChat, removeChat, loading } = useChatContext()
    const { palette } = useTheme()
    const isMobile = useIsMobile()
    // TODO: Handle loading state (make individual cards show loading or just silently filter?)
    const [deleteChat] = useDelete(chatEndpoint)

    const handleDeleteChat = async (event: React.MouseEvent, chatId: UUID) => {
        event.stopPropagation()
        const result = await deleteChat(chatId)

        if (result) {
            removeChat(chatId)
        } else {
            toast.error(genericErrorMsg)
        }
    }

    if (chats.length < 1) {
        return <CenteredContainer aria-label='chat list'>{loading ? <Loading secondaryColor /> : <i>You don't have any chats yet.</i>}</CenteredContainer>
    }

    return (
        <>
            {!isMobile && <TopScrollMask />}
            <Container className={className} aria-label='chat list'>
                {chats.map(chat => (
                    <StyledChatCard
                        key={chat.id}
                        $current={chat.id === currentChat?.id}
                        heading={chat.title ?? placeholderChatTitle(chat.bot.toolType)}
                        subheading={chat.bot.name}
                        size='small'
                        onClick={() => selectChat(chat.id)}
                        bot={chat.bot}
                    >
                        <DeleteButton onClick={(event: React.MouseEvent) => handleDeleteChat(event, chat.id)} aria-label={`delete chat ${chat.title}`}>
                            <BinIcon fill={palette.text.primary} />
                        </DeleteButton>
                    </StyledChatCard>
                ))}
                {loading && (
                    <CenteredDiv>
                        <Loading secondaryColor />
                    </CenteredDiv>
                )}
            </Container>
            {!isMobile && <BottomScrollMask />}
        </>
    )
}

export default ChatList
