import styled, { css } from 'styled-components'
import InfoTooltip from '../common/tooltips/InfoTooltip'
import MiniIconButton from '../common/MiniIconButton'

export const ButtonTooltipWrapper = styled(InfoTooltip)`
    margin-left: 4px;
`

const libraryButtonStyles = css`
    display: inline-flex;

    &:hover:not([disabled]) {
        background-color: ${props => (props.theme.isDark ? props.theme.colors.divider : props.theme.colors.paper)};
    }
`

export const OuterTabButton = styled(MiniIconButton)`
    ${libraryButtonStyles}
    width: 27px;
    height: 27px;
    margin: 4px 0 0 4px;
    padding: 4px;
`

export const InnerTabButton = styled(MiniIconButton)`
    ${libraryButtonStyles}
    height: 22px;
    width: 22px;
    padding: 0 0 2px 2px;
    vertical-align: middle;
`
