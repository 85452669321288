import { Context, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { BaseReport, Report } from '../components/report/types'
import { FileWithMetadata, UUID, doNothing, doNothingEventually } from '../types'
import { useChatContext } from './ChatContext'
import { useMessageContext } from './MessageContext'
import { useFileContext } from './FileContext'
import { getAllTopLevelSubfolders, isInFolder } from '../utils/documents'
import { useGet } from '../hooks/useGet'
import { genericReportsEndpoint } from '../endpoints'
import { usePost } from '../hooks/usePost'
import { CreateOrUpdateGenericReportPayload } from '../apiTypes'
import { toast } from 'react-toastify'
import { getGenericReportPayload } from '../utils/reports'

export interface ReportState<TReport> {
    loading: null | 'sync' | 'uploading' | 'generating'
    onLoadingChange: (loading: null | 'sync' | 'uploading' | 'generating') => void
    reports: TReport[] | null
    failedUploadFileIds: UUID[] | null
    onUploadFailure: (failedFileIds: UUID[]) => void
    generateReport: () => Promise<void>
    folders: string[]
    selectedFolder: string
    onSelectedFolderChange: (folder: string) => void
    selectedReport: TReport | null
    folderFiles: FileWithMetadata[] | null
    onFolderDelete: (folder: string) => void
}

export const defaultState: ReportState<BaseReport> = {
    loading: 'sync',
    onLoadingChange: doNothing,
    reports: null,
    failedUploadFileIds: null,
    onUploadFailure: doNothing,
    generateReport: doNothingEventually,
    folders: [''], // single file/folder uploads use empty string so they can be handled in the same way as bulk
    selectedFolder: '',
    onSelectedFolderChange: doNothing,
    selectedReport: null,
    folderFiles: null,
    onFolderDelete: doNothing,
}

export const ReportContext = createContext<ReportState<BaseReport>>(defaultState)

interface WithReportContextProps extends PropsWithChildren {
    getEndpoint?: (chatId: UUID) => string
}

const WithReportContext = <TReport extends BaseReport>({ children, getEndpoint = genericReportsEndpoint }: WithReportContextProps) => {
    const { currentChat, blockChatSwapping, unblockChatSwapping, updateChat } = useChatContext()
    const { refreshRequestChatId } = useMessageContext()
    const { files, onRemove, upload } = useFileContext()
    const [reports, syncLoading, refresh] = useGet<TReport[]>(currentChat ? getEndpoint(currentChat.id) : null)
    const [createReport] = usePost<CreateOrUpdateGenericReportPayload, true>(currentChat ? genericReportsEndpoint(currentChat.id) : null)

    const [loading, setLoading] = useState(defaultState.loading)
    const [failedUploadFileIds, setFailedUploadFileIds] = useState<UUID[] | null>(defaultState.failedUploadFileIds)
    const [folders, setFolders] = useState<string[]>(defaultState.folders)
    const [selectedFolder, setSelectedFolder] = useState(defaultState.selectedFolder)

    const selectedReport = reports?.find(r => r.name === selectedFolder) ?? (reports && reports[0]) ?? null
    const isInSelectedFolder = isInFolder(selectedFolder)
    const folderFiles = selectedFolder ? files?.filter(isInSelectedFolder) ?? [] : files

    useEffect(() => {
        setFailedUploadFileIds(defaultState.failedUploadFileIds)
        setSelectedFolder(defaultState.selectedFolder)
        setFolders(defaultState.folders)
    }, [currentChat?.id])

    useEffect(() => {
        refreshRequestChatId && refresh()
    }, [refreshRequestChatId, refresh])

    useEffect(() => {
        // loading an already existing (set of) reports
        setFolders(reports && reports.length > 1 ? reports.map(r => r.name).sort() : ['']) // TODO: Legacy todo, replace '' with report name
    }, [reports])

    useEffect(() => {
        //fresh upload aka update folders only if report doesn't exist server-side
        if (!reports?.length) {
            if (files?.length) {
                const val = getAllTopLevelSubfolders(files).map(f => f.split('/')[1])
                const uniqueVals = [...new Set(val)].sort()
                setFolders(uniqueVals.length ? uniqueVals : defaultState.folders)
            } else {
                //ensure deleting last tab resets folders
                setFolders(defaultState.folders)
            }
        }
    }, [files, reports?.length])

    useEffect(() => {
        ;(!selectedFolder || !folders.includes(selectedFolder)) && folders.length && setSelectedFolder(folders[0])
    }, [folders, selectedFolder])

    useEffect(() => {
        if (!syncLoading) {
            setLoading(selectedReport && ['generating', 'pending'].includes(selectedReport.state) ? 'generating' : null)
        } else {
            setLoading('sync')
        }
    }, [syncLoading, selectedReport])

    const handleFolderDelete = (folder: string) => {
        const deleteIds = files?.filter(isInFolder(folder)).map(f => f.id)
        if (deleteIds) {
            onRemove(...deleteIds)
        }
    }

    const handleUploadFailure = (failedFileIds: UUID[]) => {
        setFailedUploadFileIds(failedFileIds)
        setLoading(null)
    }

    const handleUploadAndGenerate = async () => {
        if (!files?.length) {
            toast.warn('Please attach at least one file')
            return
        }
        setLoading('uploading')
        blockChatSwapping()

        const { uploadedIds, rejectedIds } = await upload()

        if (uploadedIds.length) {
            const payload = getGenericReportPayload(folders, reports, files)
            const generating = await createReport(payload)
            if (generating) {
                setLoading('generating')
            } else {
                setLoading(null)
            }

            if (currentChat) {
                const title = folders.length > 1 ? 'Bulk report' : 'Report'
                updateChat(currentChat.id, { title })
            }
        }

        if (rejectedIds.length) {
            handleUploadFailure(rejectedIds)
        }

        unblockChatSwapping()
    }

    return (
        <ReportContext.Provider
            value={{
                loading,
                onLoadingChange: setLoading,
                reports: reports ?? null,
                failedUploadFileIds,
                onUploadFailure: handleUploadFailure,
                generateReport: handleUploadAndGenerate,
                folders,
                selectedFolder,
                onSelectedFolderChange: setSelectedFolder,
                selectedReport,
                folderFiles,
                onFolderDelete: handleFolderDelete,
            }}
        >
            {children}
        </ReportContext.Provider>
    )
}

const useReportContext = <TReport extends BaseReport = Report>() => useContext(ReportContext as unknown as Context<ReportState<TReport>>)

export { WithReportContext, useReportContext }
