import styled, { css } from 'styled-components'

interface SizingProps {
    size: 'big' | 'small'
}

const getSize = (props: SizingProps) => (props.size === 'big' ? 16 : 8)
const getShadowSize = (props: SizingProps) => (props.size === 'big' ? 24 : 12)

const MessageLoading = styled.div<SizingProps>`
    width: ${getSize}px;
    height: ${getSize}px;
    border-radius: 50%;
    display: block;
    ${props =>
        props.size === 'big' &&
        css`
            margin: 15px auto;
        `}
    position: relative;
    background: #fff;
    box-shadow: -${getShadowSize}px 0 #fff, ${getShadowSize}px 0 #fff;
    box-sizing: border-box;
    animation: ${props => (props.size === 'big' ? 'bigShadowPulse' : 'smallShadowPulse')} 2s linear infinite;

    @keyframes bigShadowPulse {
        33% {
            background: #fff;
            box-shadow: -24px 0 ${props => props.theme.colors.secondary}, 24px 0 #fff;
        }
        66% {
            background: ${props => props.theme.colors.secondary};
            box-shadow: -24px 0 #fff, 24px 0 #fff;
        }
        100% {
            background: #fff;
            box-shadow: -24px 0 #fff, 24px 0 ${props => props.theme.colors.secondary};
        }
    }

    @keyframes smallShadowPulse {
        33% {
            background: #fff;
            box-shadow: -12px 0 ${props => props.theme.colors.secondary}, 12px 0 #fff;
        }
        66% {
            background: ${props => props.theme.colors.secondary};
            box-shadow: -12px 0 #fff, 12px 0 #fff;
        }
        100% {
            background: #fff;
            box-shadow: -12px 0 #fff, 12px 0 ${props => props.theme.colors.secondary};
        }
    }
`

export default MessageLoading
