import Modal, { ModalProps } from '../common/Modal'
import PromptLibraries from './PromptLibraries'
import styled from 'styled-components'
import SimplePromptDetail from './SimplePromptDetail'
import TemplatePromptDetail from './TemplatePromptDetail'
import Loading from '../common/Loading'
import { usePromptContext } from '../../context/PromptContext'
import { mediaQueries } from '../common/styles'
import { isTemplatePrompt } from './promptUtils'
import CreatePromptLibrary from './CreatePromptLibrary'
import EditPromptLibrary from './EditPromptLibrary'
import CreatePrompt from './CreatePrompt'
import EditPrompt from './EditPrompt'

const StyledModal = styled(Modal)`
    width: 75dvw;
    height: 80dvh;
    padding-bottom: 50px;

    @media ${mediaQueries.laptop} {
        width: 80dvw;
        height: 80dvh;
    }
`

interface PromptLibraryModalProps extends ModalProps {
    onSetInput: (input: string) => void
}

const PromptLibraryModal = ({ show, onClose, onSetInput }: PromptLibraryModalProps) => {
    const { loading, prompt, selectedLibrary, view, onSelectPrompt, onEditLibrary, onEditPrompt } = usePromptContext()

    const handleUsePrompt = (promptMessage: string) => {
        onSetInput(promptMessage)
        onClose()
    }

    const closePromptModal = () => {
        onSelectPrompt(null)
        onEditLibrary(false)
        onEditPrompt(false)
        onClose()
    }

    if (loading) {
        return (
            <StyledModal show={show} onClose={onClose} heading='Prompt Library' alignTitleCenter={false}>
                <Loading fullSize={true} secondaryColor={true} />
            </StyledModal>
        )
    }

    const getHeading = () => {
        switch (view) {
            case 'list':
                return undefined
            case 'prompt details':
                return 'Prompt Library'
            case 'new prompt':
                return 'Create New Prompt'
            case 'edit prompt':
                return 'Edit Prompt'
            case 'new library':
                return 'Create New Library'
            case 'edit library':
                return 'Edit Library'
        }
    }

    const renderContent = () => {
        switch (view) {
            case 'list':
                return <PromptLibraries />
            case 'new library':
                return <CreatePromptLibrary />
            case 'edit library':
                if (!selectedLibrary) {
                    // User should not be able to get to this state
                    return <Loading fullSize={true} secondaryColor={true} />
                }
                return <EditPromptLibrary library={selectedLibrary} />
            case 'new prompt':
                if (!selectedLibrary) {
                    // User should not be able to get to this state
                    return <Loading fullSize={true} secondaryColor={true} />
                }
                return <CreatePrompt library={selectedLibrary} />
            case 'edit prompt':
                if (!prompt || !selectedLibrary) {
                    // User should not be able to get to this state
                    return <Loading fullSize={true} secondaryColor={true} />
                }
                return <EditPrompt library={selectedLibrary} prompt={prompt} />
            case 'prompt details':
                if (!prompt) {
                    return <Loading fullSize={true} secondaryColor={true} />
                }
                return isTemplatePrompt(prompt) ? <TemplatePromptDetail onUsePrompt={handleUsePrompt} /> : <SimplePromptDetail onUsePrompt={handleUsePrompt} />
        }
    }

    return (
        <StyledModal show={show} onClose={closePromptModal} heading={getHeading()} alignTitleCenter={false}>
            {renderContent()}
        </StyledModal>
    )
}

export default PromptLibraryModal
