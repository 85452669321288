import { styled, Tooltip, TooltipProps } from '@mui/material'

const SizedSpan = styled('span')(() => ({
    height: 'max-content',
}))

interface InfoTooltipProps extends Omit<TooltipProps, 'title'> {
    title?: string
    disabled?: boolean
}

const InfoTooltip = ({ title, disabled, children, className, ...rest }: InfoTooltipProps) => {
    return (
        <Tooltip title={!disabled && title} {...rest}>
            <SizedSpan className={className}>{children}</SizedSpan>
        </Tooltip>
    )
}

export default InfoTooltip
