import { useEffect, useState } from 'react'

const useDebounce = <T>(value: T, delayInMilliseconds: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), delayInMilliseconds)

        return () => clearTimeout(handler)
    }, [value, delayInMilliseconds])

    return debouncedValue
}

export default useDebounce
