import styled from 'styled-components'
import UnstyledButton from './UnstyledButton'
import CrossIcon from '../../icons/CrossIcon'
import Loading from './Loading'
import { highlightOnHover } from './styles'
import { FC, SVGProps } from 'react'
import InfoTooltip from './tooltips/InfoTooltip'

const Container = styled.div`
    display: flex;
    gap: 8px;
    height: 40px;
    border: 1px dashed ${props => (props.theme.isDark ? props.theme.colors.textOnSecondary : props.theme.colors.tertiary)};
    border-radius: 8px;
    font-weight: bold;
`

const Label = styled(UnstyledButton)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 8px;
    height: 100%;
    width: 100%;

    ${props => props.onClick && highlightOnHover(props.theme.colors.secondary)}
    :hover {
        cursor: ${props => (props.onClick ? 'pointer' : 'default')};
    }
`

const StyledLoading = styled(Loading)`
    margin: 8px 0;
    width: 24px;
    height: 24px;
`

const IconButton = styled(UnstyledButton)`
    height: 100%;
    padding: 0 8px;
    display: flex;
    align-items: center;
    border-radius: 0 8px 8px 0;
    background-color: ${props => props.theme.colors.tertiary};
    ${props => highlightOnHover(props.theme.colors.tertiary)}
`

interface ChipProps {
    className?: string
    label: string
    title?: string
    loading?: boolean
    onClick?: () => void
    iconTitle?: string
    onIconClick?: () => void
    Icon?: FC<SVGProps<SVGSVGElement>>
}

const Chip = ({ className, label, title, loading, onClick, iconTitle, onIconClick, Icon = CrossIcon }: ChipProps) => {
    const handleIconClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        onIconClick && onIconClick()
    }

    return (
        <Container className={className} data-testid={`document-chip-${label}`}>
            <InfoTooltip title={onClick && title} sx={{ height: '100%', flex: 1 }}>
                <Label onClick={onClick} aria-label={onClick ? title : "this button currently doesn't do anything"}>
                    {label}
                </Label>
            </InfoTooltip>

            {loading && <StyledLoading />}
            {onIconClick && !loading && (
                <InfoTooltip title={iconTitle} sx={{ height: '100%' }}>
                    <IconButton onClick={e => handleIconClick(e)} aria-label='chip icon button'>
                        <Icon width={16} height={16} />
                    </IconButton>
                </InfoTooltip>
            )}
        </Container>
    )
}

export default Chip
