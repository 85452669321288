import ArrowIcon from '../../icons/ArrowIcon'
import { styled, Typography } from '@mui/material'
import TransparentButton from './TransparentButton'

const BackButton = styled(TransparentButton)(({ theme }) => ({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    'svg ': {
        width: '12px',
        height: '12px',
        color: theme.palette.text.secondary,
    },
}))

const Label = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    paddingTop: '1px',
    marginLeft: '10px',
}))

interface BreadcrumbProps {
    onClick: () => void
    label: string
}

const Breadcrumb = ({ onClick, label }: BreadcrumbProps) => {
    return (
        <BackButton onClick={onClick} aria-label='back button'>
            <ArrowIcon />
            <Label variant='subtitle1'>{label}</Label>
        </BackButton>
    )
}

export default Breadcrumb
