import { PropsWithChildren } from 'react'
import { styled, TooltipProps, Typography } from '@mui/material'
import FloatingCard from './FloatingCard'

const TooltipContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
}))

const SizedSpan = styled('span')(() => ({
    height: 'max-content',
}))

interface ExtendedTooltipProps extends Omit<TooltipProps, 'title'> {
    heading: string
    description: string
    disabled?: boolean
}

const ExpandedInfoTooltip = ({ heading, description, disabled, children, className, ...rest }: ExtendedTooltipProps) => {
    return (
        <FloatingCard title={disabled ? undefined : <TooltipContent heading={heading} description={description} />} {...rest}>
            <SizedSpan className={className}>{children}</SizedSpan>
        </FloatingCard>
    )
}

interface ToolTipContentProps extends PropsWithChildren {
    heading: string
    description: string
}

const TooltipContent = ({ heading, description }: ToolTipContentProps) => (
    <TooltipContainer>
        <Typography variant='h6'>{heading}</Typography>
        <Typography>{description}</Typography>
    </TooltipContainer>
)

export default ExpandedInfoTooltip
