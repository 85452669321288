import { useEffect } from 'react'
import styled from 'styled-components'
import LogoIcon from '../icons/LogoIcon'
import { scrollBarStyle } from './common/styles'
import Button from './common/Button'
import { environmentVariables } from '../env'
import DentonsGuidance from './DentonsGuidance'
import CRSGuidance from './CRSGuidance'

const OuterContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.background};
`

const StyledLogoIcon = styled(LogoIcon)`
    margin: 10px auto;
`

const InnerContainer = styled.main`
    overflow-y: auto;
    background-color: ${({ theme }) => theme.colors.paper};
    margin: 0 auto;
    padding: 36px;
    border-radius: 12px;
    max-width: 1500px;
    ${scrollBarStyle}

    h1:first-of-type {
        margin-top: 0;
    }
`

const ContinueButton = styled(Button)`
    align-self: center;
    margin: 15px 0;
`

const getGuidanceContent = () => {
    switch (environmentVariables.company) {
        case 'Dentons':
            return <DentonsGuidance />
        case 'CharlesRussellSpeechlys':
            return <CRSGuidance />
        default:
            return null
    }
}

interface GuidancePageProps {
    onContinue: () => void
}

const GuidancePage = ({ onContinue }: GuidancePageProps) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => (event.key === 'Enter' || event.key === 'Escape') && onContinue()

        window.addEventListener('keydown', handleKeyDown)

        return () => window.removeEventListener('keydown', handleKeyDown)
    })

    return (
        <OuterContainer>
            <StyledLogoIcon />
            <InnerContainer>{getGuidanceContent()}</InnerContainer>
            <ContinueButton onClick={onContinue} aria-label='acknowledge guidance read'>
                Continue
            </ContinueButton>
        </OuterContainer>
    )
}

export default GuidancePage
