import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'

const Input = styled.input`
    margin-right: 10px;
    accent-color: ${props => props.theme.colors.secondary};
`

interface RadioOptionProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string
}

const RadioOption = ({ className, label, ...rest }: RadioOptionProps) => (
    <label className={className}>
        <Input type='radio' {...rest} />
        {label}
    </label>
)

export default RadioOption
