import { SVGProps } from 'react'
import { useTheme } from 'styled-components'

const UploadIcon = ({ color, ...props }: SVGProps<SVGSVGElement>) => {
    const { colors } = useTheme()

    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={93} height={69} fill='none' {...props}>
            <path fill={color || colors.text} d='m33.52 43.942 3.44 3.44 6.894-6.895v27.531h4.865v-27.53l6.893 6.895 3.438-3.441-12.764-12.766L33.52 43.942Z' />
            <path
                fill={color || colors.text}
                d='M75.72 17.446C73.601 7.359 64.703 0 54.178 0c-8.864 0-16.9 5.437-20.302 13.48a17.805 17.805 0 0 0-20.873 13.315C5.855 26.9.071 32.754.071 39.94c0 7.245 5.89 13.143 13.13 13.143h14.476v-4.865H13.2c-4.557 0-8.265-3.712-8.265-8.278 0-4.568 3.708-8.283 8.265-8.283.416 0 .862.044 1.406.14a2.439 2.439 0 0 0 1.886-.455c.537-.41.882-1.02.95-1.692A12.96 12.96 0 0 1 30.37 17.988c1.395 0 2.862.251 4.241.727a2.433 2.433 0 0 0 3.125-1.605C39.888 9.9 46.65 4.865 54.18 4.865c8.78 0 16.11 6.575 17.048 15.295.142 1.31 1.278 2.288 2.615 2.165.286-.023.551-.044.823-.044 7.151 0 12.97 5.821 12.97 12.975 0 7.146-5.819 12.962-12.97 12.962H65.32v4.865h9.346c9.833 0 17.835-7.997 17.835-17.825.002-9.486-7.435-17.266-16.78-17.812Z'
            />
        </svg>
    )
}

export default UploadIcon
