import styled from 'styled-components'
import ErrorIcon from '../icons/ErrorIcon'
import { BotMessage } from '../types'
import MarkdownMessage from './common/MarkdownMessage'
import MessageTooltip from './common/MessageTooltip'
import CollapsedMessage from './CollapsedMessage'

const BaseErrorMessageIcon = styled(ErrorIcon)`
    width: 30px;
    height: 30px;
`

interface BotMessageContentProps {
    message: BotMessage
}

const BotMessageContent = ({ message }: BotMessageContentProps) => {
    const tooltip = message.messageType === 'error' ? 'The bot returned an error message.' : 'The bot returned a warning message.'
    // TODO define warning colours for light and dark. tbc with MUI upgrade
    const Icon = message.messageType === 'error' ? <BaseErrorMessageIcon /> : <BaseErrorMessageIcon color='#f2d602' />
    const showTooltip = message.messageType === 'error' || message.messageType === 'warning'

    return (
        <>
            {showTooltip && (
                <MessageTooltip id={message.id} tooltip={tooltip}>
                    {Icon}
                </MessageTooltip>
            )}
            {message.messageType === 'collapsed' ? <CollapsedMessage message={message.text} /> : <MarkdownMessage>{message.text}</MarkdownMessage>}
        </>
    )
}

export default BotMessageContent
