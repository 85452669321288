import UnstyledButton from '../../common/UnstyledButton'
import Checkbox from '../../common/Checkbox'
import ChevronIcon from '../../../icons/ChevronIcon'
import { PromptResult } from '../utils/types'
import FilterIcon from '../../../icons/FilterIcon'
import { ContentSectionProps, FilterSectionProps, cellWithFilterSectionStyles, dynamicHeightTableCell } from '../utils/styles'
import { placeholderPrompt, AUTO_RESIZE_TRANSITION_TIME } from '../utils/utils'
import ExpandedInfoTooltip from '../../common/tooltips/ExpandedInfoTooltip'
import { styled, useTheme } from '@mui/material'
import { forwardPropsWithTransient } from '../../../utils/muiUtils'

const Container = styled('td')(() => ({
    ...dynamicHeightTableCell,
}))

const InnerContainer = styled('div', { shouldForwardProp: forwardPropsWithTransient })<ContentSectionProps>(({ theme, $fullSize }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 30px)',
    gridTemplateRows: '1fr',
    gridRowGap: '10px',
    ...cellWithFilterSectionStyles.contentSectionStyles({ theme, $fullSize }),
    transition: `width ${AUTO_RESIZE_TRANSITION_TIME}ms ease-out`,
    justifyItems: 'center',
}))

const filterPadding = 18

const FilterContainer = styled('div', { shouldForwardProp: forwardPropsWithTransient })<FilterSectionProps>(({ theme, $disabled }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '35px',
    ...cellWithFilterSectionStyles.filterSectionStyles({ theme, $disabled }),
    padding: `5px ${filterPadding}px`,
    width: `calc(100% - ${filterPadding * 2}px)`,
}))

const CollapseButtonTooltipWrapper = styled(ExpandedInfoTooltip)(() => ({
    gridArea: '1 / 1 / 2 / 2',
    alignSelf: 'start',
    width: '30px',
}))

const CollapseButton = styled(UnstyledButton)(() => ({
    height: '20px',
}))

const StyledCheckboxTooltipWrapper = styled(ExpandedInfoTooltip)(() => ({
    marginTop: '2px',
    gridArea: '1 / 2 / 2 / 3',
    width: '30px',
}))

const StyledEllipsisTooltipWrapper = styled(ExpandedInfoTooltip)(() => ({
    marginTop: '2px',
    gridArea: '1 / 3 / 3 / 3',
    transform: 'translateY(-35%)',
}))

interface CollapsedPromptCellProps {
    className?: string
    promptResult: PromptResult
    selected: boolean
    onToggle: () => void
}

const CollapsedPromptCell = ({ className, promptResult: { id, prompt, filterValue }, selected, onToggle }: CollapsedPromptCellProps) => {
    const { palette } = useTheme()

    const showFilterSection = typeof filterValue === 'boolean'

    return (
        <Container className={className}>
            <InnerContainer $fullSize={!showFilterSection} className='visible-on-hover-parent'>
                <CollapseButtonTooltipWrapper heading='Coming Soon:' description='Expand / Collapse prompt'>
                    <CollapseButton type='button' aria-label='expand / collapse prompt' disabled>
                        <ChevronIcon width={20} height={18} direction='down' color={palette.secondary.dark} />
                    </CollapseButton>
                </CollapseButtonTooltipWrapper>
                <StyledCheckboxTooltipWrapper heading='Coming Soon:' description='Activate / Deactivate prompt'>
                    <Checkbox checked={selected} onClick={onToggle} disabled />
                </StyledCheckboxTooltipWrapper>

                <StyledEllipsisTooltipWrapper
                    heading='Prompt:'
                    description={(prompt ?? placeholderPrompt).length > 300 ? `${(prompt ?? placeholderPrompt).slice(0, 300)}...` : prompt ?? placeholderPrompt}
                    placement='right'
                >
                    <div>...</div>
                </StyledEllipsisTooltipWrapper>
            </InnerContainer>
            {showFilterSection && (
                <FilterContainer data-testid='filter section'>
                    <FilterIcon width={30} color={palette.text.secondary} />
                    <StyledEllipsisTooltipWrapper heading='Filter:' description={filterValue ? 'Yes' : 'No'} placement='right'>
                        <div>...</div>
                    </StyledEllipsisTooltipWrapper>
                </FilterContainer>
            )}
        </Container>
    )
}

export default CollapsedPromptCell
