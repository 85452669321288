import { Issue } from '../types'
import { useGet } from '../hooks/useGet'
import { knownIssuesEndpoint } from '../endpoints'
import { styled, Typography } from '@mui/material'

const KnownIssuesContainer = styled('details')(({ theme }) => ({
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    padding: '10px 10px',
    margin: '10px',
    borderRadius: '10px',
}))

const KnownIssuesSummary = styled('summary')({
    textAlign: 'center',
    fontWeight: 500,
    '&:hover': {
        cursor: 'pointer',
    },
})

const KnownIssuesList = styled('ul')<{ hasIssues: boolean }>(({ hasIssues }) => ({
    maxHeight: '100px',
    padding: hasIssues ? 'revert' : '0px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
}))

const KnownIssuesListItem = styled('li')({
    maxWidth: '550px',
    textAlign: 'left',
})

const Paragraph = styled(Typography)({
    margin: '12px',
})

const KnownIssues = () => {
    const [issues] = useGet<Issue[]>(knownIssuesEndpoint)

    const hasIssues = !!(issues && issues.length > 0)

    if (!hasIssues) return null

    return (
        <>
            <Paragraph>Before submission, please check if your issue is in the known issues list below.</Paragraph>
            <KnownIssuesContainer>
                <KnownIssuesSummary aria-label='known issues'>Known Issues</KnownIssuesSummary>
                <KnownIssuesList hasIssues={hasIssues}>
                    {issues.map(issue => (
                        <KnownIssuesListItem key={issue.id}>
                            <Typography variant='body2'>{issue.issue}</Typography>
                        </KnownIssuesListItem>
                    ))}
                </KnownIssuesList>
            </KnownIssuesContainer>
        </>
    )
}

export default KnownIssues
