import { toast } from 'react-toastify'
import { JSONFormPayload, JSONFormResponse } from '../../apiTypes'
import { botConfigEndpoint } from '../../endpoints'
import { useGet } from '../../hooks/useGet'
import { usePost } from '../../hooks/usePost'
import { UUID } from '../../types'
import FormWrapper from '../forms/FormWrapper'
import Divider from './Divider'
import Instructions from './Instructions'
import ErrorCard from '../common/ErrorCard'
import { configTabErrors } from '../../utils/userMessages'

interface BotConfigProps {
    id: UUID
    readonly: boolean
}

const BotConfig = ({ id, readonly }: BotConfigProps) => {
    const [config, configLoading, refresh] = useGet<JSONFormResponse>(botConfigEndpoint(id))
    const [saveConfig, saveLoading] = usePost<JSONFormPayload, boolean>(botConfigEndpoint(id))

    const handleSave = async (updatedConfig: JSONFormPayload) => {
        const response = await saveConfig(updatedConfig)

        if (response) {
            toast.success('Configuration has been updated')
            refresh()
        }
    }

    return (
        <>
            {readonly && <ErrorCard error={configTabErrors} />}
            <Instructions>
                This form customises the tool behaviour and is different for each tool type.
                <br />
                Saved changes will be applied to subsequent conversation turns and new chats.
            </Instructions>
            <Divider />
            <FormWrapper formData={config} disabled={readonly || configLoading || saveLoading} onSubmit={handleSave} />
        </>
    )
}

export default BotConfig
