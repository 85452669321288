import { useEffect, useState } from 'react'

const useLoadingDebounce = (loading: boolean, delayInMilliseconds: number) => {
    const [debouncedLoading, setDebouncedLoading] = useState(loading)

    useEffect(() => {
        if (loading) {
            const handler = setTimeout(() => setDebouncedLoading(loading), delayInMilliseconds)

            return () => clearTimeout(handler)
        } else {
            setDebouncedLoading(false)
        }
    }, [loading, delayInMilliseconds])

    return debouncedLoading
}

export default useLoadingDebounce
