const USDFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

export const toUSD = (value: number) => USDFormatter.format(value)

export const formatToUSD = (value: string): string => {
    const sanitizedValue = value.replace(/[^0-9.-]+/g, '')
    const numericValue = parseFloat(sanitizedValue)

    if (isNaN(numericValue)) {
        return ''
    }

    return toUSD(numericValue)
}

export const capitalizeFirstLetter = (value: string) => `${value.charAt(0).toUpperCase()}${value.slice(1)}`

export const clamp = (value: number, min: number, max: number): number => {
    return Math.min(Math.max(value, min), max)
}

export const semverWithoutBuild = (version: string) => version.substring(0, version.indexOf('+'))
