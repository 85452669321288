import styled, { useTheme } from 'styled-components'
import AddIcon from './AddIcon'
import { SVGProps } from 'react'

const CrossIcon = ({ width = 30, height = 30, strokeWidth = 1.25, color, ...rest }: SVGProps<SVGSVGElement>) => {
    const { colors } = useTheme()

    return <AddIcon width={width} height={height} strokeWidth={strokeWidth} color={color || colors.text} {...rest} />
}

const RotatedIcon = styled(CrossIcon)`
    transform: rotate(45deg);
`

export default RotatedIcon
