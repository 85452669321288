export const getFileNameFromHeader = (response: Response) => {
    return response.headers.get('content-disposition')?.split('filename=')[1] || 'unnamed'
}

export const downloadFile = (url: string, filename: string) => {
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = filename
    anchor.click()
}

export const downloadBlob = async (response: Response) => {
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)
    const filename = getFileNameFromHeader(response)
    downloadFile(url, filename)
    window.URL.revokeObjectURL(url)
}
