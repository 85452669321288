import { SVGProps } from 'react'
import { useTheme } from 'styled-components'

interface AttachIconProps extends SVGProps<SVGSVGElement> {
    disabled?: boolean
}

const AttachIcon = ({ disabled, ...rest }: AttachIconProps) => {
    const { colors } = useTheme()

    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={20} height={26} viewBox='0 0 24 26' fill='none' {...rest}>
            <path
                fill={disabled ? colors.disabled : colors.text}
                stroke='none'
                strokeLinejoin='round'
                strokeWidth={1}
                d='M20.579 3.077a7.832 7.832 0 0 0-11.134 0l-7.91 7.968a1.452 1.452 0 0 0 0 2.043c.56.564 1.468.564 2.028 0l7.91-7.968a4.98 4.98 0 0 1 7.078 0 5.069 5.069 0 0 1 0 7.131l-9.192 9.26a2.845 2.845 0 0 1-4.043 0 2.895 2.895 0 0 1 0-4.073l2.024-2.04 5.88-5.923a.71.71 0 0 1 1.008 0 .722.722 0 0 1 0 1.015l-6.622 6.671a1.452 1.452 0 0 0 0 2.043c.56.564 1.468.564 2.028 0l6.622-6.671a3.625 3.625 0 0 0 0-5.1 3.562 3.562 0 0 0-5.064 0l-7.905 7.963a5.8 5.8 0 0 0 0 8.159 5.698 5.698 0 0 0 8.1 0l9.192-9.26c3.075-3.098 3.075-8.12 0-11.217v-.001Z'
            />
        </svg>
    )
}

export default AttachIcon
