import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled, Typography } from '@mui/material'
import Loading from '../../common/Loading'
import { toUSD } from '../../../utils/formatting'
import UserSelect from './UserSelect'
import { User } from '../../../types'
import UserRoleSelect from './UserRoleSelect'
import UserQuotaEditable from './UserQuotaEditable'

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    maxHeight: 480,
    paddingRight: 12,
}))

const StyledHeadCell = styled(TableCell)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    padding: '12px 2px',
    minWidth: 100,
}))

const StyledCell = styled(TableCell)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    padding: '12px 2px',
}))

interface MemberTableProps {
    users: User[]
    loading: boolean
    onUserStateChange: () => void
}

const MemberTable = ({ users, loading, onUserStateChange }: MemberTableProps) =>
    loading ? (
        <Loading secondaryColor />
    ) : (
        <StyledTableContainer>
            <Table aria-label='member table' stickyHeader>
                <TableHead>
                    <TableRow>
                        <StyledHeadCell>
                            <Typography variant='subtitle1'>Name</Typography>
                        </StyledHeadCell>
                        <StyledHeadCell align='right'>
                            <Typography variant='subtitle1'>Status</Typography>
                        </StyledHeadCell>
                        <StyledHeadCell align='right'>
                            <Typography variant='subtitle1'>Role</Typography>
                        </StyledHeadCell>
                        <StyledHeadCell align='right'>
                            <Typography variant='subtitle1'>Quota</Typography>
                        </StyledHeadCell>
                        <StyledHeadCell align='right'>
                            <Typography variant='subtitle1'>Cost this week</Typography>
                        </StyledHeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map(({ id, name, isActive, quota, costThisWeek, role }: User) => (
                        <TableRow key={id}>
                            <StyledCell component='th' scope='row'>
                                <Typography>{name}</Typography>
                            </StyledCell>
                            <StyledCell align='right'>
                                <UserSelect isActive={isActive} userId={id} onUserStateChange={onUserStateChange} />
                            </StyledCell>
                            <StyledCell align='right'>
                                <UserRoleSelect userId={id} role={role} onUserStateChange={onUserStateChange} />
                            </StyledCell>
                            <StyledCell align='right'>
                                <UserQuotaEditable userId={id} quota={quota} onUserStateChange={onUserStateChange} />
                            </StyledCell>
                            <StyledCell align='right'>
                                <Typography>{toUSD(costThisWeek)}</Typography>
                            </StyledCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    )

export default MemberTable
