import styled, { useTheme } from 'styled-components'
import { usePromptContext } from '../../context/PromptContext'
import { useUserContext } from '../../context/UserContext'
import { ButtonTooltipWrapper, OuterTabButton } from './styles'
import AddIcon from '../../icons/AddIcon'
import { ChangeEvent, useRef } from 'react'
import { CircularProgress } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { usePost } from '../../hooks/usePost'
import { libraryImportEndpoint } from '../../endpoints'
import { validateLibraryImportFile } from '../../utils/documents'

const HiddenInput = styled('input')({
    display: 'none',
})

const LibraryControls = () => {
    const theme = useTheme()
    const { isMaintainer } = useUserContext()
    const { onLibraryChange, refreshLibraries, refreshPromptList } = usePromptContext()

    const hiddenInputRef = useRef<HTMLInputElement>(null)

    const [uploadLibrary, libraryUploadLoading] = usePost<FormData, boolean>(libraryImportEndpoint)

    const handleLibraryImport = async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
        const file = validateLibraryImportFile(files)

        if (file) {
            const payload = new FormData()
            payload.append('file', file)

            const success = await uploadLibrary(payload)
            if (success) {
                refreshLibraries()
                refreshPromptList()
            }
        }
    }

    return (
        <>
            <ButtonTooltipWrapper title='Add Library'>
                <OuterTabButton type='button' onClick={() => onLibraryChange('new')} aria-label='add library'>
                    <AddIcon color={theme.colors.text} withCircle />
                </OuterTabButton>
            </ButtonTooltipWrapper>
            {isMaintainer && (
                <ButtonTooltipWrapper title='Import Library'>
                    <OuterTabButton type='button' onClick={() => hiddenInputRef.current?.click()} disabled={libraryUploadLoading} aria-label='import library'>
                        {libraryUploadLoading ? <CircularProgress size={15} /> : <CloudUploadIcon />}
                        <HiddenInput ref={hiddenInputRef} type='file' onChange={handleLibraryImport} />
                    </OuterTabButton>
                </ButtonTooltipWrapper>
            )}
        </>
    )
}

export default LibraryControls
