import { Dialog, styled } from '@mui/material'
import AccountSettingsController from './AccountSettingsController'

const StyledModal = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.background.default,
        paddingTop: 60,

        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
        },
    },
}))

interface AccountSettingsProps {
    open: boolean
    onClose: () => void
}

//This is for the temporary modal setup, this container will stand on its own with dashboard routing
const AccountSettings = ({ open, onClose }: AccountSettingsProps) => {
    return (
        <StyledModal open={open} onClose={onClose} aria-labelledby='account settings' aria-describedby='account settings' fullScreen>
            <AccountSettingsController onBack={onClose} />
        </StyledModal>
    )
}

export default AccountSettings
