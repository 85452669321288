import { PropsWithChildren } from 'react'
import { UUID } from '../../types'
import { styled, Tooltip } from '@mui/material'

const TooltipSource = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '-20px',
    left: '-18px',
    cursor: 'help',
    backgroundColor: theme.palette.background.paper,
    height: '36px',
    width: '36px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

interface MessageTooltipProps extends PropsWithChildren {
    id: UUID
    tooltip: string
}

const MessageTooltip = ({ id, tooltip, children }: MessageTooltipProps) => (
    <Tooltip title={tooltip} id={id} role='tooltip'>
        <TooltipSource>{children}</TooltipSource>
    </Tooltip>
)

export default MessageTooltip
