import { styled, Typography } from '@mui/material'

const SectionHeading = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
}))

const RiskLegalTeam = () => <a href='mailto:RiskLegalTeam@crsblaw.com'>Risk Legal Team</a>

// Note: Text on this page is displayed verbatim, as provided by CRS
const CRSGuidance = () => (
    <>
        <Typography>Welcome to our new AI tool, Sidekick. We encourage you to explore the various uses of this tool.</Typography>
        <SectionHeading variant='h2'>Overview</SectionHeading>
        <Typography>
            Sidekick is a proprietary Generative AI system powered by GPT4 and other Large Language Models. In the tool you will be able to chat with the AI and
            upload client documents to help you during the course of your matter work.
        </Typography>
        <Typography>
            The Sidekick tool is a private instance. This means that our data is not used to train the AI, nor can it be accessed in any way by OpenAI or
            Microsoft. The data is hosted in the UK. By setting up the tool in this way, and pursuant to the rest of this guidance, we are compliant with our
            SRA obligations.
        </Typography>
        <Typography>The rest of this guidance will cover:</Typography>
        <ul>
            <li>
                <Typography>recommended use cases</Typography>
            </li>
            <li>
                <Typography>advice on prompting</Typography>
            </li>
            <li>
                <Typography>upcoming features</Typography>
            </li>
            <li>
                <Typography>your obligations and DP monitoring</Typography>
            </li>
        </ul>
        <SectionHeading variant='h2'>Recommended use cases</SectionHeading>
        <Typography>
            In general, we would like you to be creative with your uses of the tool and start to build up your own use cases over time. In terms of a broad
            list, you should be looking to use the tool to assist you in:
        </Typography>
        <ul>
            <li>
                <Typography>document analysis and reporting</Typography>
            </li>
            <li>
                <Typography>clause drafting</Typography>
            </li>
            <li>
                <Typography>summarising</Typography>
            </li>
            <li>
                <Typography>translating</Typography>
            </li>
            <li>
                <Typography>preparing training materials</Typography>
            </li>
            <li>
                <Typography>strategic planning</Typography>
            </li>
            <li>
                <Typography>brainstorming</Typography>
            </li>
            <li>
                <Typography>legal research (subject to the restrictions and limitations below)</Typography>
            </li>
        </ul>
        <Typography>
            Pursuant to the guidance provided by the <RiskLegalTeam /> later on this page, the AI should only be used to produce a first draft and all outputs
            of the tool should be independently verified and validated by you, and you should remain the owner of the ultimate advice to the client.
        </Typography>
        <SectionHeading variant='h2'>Restrictions and Limitations on Use</SectionHeading>
        <Typography>
            The main exclusion we have at this point is to not upload any third party documents unless we are expressly permitted to do so as this potentially
            infringes copyright and / or is a breach of licence terms. Please remember that the fact that a document is available on the internet does not
            automatically make it free to use. You need to check the licence terms.
        </Typography>
        <Typography>
            Specifically there are a large number of public sector websites that operate under open licence such as{' '}
            <a href='https://caselaw.nationalarchives.gov.uk/'>Find case law - The National Archives</a> for caselaw and{' '}
            <a href='https://www.legislation.gov.uk/'>Legislation.gov.uk</a> , <a href='https://www.parliament.uk/'>UK Parliament</a> for legislation which may
            be used. In contrast, we must not upload materials from Practical Law, Westlaw or Lexis+ into Sidekick, as their terms prohibit this upload.
        </Typography>
        <Typography>
            Please also note that Sidekick only has access to a closed dataset which is not up to date. All responses MUST be treated with caution and verified.
        </Typography>
        <SectionHeading variant='h2'>Advice on prompting</SectionHeading>
        <Typography>To obtain the best results on prompting, we have put together the following pointers:</Typography>
        <ul>
            <li>
                <Typography>
                    Try and make your request as detailed as possible, giving it extra information that could help elicit the optimal response. This could
                    include instructing the Sidekick to give you only one of a few specific answers or options.
                </Typography>{' '}
            </li>
            <li>
                <Typography>
                    It is usually helpful to give the Sidekick a persona, eg, "from the perspective of a senior commercial lawyer based in England &amp; Wales".
                </Typography>{' '}
            </li>
            <li>
                <Typography>Ask the Sidekick for the logical steps that it took to arrive at an answer. You can then drill down into these steps.</Typography>
            </li>
            <li>
                <Typography>Try asking the same question in slightly different ways, as the AI will usually respond with distinct answers.</Typography>
            </li>
            <li>
                <Typography>
                    Break one prompt down into separate steps in multiple prompts. For example, if you want the Sidekick to extract all indemnities from
                    different sections of the document and actively amend them in a certain way, it won't do it. Instead, you should break down that task into
                    separate prompts.
                </Typography>{' '}
            </li>
            <li>
                <Typography>
                    After you've reached a conclusion, ask if the AI has any more information for you that can help. Usually there will be something it hasn't
                    mentioned already.
                </Typography>{' '}
            </li>
        </ul>
        <Typography>
            We have also prepared some further guidance which can be accessed{' '}
            <a href='https://crs.docebosaas.com/learn/courses/1997/sidekick-writing-good-prompts/lessons/5426/sidekick-writing-good-ai-prompts'>here</a> . This
            will be updated periodically.
        </Typography>
        <SectionHeading variant='h2'>Upcoming features</SectionHeading>
        <Typography>Over the coming months we will embedding the following features into the tool:</Typography>
        <ul>
            <li>
                <Typography>ability to toggle different AI models within the chat interface</Typography>
            </li>
            <li>
                <Typography>a prompt library from within the tool to suggest template prompts you can use</Typography>
            </li>
            <li>
                <Typography>ability to run a sequence of prompts in the background to, eg, automate the first draft of multiple contract reports</Typography>
            </li>
            <li>
                <Typography>option of toggling a more or less creative response</Typography>
            </li>
        </ul>
        <SectionHeading variant='h2'>Your obligations</SectionHeading>
        <Typography>
            It is essential that all users of this tool, whether or not they are legally qualified or working as a fee-earner, use it in a way that is
            consistent with the firm's obligations to its clients, including pursuant to the{' '}
            <a href='https://www.sra.org.uk/solicitors/standards-regulations/code-conduct-solicitors/'>SRA Code of Conduct</a>.
        </Typography>
        <Typography>
            You must always take responsibility for critically reviewing, confirming and, where necessary, correcting the output from this tool. If using this
            platform for research, just as you would with Google or other research tools, you should verify responses using other primary sources such as
            Lexis+, Westlaw or equivalent.
        </Typography>
        <Typography>
            This tool presents the risk of producing over-simplified or partially inaccurate content: therefore, you must make yourself comfortable that the end
            product you send to a client or other parties is accurate and includes everything it needs.
        </Typography>
        <Typography>
            If you use this tool for client work, in the same way you would save your research notes or sources, you must save a copy to the client's iManage
            matter folder of any chats which have been used to generate advice or work product to be sent to the client or a third party on the client's behalf:
            i.e. anything to which you may need to refer in the future (including for the purpose of handovers or general memory refreshers) and anything we
            might need to refer to, rely on or disclose in the event of a claim or complaint being made in the future. Please use the 'print to PDF' option in
            the chat window to do this.
        </Typography>
        <Typography>
            Some clients have notified us that they do not consent to us using generative AI tools. We will shortly attach a list here with those client names.
            Going forward, our new engagement letters will contain information about our use of AI tools. Should a client tell you that they do not consent to
            use of these tools, please inform the <RiskLegalTeam /> immediately. If the client objects to the use of Sidekick, you may not under any
            circumstance upload the client's information onto this platform or use this tool as part of the services we provide for them.
        </Typography>
        <Typography>
            When using Sidekick on a client's matters for the first time, and particularly where the client has not received an engagement letter with the new
            AI wording, you should notify the client so that they have an opportunity to raise any concerns they may have. This may be done by, for example,
            noting in an email to the client regarding the scope of work you are undertaking that aspects of the work may be completed with the assistance of an
            AI tool. Provided the client has been notified, there is no requirement to obtain signed consent. If you are using Sidekick on a client's matter and
            you are not sure whether it is appropriate to use the tool, please speak to your supervisor or matter partner.
        </Typography>
        <Typography>
            You must not refer to two different clients' information in the same chat thread, as this presents the risk of 'cross-contaminating' outputs.
        </Typography>
        <SectionHeading variant='h2'>Data protection and monitoring</SectionHeading>
        <Typography>
            Please ensure that you have read our firm's{' '}
            <a href='https://crsblaw.sharepoint.com/sites/policies/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2Fpolicies%2FShared%20Documents%2FArtificial%20Intelligence%20Policy%2Epdf&parent=%2Fsites%2Fpolicies%2FShared%20Documents'>
                Artificial Intelligence Policy
            </a>{' '}
            and{' '}
            <a href='https://crsblaw.sharepoint.com/sites/policies/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2Fpolicies%2FShared%20Documents%2FData%20Protection%20and%20Monitoring%20Policy%2Epdf&parent=%2Fsites%2Fpolicies%2FShared%20Documents'>
                Data Protection and Monitoring Policy
            </a>
            .
        </Typography>
        <Typography>
            Please note that deleting your chat history will not permanently erase it from our systems, and we may still be able to access your chat
            transcripts. We do not intend to monitor your use or access your transcripts unless there is an overarching legal, regulatory or insurance
            requirement.
        </Typography>
        <SectionHeading variant='h2'>Assistance</SectionHeading>
        <Typography>
            If you have a legal, ethical or regulatory question concerning the use of Sidekick, please contact the <RiskLegalTeam />.
        </Typography>
        <Typography>
            If you need technical support with Sidekick, please contact the{' '}
            <a href='https://crsblaw.sharepoint.com/sites/legal-technology/SitePages/CRSidekick.aspx'>Advanced Client Solutions Team</a>.
        </Typography>
    </>
)

export default CRSGuidance
