import { UUID } from 'crypto'
import { changeMemberActiveEndpoint } from '../../../endpoints'
import { usePut } from '../../../hooks/usePut'
import { MenuItem, Select, styled, Typography } from '@mui/material'

const StyledSelect = styled(Select)(() => ({
    boxShadow: 'none',

    '.MuiOutlinedInput-notchedOutline': { border: 0 },
    '.MuiSelect-icon': { width: 16 },
}))

interface UserSelectInterface {
    isActive: boolean
    userId: UUID
    onUserStateChange: () => void
}

const UserSelect = ({ isActive, userId, onUserStateChange }: UserSelectInterface) => {
    const [requestUserStateChange] = usePut(changeMemberActiveEndpoint(isActive, userId))

    const handleUserStateChange = async () => {
        const success = await requestUserStateChange({})
        success && onUserStateChange()
    }

    return (
        <StyledSelect value={isActive ? 'active' : 'inactive'} onChange={handleUserStateChange}>
            <MenuItem value='active'>
                <Typography>Active</Typography>
            </MenuItem>
            <MenuItem value='inactive'>
                <Typography>Inactive</Typography>
            </MenuItem>
        </StyledSelect>
    )
}

export default UserSelect
