import { CircularProgress, styled, Typography } from '@mui/material'
import { TenancySettings } from '../utils/types'
import { getCurrentWeekRange } from '../../../utils/dates'
import Loading from '../../common/Loading'
import { toUSD } from '../../../utils/formatting'

const CardContainer = styled('div')(({ theme }) => ({
    margin: '12px 0',
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '12px',
    },
}))

const Card = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: '12px 32px',
    borderRadius: 12,
    textAlign: 'center',
}))

const FlexContainer = styled('div')(() => ({
    display: 'flex',
    gap: 24,
}))

const ProgressContainer = styled('div')(() => ({
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
}))

const LabelContainer = styled('div')(() => ({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

const StyledLoading = styled(Loading)(() => ({
    marginTop: 24,
}))

interface SettingsDataCardsProps {
    tenancySettings?: TenancySettings | null
    loading: boolean
}

const SettingsDataCards = ({ tenancySettings, loading }: SettingsDataCardsProps) => {
    const progress = tenancySettings ? (tenancySettings.costThisWeek / tenancySettings.quota) * 100 : 0
    const availableSeats = tenancySettings ? tenancySettings.seats - tenancySettings.activeUserCount : 0

    return (
        <CardContainer>
            <Card>
                <Typography variant='subtitle2'>Seats</Typography>
                {loading ? <StyledLoading secondaryColor /> : <Typography variant='h2'>{tenancySettings ? availableSeats : 'no data'} available</Typography>}
                <Typography variant='h5'>{tenancySettings ? `of ${tenancySettings.seats}` : null}</Typography>
            </Card>
            <Card>
                <FlexContainer>
                    <ProgressContainer>
                        <CircularProgress variant='determinate' value={progress} size={100} />
                        <LabelContainer>
                            <Typography variant='subtitle2'>{`${progress.toFixed(0)}%`}</Typography>
                        </LabelContainer>
                    </ProgressContainer>
                    <div>
                        <Typography variant='subtitle2'>Weekly Quota - {getCurrentWeekRange()}</Typography>
                        {loading ? (
                            <StyledLoading secondaryColor />
                        ) : (
                            <>
                                <Typography variant='h2'>{tenancySettings ? toUSD(tenancySettings.costThisWeek) : 'no data available'}</Typography>
                                <Typography variant='h5'>{tenancySettings ? `of ${toUSD(tenancySettings.quota)} limit` : null}</Typography>
                            </>
                        )}
                    </div>
                </FlexContainer>
            </Card>
        </CardContainer>
    )
}

export default SettingsDataCards
