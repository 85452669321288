import { Document, Dollar, UUID } from '../../../types'

export type ResultState = 'pending' | 'running' | 'done' | 'skipped' | 'generation_error'

interface BaseDocumentResult {
    documentId: UUID
    state: ResultState
}

interface ResultWithoutValue extends BaseDocumentResult {
    promptDigest: null
    value: null
}

interface ResultWithValue extends BaseDocumentResult {
    promptDigest: string
    value: string
}

export type DocumentResult = ResultWithoutValue | ResultWithValue

export type ResponseType = 'boolean' | 'text' | 'short_text' | 'bullet_points' | 'entity'

interface BasePromptResult {
    id: UUID
    prompt: string
    digest: string
    responseType: ResponseType
    results: DocumentResult[]
}

interface BooleanPromptResult extends BasePromptResult {
    responseType: 'boolean'
    filterValue: boolean | null
}

interface TextPromptResult extends BasePromptResult {
    responseType: 'text'
    filterValue: string | null
}

interface ShortTextPromptResult extends BasePromptResult {
    responseType: 'short_text'
    filterValue: string | null
}

interface BulletPointsPromptResult extends BasePromptResult {
    responseType: 'bullet_points'
    filterValue: string | null
}

interface EntityPromptResult extends BasePromptResult {
    responseType: 'entity'
    filterValue: string | null
}

export type PromptResult = BooleanPromptResult | TextPromptResult | ShortTextPromptResult | BulletPointsPromptResult | EntityPromptResult

export interface SkimmerReport {
    chatId: UUID
    costCap: Dollar
    documents: Document[]
    results: PromptResult[]
}

interface CellStateUpdate {
    chatId: UUID
    promptId: UUID
    documentId: UUID
    state: ResultState
}

interface CellValueUpdate extends CellStateUpdate {
    promptDigest: string
    text: string
}

export type CellUpdate = CellStateUpdate | CellValueUpdate

export const isCellValueUpdate = (value: CellUpdate): value is CellValueUpdate => 'promptDigest' in value && 'text' in value
