import { ClickAwayListener, emphasize, styled, Typography } from '@mui/material'
import { PropsWithChildren } from 'react'
import { UUID } from '../../../types'
import Button from '../Button'
import TransparentButton from '../TransparentButton'
import FloatingCard from './FloatingCard'

const Container = styled('div')({
    margin: 12,
})

const Controls = styled('div')({
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '15px',
})

const DeleteButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.error.dark,
    maxWidth: 300,
    marginLeft: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',

    '&:hover': {
        backgroundColor: emphasize(theme.palette.error.dark, 0.1),
    },
}))

const SizedSpan = styled('span')(() => ({
    width: '100%',
}))

interface DeleteTooltipProps extends PropsWithChildren {
    id: UUID
    targetName: string
    description?: string
    onDelete: () => void
    open: boolean
    handleClose: () => void
    placement?: 'left' | 'right' | 'top' | 'bottom'
}

const DeleteTooltip = ({ id, open, handleClose, targetName, onDelete, description, placement = 'bottom', children }: DeleteTooltipProps) => {
    return (
        <FloatingCard
            id={id}
            onClose={handleClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement={placement}
            title={
                <ClickAwayListener onClickAway={handleClose}>
                    <Container>
                        <Typography>
                            This action is irreversible.
                            <br />
                            {description}
                        </Typography>
                        <Controls>
                            <TransparentButton type='button' aria-label='abort delete' onClick={handleClose}>
                                Cancel
                            </TransparentButton>
                            <DeleteButton type='button' aria-label='confirm delete' onClick={onDelete}>
                                Delete {targetName}
                            </DeleteButton>
                        </Controls>
                    </Container>
                </ClickAwayListener>
            }
        >
            <SizedSpan>{children}</SizedSpan>
        </FloatingCard>
    )
}

export default DeleteTooltip
