import styled, { css } from 'styled-components'
import { DocumentUserMessage } from '../types'
import MarkdownMessage from './common/MarkdownMessage'
import DocumentChip from './DocumentChip'

interface DocumentMessageProps {
    message: DocumentUserMessage
}

const StyledDocumentChip = styled(DocumentChip)<{ applyTopMargin: boolean }>`
    ${({ applyTopMargin }) =>
        applyTopMargin &&
        css`
            margin-top: 20px;
        `}
`

const DocumentMessage = ({ message }: DocumentMessageProps) => {
    const text = message.text ?? ''

    return (
        <>
            <MarkdownMessage>{text}</MarkdownMessage>
            {message.documents.length > 0
                ? message.documents.map((document, i) => (
                      <StyledDocumentChip key={document.id} applyTopMargin={i === 0 && text !== ''} chatId={message.chatId} document={document} />
                  ))
                : 'Documents seem to be missing, please report an issue if this is unexpected'}
        </>
    )
}

export default DocumentMessage
