import { useMsal } from '@azure/msal-react'
import styled from 'styled-components'
import { selectAccountLoginRequest } from '../authConfig'
import LogoIcon from '../icons/LogoIcon'
import MicrosoftIcon from '../icons/MicrosoftIcon'
import UnstyledButton from './common/UnstyledButton'
import { InteractionStatus } from '@azure/msal-browser'
import { toast } from 'react-toastify'
import useDebounce from '../hooks/useDebounce'
import Loading from './common/Loading'
import { Typography } from '@mui/material'

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.colors.background};
`

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    padding: 22px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    background-color: ${props => props.theme.colors.paper};
`

const IconContainer = styled.div`
    position: absolute;
    top: 36px;
`

const MicrosoftButton = styled(UnstyledButton)`
    border-radius: 10px;
    background-color: ${props => props.theme.colors.secondary};
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 32px auto 0 auto;
    padding: 20px;

    & h6 {
        color: ${props => props.theme.colors.textOnSecondary};
    }
`

const StyledLoading = styled(Loading)`
    margin: 32px 0;
`

const Login = () => {
    const { instance, inProgress } = useMsal()
    const debouncedInteractionStatus = useDebounce(inProgress, 50)

    const handleMicrosoftLogin = async () => {
        if (inProgress !== InteractionStatus.None) {
            toast.warn('Authentication already in progress, please check for the popup window or try refreshing the page')
        } else {
            // Errors are caught in the effect in App
            await instance.loginPopup(selectAccountLoginRequest)
        }
    }

    return (
        <Container>
            <IconContainer>
                <LogoIcon />
            </IconContainer>
            <LoginContainer>
                <Typography variant='h2' color='text.primary'>
                    Sign In
                </Typography>
                <Typography color='text.primary'>You can sign in using the Microsoft account that has been linked to SpringLaw.</Typography>
                {debouncedInteractionStatus === InteractionStatus.None ? (
                    <MicrosoftButton type='button' onClick={handleMicrosoftLogin}>
                        <MicrosoftIcon />
                        <Typography variant='h6'>Continue with Microsoft</Typography>
                    </MicrosoftButton>
                ) : (
                    <StyledLoading secondaryColor />
                )}
            </LoginContainer>
        </Container>
    )
}

export default Login
