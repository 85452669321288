import { toast } from 'react-toastify'
import { JSONFormPayload, JSONFormResponse } from '../../apiTypes'
import { botBalancersConfigEndpoint } from '../../endpoints'
import { useGet } from '../../hooks/useGet'
import { usePost } from '../../hooks/usePost'
import { UUID } from '../../types'
import FormWrapper from '../forms/FormWrapper'
import Divider from './Divider'
import Instructions from './Instructions'
import ErrorCard from '../common/ErrorCard'
import { configTabErrors } from '../../utils/userMessages'

interface BalancersProps {
    id: UUID
    readonly: boolean
}

const Balancers = ({ id, readonly }: BalancersProps) => {
    const [balancersConfig, balancersConfigLoading, refresh] = useGet<JSONFormResponse>(botBalancersConfigEndpoint(id))
    const [saveBalancersConfig, saveLoading] = usePost<JSONFormPayload, boolean>(botBalancersConfigEndpoint(id))

    const handleSave = async (updatedConfig: JSONFormPayload) => {
        const response = await saveBalancersConfig(updatedConfig)

        if (response) {
            toast.success('Configuration has been updated')
            refresh()
        }
    }

    return (
        <>
            {readonly && <ErrorCard error={configTabErrors} />}
            <Instructions>
                Configure which AI Engines (models) the bot uses for different tasks, e.g. reading the document and generating answers.
                <br />
                The choice will determine output quality, usage cost and latency.
            </Instructions>
            <Divider />
            <FormWrapper formData={balancersConfig} disabled={readonly || balancersConfigLoading || saveLoading} onSubmit={handleSave} />
        </>
    )
}

export default Balancers
