import PromptCard from './PromptCard'
import { usePromptContext } from '../../context/PromptContext'
import { PromptSummary } from './types'
import { styled, Typography } from '@mui/material'

const SectionContainer = styled('div')({
    margin: '10px 0px',
})

const PromptCardContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 20,
}))

const SectionHeader = styled(Typography)({
    margin: '20px 0 8px 0',
    '&:first-of-type': {
        marginTop: '0px',
    },
})

interface PromptLibraryProps {
    prompts: PromptSummary[]
}

const PromptLibrary = ({ prompts }: PromptLibraryProps) => {
    const { onSelectPrompt } = usePromptContext()

    return (
        <SectionContainer>
            <SectionHeader variant='h3'>Favourites</SectionHeader>
            <PromptCardContainer>
                {prompts
                    .filter(prompt => prompt.isFavourite)
                    .map(prompt => (
                        <PromptCard key={prompt.id} prompt={prompt} onClick={() => onSelectPrompt(prompt.id)} />
                    ))}
            </PromptCardContainer>
            <SectionHeader variant='h3'>All Prompts</SectionHeader>
            <PromptCardContainer>
                {prompts.map(prompt => (
                    <PromptCard key={prompt.id} prompt={prompt} onClick={() => onSelectPrompt(prompt.id)} />
                ))}
            </PromptCardContainer>
        </SectionContainer>
    )
}

export default PromptLibrary
