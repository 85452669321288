import { Typography } from '@mui/material'
import { UUID } from '../../types'
import UnstyledButton from '../common/UnstyledButton'

interface ChatNotificationProps {
    text: string
    onClick?: () => void
    chatId?: UUID
}

const ChatNotification = ({ text, onClick }: ChatNotificationProps) =>
    onClick ? <UnstyledButton onClick={onClick}>{text}. Click here to go to the chat.</UnstyledButton> : <Typography>{text}</Typography>

export default ChatNotification
