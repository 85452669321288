import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { UpdateChatTitleParams } from '../apiTypes'
import { useChatContext } from '../context/ChatContext'
import { updateChatTitleEndpoint } from '../endpoints'
import useIsMobile from '../hooks/useIsMobile'
import { usePut } from '../hooks/usePut'
import AddIcon from '../icons/AddIcon'
import { placeholderChatTitle } from '../types'
import { EditableCard } from './Card'
import MobileCard from './MobileCard'
import Header from './common/Header'
import Loading from './common/Loading'
import TransparentButton from './common/TransparentButton'
import InfoTooltip from './common/tooltips/InfoTooltip'

const StyledChatCard = styled(EditableCard)`
    height: 100%;
`

const RHS = styled.section`
    display: flex;

    @media (max-width: 1000px) {
        column-gap: 5px;
    }
`

const StyledLoading = styled(Loading)`
    width: 14px;
    height: 14px;
`

const AddChat = () => {
    const { loading, createChat } = useChatContext()
    return (
        <InfoTooltip title='Start another session with this tool'>
            <TransparentButton aria-label='New session' onClick={() => createChat()} disabled={loading}>
                {loading ? (
                    <StyledLoading />
                ) : (
                    <>
                        <AddIcon />
                        New session
                    </>
                )}
            </TransparentButton>
        </InfoTooltip>
    )
}

interface ChatHeaderProps extends PropsWithChildren {
    className?: string
}

const ChatHeader = ({ className, children }: ChatHeaderProps) => {
    const { currentChat, updateChat } = useChatContext()
    const title = currentChat?.title ?? placeholderChatTitle(currentChat?.bot.toolType)
    const [updateChatTitle] = usePut<UpdateChatTitleParams>(currentChat ? updateChatTitleEndpoint(currentChat.id) : null)

    const isMobile = useIsMobile()

    const handleUpdateChatTitle = async (title: string) => {
        const result = await updateChatTitle({ title })

        if (result && currentChat) {
            updateChat(currentChat.id, { title })
        }
    }

    if (!currentChat) {
        return null
    }

    if (isMobile) {
        return (
            <Header className={className}>
                <MobileCard heading={title} subheading={currentChat.bot.name} botName={currentChat.bot.name} />
            </Header>
        )
    }

    return (
        <Header className={className}>
            <StyledChatCard heading={title} subheading={currentChat.bot.name} size='big' onNameSubmit={handleUpdateChatTitle} bot={currentChat.bot} showConfig>
                <RHS>
                    {children}
                    <AddChat />
                </RHS>
            </StyledChatCard>
        </Header>
    )
}

export default ChatHeader
