import { createContext, PropsWithChildren, useContext, useState } from 'react'
import { doNothing } from '../types'

interface DarkModeState {
    isDarkMode: boolean
    toggleDarkMode: () => void
}

const defaultState: DarkModeState = {
    isDarkMode: false,
    toggleDarkMode: doNothing,
}

const darkModeKey = 'darkmode'

export const DarkModeContext = createContext<DarkModeState>(defaultState)

const getDarkModePreference = () => {
    const osIsDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches

    try {
        const storedSetting = localStorage.getItem(darkModeKey)

        if (storedSetting) {
            return storedSetting === 'true'
        }

        localStorage.setItem(darkModeKey, osIsDarkMode.toString())
    } catch {
        // User likely has some crazy settings to disable local storage
    }

    return osIsDarkMode
}

const WithDarkModeContext = ({ children }: PropsWithChildren) => {
    const [isDarkMode, setIsDarkMode] = useState(getDarkModePreference())

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode)
        localStorage.setItem(darkModeKey, (!isDarkMode).toString())
    }

    return (
        <DarkModeContext.Provider
            value={{
                isDarkMode,
                toggleDarkMode,
            }}
        >
            {children}
        </DarkModeContext.Provider>
    )
}

const useDarkModeContext = () => useContext(DarkModeContext)

export { WithDarkModeContext, useDarkModeContext }
