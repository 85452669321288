import { SVGProps } from 'react'

const ConfigIcon = ({ color, ...rest }: SVGProps<SVGSVGElement>) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' height={19} width={19} viewBox='0 0 24 24' {...rest}>
        <path
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M3 8h12m0 0a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm-6 8h12M9 16a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
        />
    </svg>
)

export default ConfigIcon
