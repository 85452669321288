import styled, { css, useTheme } from 'styled-components'
import { useBotContext } from '../context/BotContext'
import { useChatContext } from '../context/ChatContext'
import { useUserContext } from '../context/UserContext'
import AddIcon from '../icons/AddIcon'
import DownIcon from '../icons/DownIcon'
import RetryIcon from '../icons/RetryIcon'
import BotList from './BotList'
import ChatList from './ChatList'
import SidebarFooter from './SidebarFooter'
import SidebarHeader from './SidebarHeader'
import IconButton from './common/IconButton'
import TransparentButton from './common/TransparentButton'
import { mediaQueries, sidebarTransitions, sidebarWidth, zIndexes } from './common/styles'
import InfoTooltip from './common/tooltips/InfoTooltip'

const Container = styled.nav<{ isSidebarOpen: boolean; showBotList: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: ${zIndexes.navigation};
    ${sidebarTransitions}
    width: ${props => (props.isSidebarOpen ? `${sidebarWidth}px` : '0px')};
    visibility: ${props => (props.isSidebarOpen ? 'visible' : 'hidden')};
    margin: 16px 12px 0 0;
    border-radius: 0 16px 0 0;
    background-color: ${props => (props.showBotList ? props.theme.colors.tertiary : props.theme.colors.background)};

    @media ${mediaQueries.mobile} {
        margin: 0;
        width: 100%;
    }
`

const SidebarContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    color: ${props => props.theme.colors.text};
    overflow: hidden;
    ${sidebarTransitions};

    @media ${mediaQueries.mobile} {
        border-radius: 0;
    }
`

const StyledSidebarHeader = styled(SidebarHeader)`
    height: 180px;
    ${sidebarTransitions}

    @media ${mediaQueries.laptopVertical} {
        height: 150px;
    }
`

const ListHeader = styled.section`
    display: flex;
    margin: 0px 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    ${sidebarTransitions}
`

const StyledIconButton = styled(IconButton)`
    background-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.textOnSecondary};
`

const listStyles = css`
    overflow: hidden auto;
`

const StyledChatList = styled(ChatList)`
    ${listStyles}
`

const StyledBotList = styled(BotList)`
    ${listStyles}
`

interface SidebarProps {
    className?: string
    isSidebarOpen: boolean
    showBotList: boolean
    onBotListToggle: (show: boolean) => void
}

const Sidebar = ({ className, isSidebarOpen, showBotList, onBotListToggle }: SidebarProps) => {
    const { loading, refreshChats } = useChatContext()
    const {
        colors: { textOnSecondary },
    } = useTheme()
    const { editBotConfig } = useBotContext()
    const { isMaintainer } = useUserContext()

    return (
        <Container className={className} isSidebarOpen={isSidebarOpen} showBotList={showBotList}>
            <SidebarContent>
                <StyledSidebarHeader />
                {showBotList ? (
                    <>
                        <ListHeader>
                            <StyledIconButton label='Select tool' onClick={() => onBotListToggle(false)}>
                                <DownIcon color={textOnSecondary} />
                            </StyledIconButton>
                            {isMaintainer && (
                                <StyledIconButton label='Create tool' onClick={() => editBotConfig('new')}>
                                    <AddIcon color={textOnSecondary} />
                                </StyledIconButton>
                            )}
                        </ListHeader>
                        <StyledBotList />
                    </>
                ) : (
                    <>
                        <ListHeader>
                            <InfoTooltip title='Pick a tool and start a new session'>
                                <StyledIconButton label='New session' onClick={() => onBotListToggle(true)}>
                                    <AddIcon color={textOnSecondary} />
                                </StyledIconButton>
                            </InfoTooltip>

                            <InfoTooltip title='Refresh the list of recent sessions'>
                                <TransparentButton aria-label='Refresh chats' onClick={refreshChats} disabled={loading}>
                                    <RetryIcon width={24} height={18} />
                                </TransparentButton>
                            </InfoTooltip>
                        </ListHeader>
                        <StyledChatList />
                    </>
                )}
            </SidebarContent>
            <SidebarFooter />
        </Container>
    )
}

export default Sidebar
