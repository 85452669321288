import { SVGProps } from 'react'
import { useTheme } from 'styled-components'

const WaitIcon = ({ color, ...rest }: SVGProps<SVGSVGElement>) => {
    const { colors } = useTheme()

    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' {...rest}>
            <path
                stroke={color ?? colors.text}
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={1.063}
                d='M4.835 15.406h7.33c.658 0 1.18-.54 1.11-1.164-.457-4.015-3.181-3.949-3.181-5.742 0-1.793 2.76-1.693 3.18-5.742.067-.624-.451-1.164-1.109-1.164h-7.33c-.658 0-1.175.54-1.11 1.164.422 4.049 3.181 3.916 3.181 5.742s-2.724 1.727-3.18 5.742c-.072.623.451 1.164 1.109 1.164Z'
            />
            <path
                fill={color ?? colors.text}
                d='M11.399 14.344H5.616c-.518 0-.664-.598-.301-.968.879-.892 2.654-1.53 2.654-2.552V7.438c0-.66-1.262-1.163-2.043-2.232-.128-.176-.115-.425.212-.425h4.739c.28 0 .34.247.212.424-.769 1.07-2.058 1.57-2.058 2.232v3.387c0 1.014 1.85 1.56 2.67 2.553.33.4.215.967-.302.967Z'
            />
        </svg>
    )
}

export default WaitIcon
