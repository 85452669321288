import { adminUsersEndpoint, botUsersEndpoint, removeBotUserEndpoint } from '../../endpoints'
import { useGet } from '../../hooks/useGet'
import { BotStatus, User, UUID } from '../../types'
import Loading from '../common/Loading'
import Instructions from './Instructions'
import { UserBotPermissionsResponse } from '../../apiTypes'
import CrossIcon from '../../icons/CrossIcon'
import TransparentButton from '../common/TransparentButton'
import { useDelete } from '../../hooks/useDelete'
import { useUserContext } from '../../context/UserContext'
import AddUsers from './AddUsers'
import { toast } from 'react-toastify'
import { styled, Typography } from '@mui/material'
import InfoTooltip from '../common/tooltips/InfoTooltip'

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '60%',
    marginTop: '12px',
    alignSelf: 'center',
})

const HorizontalContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '6px',
    alignItems: 'center',
})

export const MinorHeading = styled(Typography)({
    margin: '6px 0px 6px 0px',
})

export const ListItem = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: '24px',
    padding: '8px 16px',

    '&:first-child': {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    '&:last-child': {
        borderBottom: 'none',
    },
    '&:hover .removeIcon': {
        visibility: 'visible',
    },
}))

const StyledIconButton = styled(TransparentButton)({
    visibility: 'hidden',
    svg: {
        maxHeight: '16px',
        maxWidth: '16px',
    },
})

export const ListWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '150px',
    overflowY: 'auto',
})

interface ShareBotProps {
    botId: UUID
    botStatus: BotStatus
}

const ShareBot = ({ botId, botStatus }: ShareBotProps) => {
    const { userEmail } = useUserContext()

    const [allUsers, loadingAllUsers, refreshAllUsers] = useGet<User[]>(adminUsersEndpoint)
    const [botUsers, loadingBotUsers, refreshBotUsers] = useGet<UserBotPermissionsResponse>(botUsersEndpoint(botId))
    const [removeUser, loadingRemoveUser] = useDelete(removeBotUserEndpoint(botId))

    if (!botUsers || !allUsers) {
        return <Loading fullSize secondaryColor />
    }

    if (botUsers.hasTenantPermission) {
        return <Instructions>This tool is shared with all users.</Instructions>
    }

    const refresh = () => {
        refreshAllUsers()
        refreshBotUsers()
    }

    const handleRemoveUser = async (user: User) => {
        const removed = await removeUser(user.id)
        if (removed) {
            refresh()
        } else {
            toast.error('Failed to remove user from this tool')
        }
    }

    const currentBotUsers = allUsers.filter(user => botUsers.userPermissions.includes(user.id))
    const createdBy = allUsers.find(user => user.id === botUsers.createdById)

    return (
        <>
            <Instructions>
                Below are the users who currently have access to this tool. You can add or remove users to test out your customised tools. Note you cannot
                add/remove yourself or the tool creator.{' '}
            </Instructions>
            {botStatus === 'preview' && <Instructions>You can only share this tool with up to 10 users whilst it is in preview mode.</Instructions>}
            <Container>
                <HorizontalContainer>
                    <MinorHeading>Tool Creator: </MinorHeading>
                    <Typography>{createdBy?.name ?? 'Administrator'}</Typography>
                </HorizontalContainer>
                <MinorHeading variant='h6'>Shared With:</MinorHeading>
                <ListWrapper>
                    {currentBotUsers.map(user => (
                        <ListItem key={user.id}>
                            {user.name} - {user.email}
                            {userEmail !== user.email && (
                                <InfoTooltip title='Remove User'>
                                    <StyledIconButton
                                        className='removeIcon'
                                        aria-label='Remove User Button'
                                        onClick={() => handleRemoveUser(user)}
                                        disabled={loadingRemoveUser}
                                    >
                                        <CrossIcon />
                                    </StyledIconButton>
                                </InfoTooltip>
                            )}
                        </ListItem>
                    ))}
                </ListWrapper>
                <AddUsers allUsers={allUsers} currentBotUsers={currentBotUsers} onSuccess={refresh} botId={botId} botStatus={botStatus} />
                {(loadingRemoveUser || loadingAllUsers || loadingBotUsers) && <Loading fullSize={false} secondaryColor />}
            </Container>
        </>
    )
}

export default ShareBot
