import { useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { ReportFieldsListPayload, ReportFieldsListResponse, WarningResponse } from '../../apiTypes'
import { botFieldsEndpoint } from '../../endpoints'
import { useGet } from '../../hooks/useGet'
import { usePost } from '../../hooks/usePost'
import { UUID } from '../../types'
import { configTabErrors } from '../../utils/userMessages'
import ErrorCard from '../common/ErrorCard'
import Loading from '../common/Loading'
import SortableList from '../common/SortableList'
import FieldDetails from './FieldDetails'
import Instructions from './Instructions'
import NewField from './NewField'
import SortableField from './SortableField'

const StyledLoading = styled(Loading)`
    height: 300px;
`

interface ReportFieldsProps {
    botId: UUID
    isLeaseReport: boolean
    readonly: boolean
}

const ReportFields = ({ botId, isLeaseReport, readonly }: ReportFieldsProps) => {
    const [editFieldId, setEditFieldId] = useState<UUID | 'new' | null>(null)

    const endpoint = botFieldsEndpoint(botId)
    const [fields, fieldsLoading, refresh] = useGet<ReportFieldsListResponse>(endpoint)
    const [saveFields, saveLoading] = usePost<ReportFieldsListPayload, WarningResponse>(endpoint)

    const handleSave = async (orderedFieldIds: UUID[], deletedFieldIds: UUID[]) => {
        const response = await saveFields({ orderedFieldIds, deletedFieldIds })

        if (response) {
            if (response.warning) {
                toast.warning(response.warning)
            } else {
                toast.success('Fields have been updated')
            }
            refresh()
        }
    }

    const handleFieldUpdated = () => {
        setEditFieldId(null)
        refresh()
    }

    if (editFieldId) {
        return (
            <>
                {editFieldId === 'new' ? (
                    <NewField botId={botId} onUpdate={handleFieldUpdated} onCancel={() => setEditFieldId(null)} />
                ) : (
                    <FieldDetails botId={botId} fieldId={editFieldId} readonly={readonly} onUpdate={handleFieldUpdated} onCancel={() => setEditFieldId(null)} />
                )}
            </>
        )
    }

    return (
        <>
            {readonly && <ErrorCard error={configTabErrors} />}
            <Instructions>
                The report generator bot will extract a list of fields from the document uploaded by the user based on the below. Each field is extracted
                independently by reading the entire document and attempting to answer the configured question. The report can then be constructed from a
                template by inserting the extracted field values into placeholders.
            </Instructions>
            {fields ? (
                <SortableList
                    items={fields.map(f => ({ ...f, label: f.name }))}
                    disabled={readonly || fieldsLoading || saveLoading}
                    onSave={handleSave}
                    onAdd={() => setEditFieldId('new')}
                    ItemComponent={props => (
                        <SortableField {...props} isLeaseReport={isLeaseReport} tags={fields.find(f => f.id === props.id)?.tags} onEdit={setEditFieldId} />
                    )}
                />
            ) : (
                <StyledLoading secondaryColor fullSize />
            )}
        </>
    )
}

export default ReportFields
