import { useFileContext } from '../context/FileContext'
import { useChatContext } from '../context/ChatContext'
import { convertToFileList } from '../utils/documents'
import { ConnectDropTarget, useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'

export const useDragAndDrop = (folderOverride?: string): [ConnectDropTarget, boolean] => {
    const { currentChat } = useChatContext()
    const { onAttach } = useFileContext()

    const [dragging, drop] = useDrop<{ dataTransfer: DataTransfer }, unknown, boolean>(
        {
            accept: [NativeTypes.FILE],
            drop: async ({ dataTransfer }) => {
                if (dataTransfer.items) {
                    const files = await convertToFileList(dataTransfer.items)
                    onAttach(files, folderOverride)
                }
            },
            canDrop: () => !!currentChat?.bot.supportsDocumentUpload,
            collect: monitor => monitor.canDrop(),
        },
        [folderOverride, currentChat?.bot.supportsDocumentUpload, onAttach]
    )

    return [drop, dragging]
}
