import { overflowText } from './common/styles'
import { stringToColour } from '../utils/colours'
import { styled, Typography } from '@mui/material'

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
})

const InnerContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
})

const StyledBotIcon = styled('div')<{ botName: string }>(({ theme, botName }) => ({
    justifySelf: 'center',
    alignSelf: 'center',
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    backgroundColor: stringToColour(botName, theme.palette.mode === 'dark'),
}))

const InfoContainer = styled('div')({
    width: '75%',
})

const Name = styled(Typography)({
    margin: 0,
    ...overflowText,
})

const Description = styled(Typography)({
    ...overflowText,
})

interface MobileCardProps {
    className?: string
    heading: string
    subheading: string
    botName: string
}

const MobileCard = ({ className, heading, subheading, botName }: MobileCardProps) => (
    <Container className={className}>
        <InnerContainer>
            <StyledBotIcon botName={botName} />
            <InfoContainer>
                <Name variant='h1'>{heading}</Name>
                <Description variant='h5'>{subheading}</Description>
            </InfoContainer>
        </InnerContainer>
    </Container>
)

export default MobileCard
