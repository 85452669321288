import { SVGProps } from 'react'

const StarIcon = ({ color, ...rest }: SVGProps<SVGSVGElement>) => (
    <svg width='14' height='14' viewBox='0.3 0 14 14' fill='none' stroke={color ? color : 'currentColor'} xmlns='http://www.w3.org/2000/svg' {...rest}>
        <path
            d='M6.55045 10.7039L6.88138 11.377L6.55045 10.7039L3.99027 11.9628C3.80989 12.0515 3.60389 11.9018 3.63251 11.7028L4.03861 8.87897C4.11585 8.34186 3.93956 7.79931 3.56137 7.41018L1.573 5.36432C1.4329 5.22017 1.51158 4.97801 1.70965 4.94374L4.52081 4.45734C5.0555 4.36483 5.51702 4.02951 5.77023 3.54958L7.10152 1.02632C7.19532 0.848533 7.44994 0.848531 7.54374 1.02632L8.87503 3.54958C9.12825 4.02951 9.58977 4.36483 10.1245 4.45734L12.9356 4.94374C13.1337 4.97801 13.2124 5.22017 13.0723 5.36432L11.0839 7.41018C10.7057 7.79931 10.5294 8.34186 10.6067 8.87897L11.0128 11.7028C11.0414 11.9018 10.8354 12.0515 10.655 11.9628L8.09482 10.7039C7.60787 10.4645 7.0374 10.4645 6.55045 10.7039Z'
            strokeWidth='1.5'
        />
    </svg>
)

export default StarIcon
