import { SortOptions } from './PromptLibraries'
import { PromptDetail, PromptMessage, PromptSummaryResponse, TemplatePromptDetail } from './types'

export const maxNumberOfTags = 10
export const maxTagCharacterLength = 20

export const sortPrompts = (prompts: PromptSummaryResponse[], selectedSort: SortOptions) => {
    switch (selectedSort) {
        case 'Alphabetical':
            return prompts.sort((a, b) => a.name.localeCompare(b.name))
        case 'Most Votes':
            return prompts.sort((a, b) => b.totalVotes - a.totalVotes)
        case 'Newest First':
            return prompts.reverse()
        default:
            // we expect prompts by default to come in the order they were created, so oldest first.
            return prompts
    }
}

const key: keyof TemplatePromptDetail = 'variables'

// BE returns empty variables array for simple prompt
export const isTemplatePrompt = (prompt: PromptDetail): prompt is TemplatePromptDetail => key in prompt && prompt.variables.length > 0

export const createDisplayedPrompt = (messageList: PromptMessage[]) => {
    return messageList
        .sort((a, b) => a.order - b.order) // Messages have an order, so make sure they are in the correct order first before joining.
        .map(message => message.content)
        .join('\n\n')
}
