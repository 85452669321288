import Button from './Button'
import { HTMLProps } from 'react'
import { overflowText } from './styles'
import { styled, Typography } from '@mui/material'

const Container = styled(Button)({
    display: 'flex',
    alignItems: 'center',
})

const Label = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    margin: '0 0 0 16px',
    fontWeight: 600,
    ...overflowText,
}))

interface IconButtonProps extends HTMLProps<HTMLButtonElement> {
    className?: string
    label: string
}

const IconButton = ({ className, label, children, ...rest }: IconButtonProps) => (
    // @ts-ignore I think this is an issue caused by Button being a styled component
    <Container className={className} {...rest}>
        {children}
        <Label>{label}</Label>
    </Container>
)

export default IconButton
