import { styled, Typography } from '@mui/material'
import { PropsWithChildren } from 'react'

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
})

const Label = styled(Typography)({
    marginBottom: '10px',
})

const Description = styled(Typography)({
    marginBottom: '10px',
})

interface WithLabelsProps extends PropsWithChildren {
    className?: string
    label: string
    description?: string
    required?: boolean
}

const WithLabels = ({ className, label, description, required, children }: WithLabelsProps) => (
    <Container className={className}>
        <Label>{`${label}${required ? '*' : ''}`}</Label>
        {description && <Description variant='subtitle1'>{description}</Description>}
        {children}
    </Container>
)

export default WithLabels
