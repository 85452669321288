import styled from 'styled-components'
import Loading from '../common/Loading'
import Breadcrumb from '../common/Breadcrumb'
import PromptMetaButtons from './PromptMetaButtons'
import { usePromptContext } from '../../context/PromptContext'
import MarkdownMessage from '../common/MarkdownMessage'
import FormWrapper from '../forms/FormWrapper'
import { JSONFormPayload, JSONFormResponse } from '../../apiTypes'
import { default as FormType } from '@rjsf/core'
import { usePost } from '../../hooks/usePost'
import { promptPreviewTemplateEndpoint, promptRenderTemplateEndpoint } from '../../endpoints'
import { UUID } from '../../types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { scrollBarStyle } from '../common/styles'
import { HeaderRow, MessageContainer, PromptDetailWrapper, StyledMessageBlob, SubmissionContainer, UsePromptButton } from './SimplePromptDetail'
import { createDisplayedPrompt, isTemplatePrompt } from './promptUtils'
import { Typography } from '@mui/material'

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
`

const StyledFormWrapper = styled(FormWrapper)`
    margin-right: 20px;
    ${scrollBarStyle}
`

interface TemplatePromptDetailProps {
    onUsePrompt: (promptMessage: string) => void
}

const TemplatePromptDetail = ({ onUsePrompt }: TemplatePromptDetailProps) => {
    const { prompt, onSelectPrompt } = usePromptContext()

    const [displayedPrompt, setDisplayedPrompt] = useState<string | null>(prompt ? createDisplayedPrompt(prompt.messageList) : null)
    const [mostRecentForm, setMostRecentForm] = useState<JSONFormPayload>({})
    const formRef = useRef<FormType>(null)

    const [filledPreviewMessage, loadingPreview] = usePost<JSONFormPayload, string, UUID>(
        prompt ? promptPreviewTemplateEndpoint(prompt.libraryId, prompt.id) : null
    )
    const [filledRenderMessage] = usePost<JSONFormPayload, string, UUID>(prompt ? promptRenderTemplateEndpoint(prompt.libraryId, prompt.id) : null)

    const updateMessagePreview = useCallback(
        async (filledForm: JSONFormPayload) => {
            const response = await filledPreviewMessage(filledForm)

            // the below method removes chars like: &#39; for apostrophes
            const decodeHtml = (html: string) => {
                const txt = document.createElement('textarea')
                txt.innerHTML = html
                return txt.value
            }

            if (response) {
                setDisplayedPrompt(decodeHtml(response))
            }
        },
        [filledPreviewMessage]
    )

    useEffect(() => {
        const previewHandler = setTimeout(() => updateMessagePreview(mostRecentForm), 3000)

        return () => clearTimeout(previewHandler)
    }, [mostRecentForm, updateMessagePreview])

    const handleUsePrompt = async () => {
        const response = await filledRenderMessage(mostRecentForm)
        if (response) {
            onUsePrompt(response)
        }
    }

    if (!prompt) {
        return <Loading fullSize={true} secondaryColor={true} />
    }

    const formDataSchema: JSONFormResponse = {
        schema: isTemplatePrompt(prompt) ? prompt.variableSchema : {},
        uiSchema: {},
        values: {},
    }

    return (
        <>
            <Breadcrumb onClick={() => onSelectPrompt(null)} label='Back to Library' />
            <PromptDetailWrapper>
                <Typography variant='h2'>{prompt.name}</Typography>
                <HeaderRow>
                    <Typography>{prompt.description}</Typography>
                    <PromptMetaButtons prompt={prompt} />
                </HeaderRow>
                <DetailsContainer>
                    <div>
                        <Typography variant='h3'>Customise Prompt</Typography>
                        <StyledFormWrapper formData={formDataSchema} disabled={false} ref={formRef} onSubmit={handleUsePrompt} onChange={setMostRecentForm} />
                    </div>
                    <MessageContainer>
                        <Typography variant='h3'>Your Prompt</Typography>
                        <StyledMessageBlob userMessage={true}>
                            <MarkdownMessage>{displayedPrompt}</MarkdownMessage>
                        </StyledMessageBlob>
                        <SubmissionContainer>
                            <UsePromptButton onClick={() => formRef.current?.submit()} title='Use Prompt' type='submit' aria-label='use prompt button'>
                                Use Prompt
                            </UsePromptButton>
                            {loadingPreview && <Loading fullSize={false} secondaryColor />}
                        </SubmissionContainer>
                    </MessageContainer>
                </DetailsContainer>
            </PromptDetailWrapper>
        </>
    )
}

export default TemplatePromptDetail
