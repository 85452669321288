import { useState } from 'react'
import { useAuthHeaders } from './useAuthHeaders'
import { captureException, captureMessage } from '@sentry/react'

export const usePut = <TQueryParams extends string[][] | Record<string, string> | string | URLSearchParams | null, TBody = Record<string, unknown>>(
    url: string | null
): [makeRequest: (queryParams: TQueryParams, body?: TBody) => Promise<boolean>, loading: boolean] => {
    const [loading, setLoading] = useState(false)
    const generateHeaders = useAuthHeaders()

    const makeRequest = async (queryParams: TQueryParams, body?: TBody) => {
        if (!url) {
            return false
        }
        setLoading(true)

        try {
            const headers = await generateHeaders()
            headers.append('Content-Type', 'application/json')

            const parameters = queryParams ? `?${new URLSearchParams(queryParams)}` : ''

            const response = await fetch(`${url}${parameters}`, {
                method: 'PUT',
                headers,
                body: JSON.stringify(body),
            })

            if (response.ok) {
                setLoading(false)
                return true
            }
            captureMessage(`${response.status}: ${response.statusText}`)
            const error = await response.text()
            captureMessage(error)
        } catch (error) {
            captureException(error)
        }

        setLoading(false)
        return false
    }

    return [makeRequest, loading]
}
