import styled from 'styled-components'
import Button from '../common/Button'

const BotConfigButton = styled(Button)`
    text-align: center;
    margin-left: 10px;
    align-self: end;
`

export default BotConfigButton
