import { useState } from 'react'
import { BotStatus, User, UUID } from '../../types'
import useDebounce from '../../hooks/useDebounce'
import Input from '../common/Input'
import { ListWrapper, MinorHeading } from './ShareBot'
import AddIcon from '../../icons/AddIcon'
import { usePost } from '../../hooks/usePost'
import { botUsersEndpoint } from '../../endpoints'
import { ListItemButton, styled, Typography } from '@mui/material'
import InfoTooltip from '../common/tooltips/InfoTooltip'

const StyledListButton = styled(ListItemButton)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '10px',
    height: '40px',
    overflow: 'hidden',
    '.addIcon': {
        opacity: 0,
        transition: 'opacity 0.2s',
    },
    '&:hover .addIcon': {
        opacity: 1,
    },
}))

const InfoContainer = styled('div')(() => ({
    maxWidth: '95%',
}))

const SubText = styled(Typography)(() => ({
    textAlign: 'center',
    marginTop: '12px',
}))

const InfoText = styled(Typography)(() => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}))

interface AddUsersProps {
    botId: UUID
    botStatus: BotStatus
    allUsers: User[]
    currentBotUsers: User[]
    onSuccess: () => void
}

const maxNumberOfPreviewToolUsers = 10

const AddUsers = ({ botId, botStatus, allUsers, currentBotUsers, onSuccess }: AddUsersProps) => {
    const [searchTerm, setSearchTerm] = useState('')
    const debouncedSearchTerm = useDebounce(searchTerm, 100)

    const [addUser, loadingAddUser] = usePost<{ users: UUID[] }, boolean>(botUsersEndpoint(botId))

    const handleAddUser = async (user: User) => {
        const response = await addUser({ users: [user.id] })
        if (response) {
            onSuccess()
            setSearchTerm('')
        }
    }

    const allOtherUsers =
        debouncedSearchTerm === ''
            ? []
            : allUsers?.filter(
                  user =>
                      !currentBotUsers.some(botUser => botUser.id === user.id) &&
                      (user.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
                          user.email.toLowerCase().includes(debouncedSearchTerm.toLowerCase()))
              ) ?? []

    return (
        <>
            {botStatus === 'preview' && currentBotUsers.length >= maxNumberOfPreviewToolUsers ? (
                <SubText>
                    Preview tools can only be shared with up to {maxNumberOfPreviewToolUsers} users. Please consider making the tool live if it is sufficiently
                    tested, or remove a user to add someone different.
                </SubText>
            ) : (
                <>
                    <MinorHeading>Add User:</MinorHeading>
                    <Input
                        placeholder="Search for a user's name or email address"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        aria-label='Search Users'
                    ></Input>
                    {allOtherUsers.length === 0 && debouncedSearchTerm !== '' && searchTerm !== '' && (
                        <>
                            <SubText>No users found matching this name or email.</SubText>
                            <SubText>
                                Please ensure the user has previously signed in. This will activate their account and allow you to add them to this tool.
                            </SubText>
                        </>
                    )}
                    <ListWrapper>
                        {allOtherUsers.map(user => (
                            <InfoTooltip key={user.id} title='Add User'>
                                <StyledListButton aria-label='Add User Button' onClick={() => handleAddUser(user)} disabled={loadingAddUser}>
                                    <InfoContainer>
                                        <InfoText>{user.name}</InfoText>
                                        <InfoText>{user.email}</InfoText>
                                    </InfoContainer>
                                    <AddIcon className='addIcon' />
                                </StyledListButton>
                            </InfoTooltip>
                        ))}
                    </ListWrapper>
                </>
            )}
        </>
    )
}

export default AddUsers
