import styled from 'styled-components'
import UnstyledButton from './UnstyledButton'
import { highlightOnHover } from './styles'

const Button = styled(UnstyledButton)`
    background-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.textOnSecondary};
    padding: 10px 16px;
    border-radius: 8px;
    transition: all 150ms ease-out;
    ${props => highlightOnHover(props.theme.colors.secondary)}

    :disabled {
        background-color: ${props => props.theme.colors.disabledBackground};
        color: ${props => props.theme.colors.disabled};
        cursor: unset;
    }
`

export default Button
