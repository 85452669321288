import styled, { css } from 'styled-components'
import { Document, UUID, isDownloadableState, isLoadingState } from '../types'
import Chip from './common/Chip'
import { useDocumentDownload } from '../hooks/useDocumentDownload'

const StyledChip = styled(Chip)<{ error: boolean }>`
    &&& {
        min-width: unset;
        max-width: 100%;
    }

    & ~ * {
        margin-top: 10px;
    }

    ${({ error }) =>
        error &&
        css`
            text-decoration: line-through;
        `}
`

interface DocumentChipProps {
    className?: string
    chatId: UUID
    document: Document
}

const DocumentChip = ({ className, chatId, document }: DocumentChipProps) => {
    const { id, state, name } = document

    const [download, debouncedLoading] = useDocumentDownload(chatId, id, state)

    return (
        <StyledChip
            className={className}
            key={id}
            label={name}
            title='download document'
            onClick={isDownloadableState(state) ? download : undefined}
            loading={isLoadingState(state) || debouncedLoading}
            error={state === 'error'}
        />
    )
}

export default DocumentChip
