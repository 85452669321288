import { FC, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { UUID } from '../../types'

interface DragItem {
    id: UUID
    index: number
}

export interface Item {
    id: UUID
    label: string
    toDelete?: boolean
}

export interface SortableItemProps extends Item {
    isDragging: boolean
    disabled: boolean
    listIsDirty: boolean
    onDeleteItem: () => void
    onRestoreItem: () => void
}

export type SortableItem = FC<SortableItemProps>

interface SortableItemWrapperProps {
    item: Item
    ItemComponent: SortableItem
    index: number
    disabled: boolean
    listIsDirty: boolean
    onMoveItem: (dragIndex: number, hoverIndex: number) => void
    onDeleteItem: () => void
    onRestoreItem: () => void
}

const SortableItemWrapper = ({ item, ItemComponent, index, disabled, onMoveItem, ...rest }: SortableItemWrapperProps) => {
    const ref = useRef<HTMLDivElement>(null)

    const [, drop] = useDrop<DragItem>({
        accept: 'dragItem',
        hover: (item, monitor) => {
            if (!ref.current) {
                return
            }

            const dragIndex = item.index
            const hoverIndex = index

            if (dragIndex === hoverIndex) {
                return
            }

            const { top, bottom } = ref.current.getBoundingClientRect()
            const itemMiddleY = (bottom - top) / 2

            const mousePosition = monitor.getClientOffset()
            if (!mousePosition) {
                return
            }
            const mouseDeltaToTopOfItem = mousePosition.y - top

            // Dragging down and not yet passed half way
            if (dragIndex < hoverIndex && mouseDeltaToTopOfItem < itemMiddleY) {
                return
            }
            // Dragging up and not yet passed half way
            if (dragIndex > hoverIndex && mouseDeltaToTopOfItem > itemMiddleY) {
                return
            }

            onMoveItem(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag<DragItem, unknown, { isDragging: boolean }>({
        type: 'dragItem',
        canDrag: !disabled,
        item: { id: item.id, index },
        collect: monitor => ({ isDragging: monitor.isDragging() }),
    })

    drag(drop(ref))

    return (
        <div ref={ref}>
            <ItemComponent {...item} isDragging={isDragging} disabled={disabled} {...rest} />
        </div>
    )
}

export default SortableItemWrapper
