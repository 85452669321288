import { ObjectWithId, UUID } from '../types'

/**
 * Deep copy an object or array, AS LONG AS all attributes are JSON serializable/primitive types (int,bool,str,array,obj) and contain no circular references.
 * Use structuredClone for more complex objects
 */
export const jsonClone = <T extends Object>(obj: T): T => JSON.parse(JSON.stringify(obj))

// Casting UUID here as they are the same template literal but TS is unsure
export const generateId = () => crypto.randomUUID() as UUID

// Using .some twice is O(m*n), using set is O(m+n)
export const containsAnyMatchingId = <T extends ObjectWithId>(sourceObjects: T[], targetObjects: T[]) => {
    const sourceIds = new Set(sourceObjects.map(o => o.id))
    return targetObjects.some(o => sourceIds.has(o.id))
}

/**
 * Will return false if targetObjects is empty as the merge would do nothing and likely cause constant state updates
 */
export const canMergeWithoutIdConflicts = <T extends ObjectWithId>(sourceObjects: T[], targetObjects: T[]) => {
    if (targetObjects.length < 1) {
        return false
    }

    if (sourceObjects.length < 1) {
        return true
    }

    return !containsAnyMatchingId(sourceObjects, targetObjects)
}

/**
 * Compares two arrays of objects.
 * Checks length, order and all keys of the objects
 */
export const areEqual = <T extends object>(a: T[], b: T[]) =>
    a.length === b.length &&
    a.every((element, index) => {
        const keys = Object.keys(element) as unknown as Array<keyof T>

        return keys.length === Object.keys(b[index]).length && keys.every(key => b[index][key] === element[key])
    })
