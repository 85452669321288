import React from 'react'
import MarkdownMessage from './common/MarkdownMessage'
import styled from 'styled-components'

interface CollapsedMessageProps {
    message: string
}

const StyledSummary = styled.summary`
    // all children elements, this prevents the summary text from appearing on a new line
    & > * {
        display: inline-block;
        padding-left: 10px;
    }
`

const CollapsedMessage = ({ message }: CollapsedMessageProps) => {
    const lines = message.split('\n')
    const summaryCutoff = 50
    const cutoffIndex = message.lastIndexOf(' ', summaryCutoff)
    const summary = lines.length > 1 && lines[0].length < cutoffIndex ? lines[0] : `${message.slice(0, cutoffIndex)}...`
    const details = lines.length > 1 && lines[0].length < cutoffIndex ? lines.slice(1).join('\n') : message.slice(cutoffIndex)

    return (
        <details>
            <StyledSummary>
                <MarkdownMessage>{summary}</MarkdownMessage>
            </StyledSummary>
            <MarkdownMessage>{details}</MarkdownMessage>
        </details>
    )
}

export default CollapsedMessage
