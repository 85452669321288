import { Select, MenuItem, ListItemText, SelectChangeEvent, styled } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import Checkbox from './Checkbox'
import { SelectInputProps } from '@mui/material/Select/SelectInput'
import { forwardPropsWithTransient } from '../../utils/muiUtils'
import { isStringArray } from '../../types'

interface StyledSelectProps {
    $compact?: boolean
    $typography?: Variant
}

const StyledSelect = styled(Select, {
    shouldForwardProp: forwardPropsWithTransient,
})<StyledSelectProps>(({ theme, $compact, $typography }) => ({
    height: $compact ? 28 : 36,
    '& .MuiSvgIcon-root': {
        width: $compact ? 16 : 22,
    },
    '& .MuiInputBase-input': theme.typography[$typography || 'body1'],
}))

const StyledListText = styled(ListItemText, {
    shouldForwardProp: forwardPropsWithTransient,
})<StyledSelectProps>(({ theme, $typography }) => ({
    margin: 0,
    '& .MuiTypography-root': theme.typography[$typography || 'body1'],
}))

const StyledCheckbox = styled(Checkbox)(() => ({
    marginRight: 8,
}))

interface BaseDropdownProps extends Omit<SelectInputProps, 'multiple' | 'autoWidth' | 'native'> {
    options: Set<string>
    placeholder?: string
    multiple?: boolean
    compact?: boolean
    typography?: Variant
}

interface SingleDropDownProps extends BaseDropdownProps {
    selected: string
    onSelectionChange: (selection: string) => void
    multiple?: false
}

interface MultiDropDownProps extends BaseDropdownProps {
    selected: string[]
    onSelectionChange: (selection: string[]) => void
    multiple: true
}

type DropdownProps = SingleDropDownProps | MultiDropDownProps

const Dropdown = ({ options, selected, onSelectionChange, multiple, compact, placeholder = 'Select', typography, ...rest }: DropdownProps) => {
    //Mui might have a bug where inference is wrong, so we take unknown here
    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value

        if (multiple && isStringArray(value)) {
            onSelectionChange(value)
        } else if (!multiple && typeof value === 'string') {
            onSelectionChange(value)
        }
    }

    return (
        <StyledSelect
            aria-label='dropdown menu'
            role='button'
            displayEmpty
            renderValue={() => (multiple ? placeholder : selected)}
            value={selected ?? ''}
            onChange={handleChange}
            label={placeholder}
            multiple={multiple}
            $compact={compact}
            $typography={typography}
            {...rest}
        >
            {options.size > 0 ? (
                Array.from(options).map(option => {
                    const isSelected = Array.isArray(selected) ? selected.includes(option) : selected === option

                    return (
                        <MenuItem key={option} value={option} role='option'>
                            {multiple && <StyledCheckbox checked={isSelected} />}
                            <StyledListText primary={option} $typography={typography} />
                        </MenuItem>
                    )
                })
            ) : (
                <MenuItem disabled>No options available</MenuItem>
            )}
        </StyledSelect>
    )
}

export default Dropdown
