import styled from 'styled-components'
import FileIcon from '../../icons/FileIcon'

const Icon = styled(FileIcon)`
    margin-right: 8px;
    vertical-align: bottom;
`

interface FileNameProps {
    name: string
}

const FileName = ({ name }: FileNameProps) => (
    <>
        <Icon width={16} height={20} />
        {name}
    </>
)

export default FileName
