import { HTMLAttributes, useState } from 'react'
import { TextField, Typography, useTheme, styled } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'

const Container = styled('div')(() => ({
    position: 'relative',
}))

const EditableBox = styled('div')(({ theme, 'aria-disabled': disabled }) => ({
    position: 'relative',
    padding: '0 2px',
    maxWidth: '100%',
    width: 'fit-content',

    '&:hover': {
        backgroundColor: disabled ? 'transparent' : theme.palette.secondary.dark,
    },
}))

const StyledTypography = styled(Typography)({
    margin: 0,
    paddingBottom: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const StyledTextField = styled(TextField)(({ multiline }) => ({
    width: '100%',
    minHeight: multiline ? '120px' : 'auto',

    '& .MuiInputBase-root, .MuiInputBase-input': {
        padding: '0px 2px',
    },
}))

interface EditableProps extends HTMLAttributes<HTMLDivElement> {
    label: string
    onLabelSubmit: (label: string) => void
    typographyVariant?: Variant
    multiline?: boolean
    disabled?: boolean
    initiallyEditable?: boolean
    placeholder?: string
    type?: 'text' | 'numeric'
    textFormatter?: (t: string) => string
    controlledLabel?: boolean
}

const EditablePage = ({
    label: initialLabel,
    onLabelSubmit,
    typographyVariant = 'body1',
    multiline = false,
    disabled,
    initiallyEditable = false,
    placeholder,
    type = 'text',
    textFormatter = t => t,
    controlledLabel = false,
    ...rest
}: EditableProps) => {
    const [editing, setEditing] = useState(initiallyEditable)
    const [label, setLabel] = useState(initialLabel)
    const theme = useTheme()
    const typographyStyle = theme.typography[typographyVariant]

    const handleChange = (value: string) => {
        if (type === 'numeric') {
            value = value.replace(/[^0-9.]/g, '')
        }
        setLabel(value)
    }

    const handleSubmit = () => {
        onLabelSubmit(label)
        setEditing(false)
        controlledLabel && setLabel(initialLabel)
    }

    return (
        <Container {...rest}>
            {editing ? (
                <StyledTextField
                    variant={multiline ? 'outlined' : 'standard'}
                    autoFocus
                    fullWidth
                    minRows={multiline ? 5 : undefined}
                    value={label}
                    onFocus={({ currentTarget }) => {
                        if (currentTarget.type !== 'number') {
                            currentTarget.setSelectionRange(currentTarget.value.length, currentTarget.value.length)
                        }
                    }}
                    onChange={({ target: { value } }) => handleChange(value)}
                    onKeyDown={({ key, shiftKey }) => {
                        if (key === 'Enter' && !shiftKey) {
                            handleSubmit()
                        }
                    }}
                    onBlur={handleSubmit}
                    multiline={multiline}
                    //TODO: Nonsense props, will change with mui 6.0.0 https://github.com/mui/material-ui/pull/42062
                    inputMode={type}
                    InputProps={{
                        sx: {
                            ...typographyStyle,
                        },
                    }}
                    inputProps={type === 'numeric' ? { pattern: '[0-9]*[.,]?[0-9]*' } : {}}
                    placeholder={placeholder}
                />
            ) : (
                <EditableBox title='Edit' aria-disabled={disabled}>
                    <StyledTypography
                        aria-disabled={disabled}
                        role='button'
                        variant={typographyVariant}
                        sx={{ color: label ? 'inherit' : 'text.disabled' }}
                        onClick={editing || disabled ? undefined : () => setEditing(true)}
                    >
                        {controlledLabel ? textFormatter(initialLabel) : label ? textFormatter(label) : placeholder}
                    </StyledTypography>
                </EditableBox>
            )}
        </Container>
    )
}

const Editable = (props: EditableProps) => <EditablePage key={props.label} {...props} />

export default Editable
