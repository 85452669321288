import dayjs from 'dayjs'

export const getCurrentWeekRange = () => {
    const currentDate = new Date()
    const offsetToLastMonday = currentDate.getDate() - currentDate.getDay() + 1
    const offsetToSunday = offsetToLastMonday + 6

    const firstDayOfWeek = new Date(currentDate.setDate(offsetToLastMonday))
    const lastDayOfWeek = new Date(currentDate.setDate(offsetToSunday))

    return `${dayjs(firstDayOfWeek).format('DD/MM')} - ${dayjs(lastDayOfWeek).format('DD/MM')}`
}
