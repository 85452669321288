import { styled, Typography } from '@mui/material'
import { accountSettingsEndpoint, adminUsersEndpoint } from '../../../endpoints'
import { useGet } from '../../../hooks/useGet'
import { User } from '../../../types'
import Breadcrumb from '../../common/Breadcrumb'
import AnalyticsContainer from '../components/AnalyticsContainer'
import MemberTable from '../components/MemberTable'
import SettingsDataCards from '../components/SettingsDataCards'
import { TenancySettingsResponse } from '../utils/types'

const Container = styled('div')(() => ({
    maxWidth: 750,
    width: '90%',
    margin: '32px 0',
}))

const SubContainer = styled('div')(() => ({
    margin: '32px 0',
}))

interface AccountSettingsControllerProps {
    onBack: () => void
}

const AccountSettingsController = ({ onBack }: AccountSettingsControllerProps) => {
    const [tenancySettings, tenancyLoading, tenancyReload] = useGet<TenancySettingsResponse>(accountSettingsEndpoint)
    const [userResult, userLoading, userReload] = useGet<User[]>(adminUsersEndpoint)

    const handleRefresh = () => {
        tenancyReload()
        userReload()
    }

    return (
        <Container>
            <Typography variant='h1'>Account Settings</Typography>
            <Breadcrumb onClick={onBack} label='Back' />
            <SettingsDataCards tenancySettings={tenancySettings} loading={tenancyLoading} />
            <SubContainer>
                <Typography variant='h3'>Members</Typography>
                <MemberTable users={userResult?.sort((a, b) => a.name.localeCompare(b.name)) ?? []} loading={userLoading} onUserStateChange={handleRefresh} />
            </SubContainer>
            <SubContainer>
                <Typography variant='h3'>Analytics</Typography>
                <AnalyticsContainer />
            </SubContainer>
        </Container>
    )
}

export default AccountSettingsController
