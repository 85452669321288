import AdditionalInformation from './AdditionalInformation'
import { DocumentCard, FileCard } from '../common/DocumentCards'
import { useLeaseReportContext } from '../../context/LeaseReportContext'
import CardList from '../common/CardList'

const LeaseReportContent = () => {
    const { failedUploadFileIds, selectedReport, folderFiles } = useLeaseReportContext()

    return (
        <>
            <CardList>
                {selectedReport?.leaseContract && <DocumentCard document={selectedReport.leaseContract} />}
                {selectedReport?.auxiliaryDocuments?.map(doc => (
                    <DocumentCard key={doc.id} document={doc} />
                ))}
                {folderFiles?.map(({ id, file }) => (
                    <FileCard
                        key={id}
                        id={id}
                        name={file.name}
                        size={file.size}
                        error={failedUploadFileIds?.includes(id) ? 'Document failed to upload, please try again' : undefined}
                    />
                ))}
            </CardList>
            <AdditionalInformation />
        </>
    )
}

export default LeaseReportContent
