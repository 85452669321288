import { captureException } from '@sentry/react'
import { Document, isStringArray, UUID } from '../../../types'
import { PromptResult } from './types'
import { clamp } from '../../../utils/formatting'
import { DOCUMENT_COLUMN_MAX_AUTO_WIDTH, DOCUMENT_COLUMN_MIN_WIDTH } from './utils'

export const getLayoutKey = (chatId: UUID) => `${chatId}-layout`

const currentVersion = 2

const widthKeySuffix = '-width'

type WidthKey = `${UUID}${typeof widthKeySuffix}`

export interface Layout {
    version: typeof currentVersion
    collapsed: UUID[]
    promptCollapsed: boolean
    [id: WidthKey]: number | undefined
}

const validateWidthProperties = (value: Record<string, unknown>) =>
    Object.entries(value)
        .filter(([key]) => key.includes(widthKeySuffix))
        .every(([_, value]) => typeof value === 'number')

export const isLayout = (value: unknown): value is Layout =>
    value !== null &&
    typeof value === 'object' &&
    'version' in value &&
    value.version === currentVersion &&
    'promptCollapsed' in value &&
    typeof value.promptCollapsed === 'boolean' &&
    'collapsed' in value &&
    isStringArray(value.collapsed) &&
    validateWidthProperties(value)

export const getDefaultLayout = (): Layout => ({
    version: currentVersion,
    collapsed: [],
    promptCollapsed: false,
})

const isDefault = (layout: Layout) => layout.collapsed.length === 0 && !layout.promptCollapsed && Object.keys(layout).length === 3

export const getLayout = (chatId: UUID): Layout => {
    const key = getLayoutKey(chatId)
    let storedLayout: string | null = null

    try {
        storedLayout = localStorage.getItem(key)
    } catch {
        // User likely has some crazy settings to disable local storage
    }

    if (!storedLayout) {
        return getDefaultLayout()
    }

    try {
        const parsed = JSON.parse(storedLayout)
        if (isLayout(parsed)) {
            return parsed
        }
    } catch {}

    localStorage.removeItem(key)
    return getDefaultLayout()
}

export const trySaveLayout = (chatId: UUID, layout: Layout) => {
    const key = getLayoutKey(chatId)

    try {
        if (isDefault(layout)) {
            localStorage.removeItem(key)
        } else {
            localStorage.setItem(key, JSON.stringify(layout))
        }
    } catch (error) {
        captureException(error)
    }
}

export const getWidthKey = (id: UUID) => `${id}${widthKeySuffix}` as WidthKey
export const getWidthFromLayout = (layout: Layout) => (id: UUID) => layout[getWidthKey(id)]

const headerCoefficient = 10
const cellCoefficient = 7.5

const getLengthOfLongestValue = (results: PromptResult[], documentId: UUID) =>
    results.reduce((acc, promptResult) => Math.max(acc, promptResult.results.find(r => r.documentId === documentId)?.value?.length ?? 0), 0)

export const estimateDocumentColumnWidths = (documents: Document[], results: PromptResult[]): Record<UUID, number> =>
    documents.reduce(
        (acc, doc) => ({
            ...acc,
            [doc.id]: clamp(
                Math.max(doc.name.length * headerCoefficient, getLengthOfLongestValue(results, doc.id) * cellCoefficient),
                DOCUMENT_COLUMN_MIN_WIDTH,
                DOCUMENT_COLUMN_MAX_AUTO_WIDTH
            ),
        }),
        {}
    )
