import { SVGProps } from 'react'

interface ChevronIconProps extends SVGProps<SVGSVGElement> {
    direction: 'left' | 'right' | 'up' | 'down'
    color?: string
    flat?: boolean
    title?: string
}

const ChevronIcon = ({ flat = false, title, ...rest }: ChevronIconProps) => {
    if (flat)
        return (
            <svg xmlns='http://www.w3.org/2000/svg' fill='none' stroke={rest.color || 'white'} viewBox='0 0 11 25' strokeWidth={4} height={25} {...rest}>
                {title && <title>{title}</title>}
                {rest.direction === 'left' && <path strokeLinecap='round' strokeLinejoin='round' d='M9 23L3 12.5L9 2' />}
                {rest.direction === 'right' && <path strokeLinecap='round' strokeLinejoin='round' d='M2 2L8 12.5L2 23' />}
                {rest.direction === 'up' && <path strokeLinecap='round' strokeLinejoin='round' d='M2.5 8.5L13 2.5L23.5 8.5' />}
                {rest.direction === 'down' && <path strokeLinecap='round' strokeLinejoin='round' d='M23.5 2.5L13 8.5L2.5 2.5' />}
            </svg>
        )

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            stroke={rest.color || 'white'}
            viewBox='0 0 512 512'
            strokeWidth={42}
            height={50}
            width={42}
            {...rest}
        >
            {title && <title>{title}</title>}
            {rest.direction === 'left' && <path strokeLinecap='round' strokeLinejoin='round' d='M328 112L184 256l144 144' />}
            {rest.direction === 'right' && <path strokeLinecap='round' strokeLinejoin='round' d='M184 112l144 144-144 144' />}
            {rest.direction === 'up' && <path strokeLinecap='round' strokeLinejoin='round' d='M112 328l144-144 144 144' />}
            {rest.direction === 'down' && <path strokeLinecap='round' strokeLinejoin='round' d='M112 184l144 144 144-144' />}
        </svg>
    )
}

export default ChevronIcon
