import { useState } from 'react'
import UnstyledButton from './common/UnstyledButton'
import { ClickAwayListener, styled, Typography } from '@mui/material'
import FloatingCard from './common/tooltips/FloatingCard'

const SectionHeading = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
}))

const ClientsButton = styled(UnstyledButton)({
    textDecoration: 'underline',
    color: 'blue',
})

const HighlightedText = styled('span')({
    color: 'red',
    fontWeight: 'bold',
})

const excludedClients = [
    'BAML',
    'BP',
    'Citi',
    'Cyient',
    'GSK',
    'Government Legal',
    'Lloyds Banking Group',
    'Marsh & McLennan',
    'National Grid',
    'Raytheon RTX',
    'UniCredit',
] as const

// Note: Text on this page is displayed verbatim, as provided by Dentons
const DentonsGuidance = () => {
    const [tooltipOpen, setTooltipOpen] = useState(false)
    return (
        <>
            <Typography variant='h1'>Internal Guidance for Using fleetAI - please read</Typography>
            <SectionHeading variant='h2'>How to Use;</SectionHeading>
            <ul>
                <li>
                    <Typography>
                        Pursuant to the below, <b>client information is allowed to be submitted into this platform.</b> This is because of work we have done on
                        data privacy and client confidentiality with Microsoft and our internal teams on the system architecture. Furthermore, usage of AI and
                        cloud tools on client work is covered in clause 23 of our{' '}
                        <a href='https://dentonsglobalgbr.sharepoint.com/sites/ukime-classic?redirect=iwl:dms=UKDMS.dentonwildesapte.com&&lib=precedents&&num=3631&&ver=1&&latest=1'>
                            UKIME Terms of Business
                        </a>{' '}
                        and has been in place since January 2020. For a list of excluded clients, who have panel terms which do not allow us to use cloud tools
                        for matter delivery, please{' '}
                        <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
                            <span>
                                <FloatingCard
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={() => setTooltipOpen(false)}
                                    open={tooltipOpen}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                        <ul>
                                            {excludedClients.map(client => (
                                                <li key={client}>{client}</li>
                                            ))}
                                        </ul>
                                    }
                                >
                                    <ClientsButton aria-label='toggle excluded clients list' onClick={() => setTooltipOpen(true)}>
                                        click here
                                    </ClientsButton>
                                </FloatingCard>
                            </span>
                        </ClickAwayListener>{' '}
                        for an easy access pop-up. For the full list of excluded clients please{' '}
                        <a href='https://ukews.dentonwildesapte.com/work/link/d/UK_ACTIVE!91330225.1'>click here</a>.
                    </Typography>
                </li>
                <li>
                    <Typography>
                        You will see options for three robots - one chatbot, which uses GPT4, and two document upload bots, which use GPT 3.5 and GPT4
                        respectively. The GPT4 document upload bot will be more advanced than the GPT3.5 version, but do expect potentially slower response
                        times. Please select the chatbot most appropriate for your task.
                    </Typography>
                </li>
                <li>
                    <Typography>
                        Provide the platform with specific and accurate prompts. Providing context and being as descriptive and detailed as possible will ensure
                        the tool generates the most accurate and relevant responses. For our full user guide, including prompt guidance, please click{' '}
                        <a href='https://ukews.dentonwildesapte.com/work/link/d/UK_ACTIVE!91282751.3'>here</a>
                    </Typography>{' '}
                </li>
                <li>
                    <Typography>
                        <HighlightedText>Always independently verify and validate outputs provided from the platform.</HighlightedText> Do not overly rely on
                        results.
                    </Typography>{' '}
                </li>
                <li>
                    <Typography>
                        There is no official upper limit on the amount of text you can upload to the system. However, the more content you upload (e.g. into the
                        100,00s of words), the higher the possibility of inaccurate answers or other errors. Please report any instances of inaccurate responses
                        to help us improve this area. Note also that currently the tool only accepts PDFs for the document upload bots at this point.
                    </Typography>{' '}
                </li>
                <li>
                    <Typography>
                        The system can take around 5m words per minute in GPT3.5 and 1.6m words per minute in GPT4 across the firm. This notwithstanding, at
                        busier times you may have to wait longer for answers from the system, or occasionally try your question again after a couple of minutes.
                    </Typography>{' '}
                </li>
                <li>
                    <Typography>Consider data privacy regulations when submitting information to the platform.</Typography>
                </li>
                <li>
                    <Typography>Be transparent with clients and disclose when interacting with generative AI</Typography>
                </li>
                <li>
                    <Typography>
                        We will update this home page with guidance documents specific to each Practice Group later in 2023 after we have collated user
                        feedback.
                    </Typography>
                </li>
                <li>
                    <Typography>Phase II of fleetAI, which deals with automated contract reports, is coming in Q4 2023.</Typography>
                </li>
            </ul>
            <SectionHeading>What to use it for;</SectionHeading>
            <ul>
                <li>
                    <Typography>To generate legal/business services content and use as a source of inspiration for drafting documents</Typography>
                </li>
                <li>
                    <Typography>
                        To conduct legal research i.e. search for case law and statutes, and identify relevant legal arguments. NB Please do not upload
                        information from Practical Law, Lexis, Westlaw, online (or print) textbooks or any external subscriptions as this breaks the terms of
                        our licence agreements
                    </Typography>{' '}
                </li>
                <li>
                    <Typography>To extract key data from documents, including clauses, obligations, and also to help identify potential risks</Typography>
                </li>
                <li>
                    <Typography>To provide analysis of a legal/business services document, or documents as part of due diligence and compliance</Typography>
                </li>
            </ul>
            <SectionHeading>Risks;</SectionHeading>
            <ul>
                <li>
                    <Typography>The tool may produce biases or inaccuracies.</Typography>
                </li>
                <li>
                    <Typography>The tool is sometimes unable to cite sources of information</Typography>
                </li>
                <li>
                    <Typography>
                        The training dataset of the tool is only accurate as up to September 2021. Bear this in mind when asking questions that may need
                        information past this date, as the tool will not know (e.g. more recent cases or legislatory updates).
                    </Typography>
                </li>
                <li>
                    <Typography>fleetAI may generate false or misleading information.</Typography>
                </li>
                <li>
                    <Typography>Be aware of legal and regulatory obligations when uploading information to the platform</Typography>
                </li>
            </ul>
        </>
    )
}

export default DentonsGuidance
