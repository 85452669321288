import UnstyledButton from '../common/UnstyledButton'
import BinIcon from '../../icons/BinIcon'
import { SortableItemProps } from '../common/SortableItemWrapper'
import { UUID } from '../../types'
import EditIcon from '../../icons/EditIcon'
import HandleIcon from '../../icons/HandleIcon'
import UndoIcon from '../../icons/UndoIcon'
import { overflowText } from '../common/styles'
import { toast } from 'react-toastify'
import { requestSaveMessage } from '../../utils/userMessages'
import { styled, useTheme } from '@mui/material'
import InfoTooltip from '../common/tooltips/InfoTooltip'

const Container = styled('div')<{ isDragging: boolean; toDelete: boolean; disabled: boolean }>(({ theme, isDragging, toDelete, disabled }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px auto',
    padding: '15px',
    width: '85%',
    height: '25px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    fontWeight: 'bold',
    fontSize: '14px',
    backgroundColor: theme.palette.action.focus,
    cursor: 'grab',
    ...(isDragging && {
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        opacity: 0.5,
    }),
    ...(toDelete && {
        textDecoration: 'line-through',
        opacity: 0.5,
    }),
    ...(disabled && {
        cursor: 'not-allowed',
    }),
}))

const Label = styled('span')({
    flexGrow: 1,
    marginLeft: '15px',
    ...overflowText,
})

const StyledButton = styled(UnstyledButton)({
    verticalAlign: 'middle',
    marginLeft: '20px',
})

interface SortableFieldProps extends SortableItemProps {
    isLeaseReport: boolean
    tags?: string[]
    onEdit: (id: UUID) => void
}

const SortableField = ({ id, label, toDelete, isDragging, disabled, listIsDirty, onDeleteItem, onRestoreItem, onEdit }: SortableFieldProps) => {
    const { palette } = useTheme()

    return (
        <Container isDragging={isDragging} toDelete={!!toDelete} disabled={disabled}>
            <HandleIcon />
            <Label>{label}</Label>
            <div>
                {!toDelete && (
                    <InfoTooltip title={`Edit field`}>
                        <StyledButton
                            onClick={() => {
                                if (listIsDirty) {
                                    toast.warn(requestSaveMessage)
                                } else {
                                    onEdit(id)
                                }
                            }}
                            type='button'
                            aria-label={`edit field ${label}`}
                        >
                            <EditIcon width={20} height={20} />
                        </StyledButton>
                    </InfoTooltip>
                )}
                {!disabled &&
                    (toDelete ? (
                        <StyledButton onClick={onRestoreItem} type='button' aria-label={`restore field ${label}`} title={`Restore field`}>
                            <UndoIcon width={22} height={22} />
                        </StyledButton>
                    ) : (
                        <StyledButton onClick={onDeleteItem} type='button' aria-label={`delete field ${label}`} title={`Delete field`}>
                            <BinIcon fill={palette.error.main} />
                        </StyledButton>
                    ))}
            </div>
        </Container>
    )
}

export default SortableField
