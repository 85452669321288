import { UUID } from 'crypto'
import { userRolesEndpoint } from '../../../endpoints'
import { MenuItem, Select, styled, Typography } from '@mui/material'
import { UserRole } from '../../../types'
import { usePost } from '../../../hooks/usePost'

const StyledSelect = styled(Select)(() => ({
    boxShadow: 'none',

    '.MuiOutlinedInput-notchedOutline': { border: 0 },
    '.MuiSelect-icon': { width: 16 },
}))

interface UserRoleSelectInterface {
    role: UserRole
    userId: UUID
    onUserStateChange: () => void
}

const UserSelect = ({ role, userId, onUserStateChange }: UserRoleSelectInterface) => {
    const [requestUserRoleChange] = usePost(userRolesEndpoint(userId))

    const handleUserRoleChange = async (value: UserRole) => {
        const success = await requestUserRoleChange({ role: value })
        success && onUserStateChange()
    }

    return (
        <StyledSelect value={role} onChange={event => handleUserRoleChange(event.target.value as UserRole)}>
            <MenuItem value={UserRole.User}>
                <Typography>User</Typography>
            </MenuItem>
            <MenuItem value={UserRole.Maintainer}>
                <Typography>Maintainer</Typography>
            </MenuItem>
            <MenuItem value={UserRole.AccountManager}>
                <Typography>Account Manager</Typography>
            </MenuItem>
        </StyledSelect>
    )
}

export default UserSelect
